import { useContext, useEffect, useState } from 'react';
import {
	collection,
	doc,
	getDoc,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { useFirestore, useFunctions } from 'reactfire';
import { FIRESTORE } from '../Firebase/firestore';
import { getAuth } from 'firebase/auth';
import { UserContext } from '../contexts/UserContext';
import { ClientContext } from '../contexts/ClientContext';
import { Calendar, EventConfiguration } from '../models/models';
import { sequenceId } from '../util/util';

const rootCollectionCalendar = FIRESTORE.CALANDARS;

export default function useCalendars() {
	const firestore = useFirestore();
	const functions = useFunctions();
	const auth = getAuth();
	const [loading, setLoading] = useState(false);
	const { activeUser, isAuthenticated } = useContext(UserContext);
	const { activeClientId } = useContext(ClientContext);
	const [calendarTemplates, setCalendarTemplates] = useState<Calendar[]>([]);

	const appendCalendarTemplate = (calendar: Calendar) => {
		setCalendarTemplates((prev) => [...prev.filter((pk) => pk.id !== calendar.id), calendar]);
	};
	useEffect(() => {
		if (!isAuthenticated || !activeClientId) {
			return;
		} else {
			setLoading(true);
			const q = query(
				collection(firestore, `${FIRESTORE.CALANDARS}`),
				where('status', '==', 'active'),
				orderBy('lastUpdated', 'desc'),
			);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendCalendarTemplate({
								...change.doc.data(),
								id: change.doc.id,
							} as Calendar);
						} else if (change.type == 'modified') {
							appendCalendarTemplate({
								...change.doc.data(),
								id: change.doc.id,
							} as Calendar);
						} else if (change.type == 'removed') {
							setCalendarTemplates((prev) =>
								prev.filter((p) => p.id !== change.doc.data().id),
							);
						}
					});
					setLoading(false);
				},
				(err) => {
					console.log(err, 'Err');
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated, activeClientId]);
	const removeEvent = async (event: EventConfiguration, id: string) => {
		const db = getFirestore();
		const calendarRef = doc(db, rootCollectionCalendar, id);
		try {
			const calendarDoc = await getDoc(calendarRef);
			if (calendarDoc.exists() && event) {
				// Calendar document exists, update it by appending new non-duplicate events
				const existingEvents = (calendarDoc.data()?.events as EventConfiguration[]) || [];
				const filteredEvents = existingEvents?.filter(
					(ec) =>
						!(
							ec?.name === event?.name &&
							ec?.description === event?.description &&
							ec?.eventDate === event?.eventDate &&
							ec?.generationDate === event?.generationDate
						),
				);

				await updateDoc(calendarRef, {
					events: [...filteredEvents],
					lastUpdated: new Date().toISOString(),
				});
				console.log('Event removed!');
			}
		} catch (err) {}
	};
	const saveCalendar = async (events: EventConfiguration[], id: string): Promise<string> => {
		const db = getFirestore();
		const calendarId = id === 'new' ? sequenceId() : id;
		const calendarRef = doc(db, rootCollectionCalendar, calendarId);

		try {
			const calendarDoc = await getDoc(calendarRef);

			if (calendarDoc.exists()) {
				// Calendar document exists, update it by appending new non-duplicate events
				// const existingEvents = calendarDoc.data()?.events || [];
				await updateDoc(calendarRef, {
					events: [...events],
					lastUpdated: new Date().toISOString(),
				});
				console.log('Calendar successfully updated!');
			} else {
				// Calendar document doesn't exist, create a new one
				const calendar: Calendar = {
					id: calendarId,
					clientId: activeClientId ?? activeUser?.id,
					events: [...events],
					name: 'Custom calendar',
					status: 'active',
					lastUpdated: new Date().toISOString(),
				};

				await setDoc(calendarRef, calendar);
				console.log('Calendar successfully added to the collection!');
			}
		} catch (error) {
			console.error('Error modifying calendar in the collection: ', error);
		}
		return calendarId;
	};

	const deleteCalendar = async (calendar: Calendar) => {
		await setLoading(true);
		const fileRef = await doc(firestore, rootCollectionCalendar, calendar?.id);
		await setDoc(fileRef, { ...calendar, status: 'deleted' }, { merge: true });
		await setLoading(false);
	};

	return { saveCalendar, removeEvent, deleteCalendar, calendarTemplates };
}
