import { useEffect, useState } from "react";
import "firebase/auth";

import { getAuth, signInWithRedirect, signInWithCredential, onAuthStateChanged, User, getRedirectResult, getAdditionalUserInfo, updateProfile, updateEmail, sendEmailVerification, signInAnonymously, UserCredential } from "firebase/auth";
import { DocumentReference, Query, collection, doc, getDoc, getDocs, initializeFirestore, limit, onSnapshot, query, setDoc, where, FieldValue, updateDoc} from 'firebase/firestore';
import { useFirebaseApp, useFirestore, useFirestoreDocData } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { ActiveUser, initialState, removeActiveCompany } from "../redux/active-item/active-itemSlice";
import { DbUser } from "../Firebase/models";



export default function useFirebase() {
    const [isAuthenticatedCache, setIsAuthenticatedCache] = useState(false);
    const [user, setUser] = useState<User | null>(null);
    const firestore = useFirestore();
    const app = useFirebaseApp();
    const [redirectUrl, setRedirectUrl] = useState('');
    const auth = getAuth(app);
    const initialActiveUserState = {
      id: "",
      salesProfessionalInfo: {
          name: '',
          email: '',
          phoneNumber: '',
          jobTitle: '',
          linkedinId: '',
          UPS: ''
      },
      communicationPreferences: {
          email:'',
          phoneNumber:'',
          toneAndStyle:'',
          socialMediaHandle: []
      },
      clientIds: [],
      goalsAndObjectives: {
          cycleTime: '',
          leads: '',
          winRate: '',
          revenue: '',
          profitMargin: '',
          churnRate: '',
          upSell: ''
      }
  };
    const [activeUser, setActiveUser] = useState <ActiveUser>();
    const [userToken, setUserToken] = useState<string | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    // const [users, setusers] = useState<DbUser[]>([]);
    useEffect(() => {
        setIsAuthenticatedCache(localStorage.getItem('isAuthenticated') != null);
        const authObserverUnsubscribe = onAuthStateChanged(auth,
          async (firebaseUser) => {
            // console.log("Auth changed fired", auth, firebaseUser);
            if (!!auth.currentUser && !!firebaseUser) {
              const token = await auth.currentUser.getIdToken();
              setUserToken(token);
              setUser(auth.currentUser);
              setIsAuthenticated(true);
              localStorage.setItem('isAuthenticated', 'true');
              setIsAuthenticatedCache(true);
            } else {
              //user not here, empty token?
              setUserToken(null);
              setUser(null);
              setIsAuthenticated(false);
              setActiveUser(undefined);
              localStorage.removeItem('isAuthenticated');
              setIsAuthenticatedCache(false);
              // console.log("user logged off probably?", firebaseUser);
            }
          },
          (error) => {
            console.log("in useFirebase, auth error", error);
          }
        );
        return () => {
          // ...
          authObserverUnsubscribe();
        };
      }, []);

      useEffect(() => {
        if (!user?.uid) 
          return;
        // console.log("registering to fetch active user for", user.uid, "using credentials", auth.currentUser);
        const unsubscribe = onSnapshot(doc(firestore, `${FIRESTORE.USER}/${user.uid}`), (snapshot) => {
          const userRecord = snapshot.data()  as ActiveUser;
          setActiveUser(userRecord as ActiveUser);
        },(err)=>{console.log("err on fetching activeuser", err);});
        return unsubscribe;
      }, [user]);

  const saveClientIdToUserData = async (newClientId: string) => {
    try {
      if (!!activeUser?.id) {
        // console.log("newId: " + newClientId);
        const profileRef = await doc(firestore, FIRESTORE.USER, activeUser?.id);
        await updateDoc(profileRef, { clientIds: [...activeUser?.clientIds!, newClientId] });
        // console.log('Profile saved!');
        return;
      }
    } catch (err) {
      console.log("error on saving new client id:", err);
    }
  };
    //   const appendusers = (list: DbUser) => {
    //     setusers(prev => [...prev.filter(pk => pk.id !== list.id), list])
    // }

//       useEffect(() => {
//         const q = query(collection(firestore, `${FIRESTORE.USER}`)) as Query<DbUser>;
//         setusers([]);
//         const unsubscribe = onSnapshot(q, (snapshot) => {
//             snapshot.docChanges().forEach((change) => {
//                 if (change.type == "added") {
//                     appendusers({ ...change.doc.data(), id: change.doc.id } as DbUser);
//                 } else if (change.type == "modified") {
//                     appendusers({ ...change.doc.data(), id: change.doc.id } as DbUser);
//                 } else if (change.type == "removed") {
//                     setusers(prev => prev.filter(p => p.id !== change.doc.data().id))
//                 }
//             })
//         }, (err) => { console.log(err) });
//         return unsubscribe; 
// }, [user?.uid])


// useEffect(() => {
//       const q = query(collection(firestore, `${FIRESTORE.COMPANY}/${activeUser.id}/${FIRESTORE.COMPANY}`), where('companyListIds', "array-contains", listId)) as Query<GooglePlace>;
//       setLeads([]);
//       const unsubscribe = onSnapshot(q, (snapshot) => {
//           snapshot.docChanges().forEach((change) => {
//               if (change.type == "added") {
//                   appendLeads({ ...change.doc.data(), place_id: change.doc.id } as GooglePlace);
//               } else if (change.type == "modified") {
//                   appendLeads({ ...change.doc.data(), place_id: change.doc.id } as GooglePlace);
//               } else if (change.type == "removed") {
//                   setLeads(prev => prev.filter(p => p.place_id !== change.doc.data().place_id))
//               }
//           })
//       }, (err) => { console.log(err) });
//       return unsubscribe;
  
// }, [activeCompany, listId])

    return {user,userToken, isAuthenticated, isAuthenticatedCache, activeUser, redirectUrl, setRedirectUrl, saveClientIdToUserData}
}



