import { createContext, useEffect, useState } from 'react';
import useMidJourney from '../hooks/useMidJourney';
import { ImaginePrompt } from '../models/chat';

interface IMidJourneyContext {
	midjourneyImagines:ImaginePrompt[];
}

export const MidJourneyContext = createContext<IMidJourneyContext>({} as IMidJourneyContext);

export const MidJourneyProvider = ({ children }: { children: any; }) => {
	const { midjourneyImagines} = useMidJourney();
	return (
        <MidJourneyContext.Provider value={{ midjourneyImagines}}>{children}</MidJourneyContext.Provider>
	);
};
