import { useContext, useEffect, useState } from 'react';
import { useFirestore, useFunctions } from 'reactfire';
import { httpsCallable } from '@firebase/functions';
import { CollaborationThread, Collaborator } from '../models/collaboration';
import { Query, collection, onSnapshot, query, where } from 'firebase/firestore';
import { FIRESTORE } from '../Firebase/firestore';
import { UserContext } from '../contexts/UserContext';

export enum RequestType {
	add_collaborators = "add_collaborators",
	remove_collaborators = "remove_collaborators",
	add_collaboration_question = "add_collaboration_question",  
}

export default function useCollaboration(workflowId: string) {
	const firestore = useFirestore();
	const firebaseFunctions = useFunctions();
	const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
	const [collaborationThreads, setCollaborationThreads] = useState<CollaborationThread[]>([]);
	const { activeUser } = useContext(UserContext);

	const appendCollaborator = (collaborator: Collaborator) => {
		setCollaborators((prev) => [...prev.filter((pk) => pk.userId !== collaborator.userId), collaborator]);
	};

	const appendCollaborationThread = (thread: CollaborationThread) => {
		setCollaborationThreads((prev) => [...prev.filter((pk) => pk.id !== thread.id), thread]);
	};

	useEffect(() => {
		if(!workflowId || !activeUser?.clientIds){
			setCollaborationThreads([]);
			setCollaborators([]);
		}
		if(!!workflowId){
			setCollaborationThreads(prev => prev?.filter(i => i?.workflowId === workflowId));
			setCollaborators(prev => prev?.filter(i => i?.workflowId === workflowId));
		}
	}, [activeUser?.clientIds, workflowId])

	useEffect(() => {
		if (!!workflowId && !!activeUser?.clientIds && activeUser?.clientIds?.length > 0) {
			const q = query(
				collection(firestore, FIRESTORE.WORKFLOW, workflowId, FIRESTORE.COLLABORATOR),
				where('clientId', 'in', activeUser?.clientIds),
			) as Query<Collaborator>;
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendCollaborator({ ...change.doc.data(), id: change.doc.id } as Collaborator);
						} else if (change.type == 'modified') {
							appendCollaborator({ ...change.doc.data(), id: change.doc.id } as Collaborator);
						} else if (change.type == 'removed') {
							setCollaborators((prev) => prev.filter((p) => p.userId !== change.doc.data().userId));
						}
					});
				},
				(err) => {
					console.log({COLLABORATOR: FIRESTORE.COLLABORATOR, err});
				},
			);
			return unsubscribe;
		}
	}, [workflowId, activeUser?.clientIds]);

	useEffect(() => {
		if (!!workflowId && !!activeUser?.clientIds && activeUser?.clientIds?.length > 0) {
			const q = query(
				collection(firestore, FIRESTORE.WORKFLOW, workflowId, FIRESTORE.COLLABORATION_THREAD),
				where('clientId', 'in', activeUser?.clientIds),
			) as Query<CollaborationThread>;
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendCollaborationThread({ ...change.doc.data(), id: change.doc.id } as CollaborationThread);
						} else if (change.type == 'modified') {
							appendCollaborationThread({ ...change.doc.data(), id: change.doc.id } as CollaborationThread);
						} else if (change.type == 'removed') {
							setCollaborationThreads((prev) => prev.filter((p) => p.id !== change.doc.data().id));
						}
					});
				},
				(err) => {
					console.log({COLLABORATION_THREAD: FIRESTORE.COLLABORATION_THREAD, err});
				},
			);
			return unsubscribe;
		}
	}, [workflowId, activeUser?.clientIds]);

	const addCollaborators = async(collaborators: Collaborator[], workflowId: string, clientId: string, stepName: string) => {
		const workflowCall = httpsCallable(firebaseFunctions, "workflow-workflowCall");
		try {			
			await workflowCall({requestType: RequestType.add_collaborators, collaborators, workflowId, clientId, chatId: stepName});
			return true;
		} catch(err) {
			console.log(err);
			return err?.toString();
		}
	}

	const removeCollaborators = async(collaborators: Collaborator[], workflowId: string, clientId: string) => {
		const workflowCall = httpsCallable(firebaseFunctions, "workflow-workflowCall");
		try {
			await workflowCall({requestType: RequestType.remove_collaborators, collaborators, workflowId, clientId});
			return true;
		} catch(err) {
			console.log(err);
			return err?.toString();
		}
	}

	const addCollaborationQuestion = async(id: string, suggestion: string, workflowId: string, clientId: string, stepName: string, dataFragmentId?: string) => {
		const workflowCall = httpsCallable(firebaseFunctions, "workflow-workflowCall");
		try {
			await workflowCall({requestType: RequestType.add_collaboration_question, id, suggestion, workflowId, clientId, chatId: stepName, copyId: dataFragmentId});
			return true;
		} catch(err) {
			console.log(err);
			return err?.toString();
		}
	}

	return {
		addCollaborators,
		removeCollaborators,
		addCollaborationQuestion,
		collaborationThreads,
		collaborators,
	};
}
