import {
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import useFirebase from "../../hooks/useFirebase";
import { authMenu } from '../../menu';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';

export const ProtectedRoute = ({ component }: {component: any}) => {
  const { isAuthenticated, isAuthenticatedCache } = useContext(UserContext);
  if (!isAuthenticated && !isAuthenticatedCache) {
    return <Navigate to={authMenu.login.path} replace />;
  }

  return component;
};

