import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { User, UserCredential } from "firebase/auth";
import useFirebase from '../hooks/useFirebase';
import { ActiveUser } from '../redux/active-item/active-itemSlice';
import useProfile from '../hooks/useProfile';
import { CommunicationPreferences, Profile, SalesProfessionalInfo } from '../models/profile';
import useusers from '../hooks/useUsers';
import { DbUser } from '../Firebase/models';




interface IUserContext {
  user: User | null;
  activeUser?: ActiveUser;
  userToken: string | null;
  isAuthenticated?: boolean;
  isAuthenticatedCache: boolean;
  profile: Profile | undefined;
  salesProfessionalInfo: SalesProfessionalInfo | undefined;
  saveProfile: (profile: Partial<Profile>) => Promise<void>;
  communicationPreferences: CommunicationPreferences;
  users: DbUser[];
  redirectUrl: string;
  setRedirectUrl: Dispatch<SetStateAction<string>>;
  saveClientIdToUserData: (newClientId: string) => Promise<void>
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider = ({ children }: { children: any }) => {
  const { user, userToken, isAuthenticated, activeUser, redirectUrl, setRedirectUrl, saveClientIdToUserData, isAuthenticatedCache } = useFirebase();
  const { profile, salesProfessionalInfo, saveProfile, communicationPreferences } = useProfile();
  const { users } = useusers(activeUser);
  return (
    <UserContext.Provider value={{ user, userToken, isAuthenticated, isAuthenticatedCache, activeUser, profile, salesProfessionalInfo, communicationPreferences, redirectUrl, setRedirectUrl, saveProfile,saveClientIdToUserData, users }}>
      {children}
    </UserContext.Provider>
  )
}  