import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CompanyBasicInfo, Competitor, SuccessStories } from '../../models/company';
import { ProductBasicInfo, ProductFeaturesAndBenefits, ProductPricingAndPackages, ProductTestimonial, ProductTestimonialsAndSocialProof } from '../active-product/model';
import { Gallery, GooglePlace, Product } from '../../models/product';
import { CampaignComposedEmail, LeadSourceAndContact } from '../../models/campaign';
import { Campaign, CampaignCallToAction, CampaignGoal, CampaignTemplate, CampaignTone, MarketSegment } from '../../models/campaign';
import { DbUser, MidjourneyJob } from '../../Firebase/models';
import { CommunicationPreferences, GoalsAndObjectives, SalesProfessionalInfo, SocialHandle } from '../../models/profile';

export interface activeProductState {
    id: string;
    basicInfo: ProductBasicInfo;
    featuresAndBenefits: ProductFeaturesAndBenefits;
    pricingAndPackages: ProductPricingAndPackages;
    successStories: ProductTestimonial[];
    testimonialAndSocialProof: ProductTestimonialsAndSocialProof;
    gallery: Gallery[];
}

export interface activeGenerativeImageJobState {
    job: MidjourneyJob;
}

export interface activeCampaignState {
    templateId: string;
    name: string;
    goal: CampaignGoal;
    callToAction:CampaignCallToAction;
    tone: CampaignTone;
    productId: string;
    productImage: string;
    selectedPhotos: string[];
    selectedLeads: LeadSourceAndContact[];
    composedEmails: CampaignComposedEmail[];
    activeComposedEmail: CampaignComposedEmail;
    generatedEmailTemplate?: CampaignComposedEmail;
    editingGeneratedEmailTemplate?: boolean;
    marketSegments: MarketSegment[];
    testimonials: ProductTestimonial[];
    successStories: ProductTestimonial[];
    competitors: Competitor[];
    testimonialsIncluded?: boolean;
    successStoriesIncluded?: boolean;
    marketSegmentIncluded?:boolean;
    competitorsIncluded?: boolean;
    leadType?: string;
    leads: GooglePlace[];
}


export interface activeItemState {
    activeCompany: {
        basicCompanyInfo: CompanyBasicInfo;
        competitorsInfo: Competitor[];
        successStories:SuccessStories[];
    };
    activeProduct: activeProductState;
    activeCampaign: activeCampaignState;
    activeGenerativeImageJob: activeGenerativeImageJobState;
    activeUser: {    
        id: string;
        clientIds: string[];
        salesProfessionalInfo:SalesProfessionalInfo;
        goalsAndObjectives:GoalsAndObjectives;
        communicationPreferences:CommunicationPreferences;
    };
}

export interface ActiveUser{
        id: string;
        clientIds: string[];
        email?: string;
        name?: string;
        displayName?: string;
        salesProfessionalInfo:SalesProfessionalInfo;
        goalsAndObjectives:GoalsAndObjectives;
        communicationPreferences:CommunicationPreferences;
}
export const initialState: activeItemState = {
    activeCompany: {
        basicCompanyInfo: {
            name: "",
        },
        competitorsInfo: [],
        successStories:[]
    },
    activeGenerativeImageJob: {
        job: {
            hashCodes: [],
            id: "",
            submissions: [],
            images: [],
            prompt: "",
            seed: 1,
            userId: ""
        }
    },
    activeProduct: {
        id: "",
        basicInfo: {
            businessName: "",
            description: "",
            productName: "",
            sku: ""
        },
        featuresAndBenefits: {
            highlightedFeatures: [],
            benefits: [],
        },
        testimonialAndSocialProof: {
            complianceAndcertifications: [],
            eventsOrActivities: [],
            testimonials: [],
            newsOrExogenous: [],
        },
        pricingAndPackages: {
            pricing: "",
            delivery: "",
            supportAndMaintenance: "",
        },
        successStories: [],
        gallery: []
    },
    activeCampaign: {
        templateId: "",
        name: "",
        goal: {
            // goalCycleTime: "",
            // goalLeads: "",
            // goalWinRate: "",
            // goalRevenue: "",
            // goalProfitMargin: "",
            // goalCustomerAcquisitionCosts: "",
            // goalCustomerRetention: "",
            goalDescription: "",
            // goalChurnRate: "",
            // goalCross_sellAndUpsell: "",
        },
        callToAction:{
          callToActionDescription:"",
          callToActionLink:""
        },
        leads: [],
        tone: {
            toneAudience: '',
            toneDomain: "",
            toneFormality: "",
            toneIntent: "",
            toneSubjectLinePreference:"",
        },
        selectedPhotos: [],
        selectedLeads: [],
        composedEmails: [],
        activeComposedEmail: {
            campaignId: "",
            copyId: "",
            chatId: "",
            email: "",
            place_id: "",
            subject: "",
            body: "",
            attachments: [],
            image: "",
            name: "",
            phoneNumber: "",
            type: "general",
            lastUpdated: ""
        },
        productId: "",
        productImage: "",
        marketSegments: [],
        competitors: [],
        successStories: [],
        testimonials: [],
        successStoriesIncluded: false,
        testimonialsIncluded: false,
        leadType: "",
    },
    activeUser: {
        id: "",
        salesProfessionalInfo: {
            name: '',
            email: '',
            phoneNumber: '',
            jobTitle: '',
            linkedinId: '',
            UPS: ''
        },
        communicationPreferences: {
            email:'',
            phoneNumber:'',
            toneAndStyle:'',
            socialMediaHandle: []
        },
        clientIds: [],
        goalsAndObjectives: {
            cycleTime: '',
            leads: '',
            winRate: '',
            revenue: '',
            profitMargin: '',
            churnRate: '',
            upSell: ''
        }
    },
}

export const activeItemSlice = createSlice({
    name: "activeItem",
    initialState,
    reducers: {
        //user
        selectUser: (state, action: PayloadAction<DbUser & SalesProfessionalInfo & CommunicationPreferences>) => {
            const u = action.payload;
            state.activeUser.id = u.id;
            state.activeUser.clientIds = u.clientIds ?? [u.id];
            state.activeUser.salesProfessionalInfo.jobTitle = u.jobTitle??"";
            state.activeUser.salesProfessionalInfo.UPS = u.UPS??"";
            state.activeUser.salesProfessionalInfo.linkedinId = u.linkedinId??"";
            state.activeUser.salesProfessionalInfo.email = u.email;
            state.activeUser.salesProfessionalInfo.phoneNumber = u.phoneNumber??"";
            state.activeUser.salesProfessionalInfo.name = u.name??"";
            state.activeUser.salesProfessionalInfo.photoUrl = u.photoUrl ?? "";
            state.activeUser.communicationPreferences.toneAndStyle = u.toneAndStyle??"";
            state.activeUser.communicationPreferences.socialMediaHandle = u.socialMediaHandle??"";
        },
        setSalesProfessionalInfo:(state,action:PayloadAction<SalesProfessionalInfo>)=>{
            state.activeUser.salesProfessionalInfo=action.payload;
        },
       setGoalsAndObjectives:(state,action:PayloadAction<GoalsAndObjectives>)=>{
           state.activeUser.goalsAndObjectives=action.payload;
       },
       setName:(state, action: PayloadAction<string>)=>{
        state.activeUser.salesProfessionalInfo.name= action.payload;

    },
    setSalesProfessionalEmail: (state, action: PayloadAction<string>) => {
        state.activeUser.salesProfessionalInfo.email = action.payload;
      },
      setSalesProfessionalPhoneNumber: (state, action: PayloadAction<string>) => {
        state.activeUser.salesProfessionalInfo.phoneNumber = action.payload;
      },
      setJobTitle: (state, action: PayloadAction<string>) => {
        state.activeUser.salesProfessionalInfo.jobTitle = action.payload;
      },
      setLinkedinId: (state, action: PayloadAction<string>) => {
        state.activeUser.salesProfessionalInfo.linkedinId = action.payload;
      },
      setUPS: (state, action: PayloadAction<string>) => {
        state.activeUser.salesProfessionalInfo.UPS = action.payload;
      },
      setCycleTime: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.cycleTime = action.payload;
      },
      setLeads: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.leads = action.payload;
      },
      setWinRate: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.winRate = action.payload;
      },
      setRevenue: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.revenue = action.payload;
      },
      setProfitMargin: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.profitMargin = action.payload;
      },
      setChurnRate: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.churnRate = action.payload;
      },
      setUpSell: (state, action: PayloadAction<string>) => {
        state.activeUser.goalsAndObjectives.upSell = action.payload;
      },
      setEmail: (state, action: PayloadAction<string>) => {
        state.activeUser.communicationPreferences.email = action.payload;
      },
      setPhoneNumber: (state, action: PayloadAction<string>) => {
        state.activeUser.communicationPreferences.phoneNumber = action.payload;
      },
      setToneAndStyle: (state, action: PayloadAction<string>) => {
        state.activeUser.communicationPreferences.toneAndStyle = action.payload;
      },
      setSocialMediaHandle: (state, action: PayloadAction<SocialHandle[]>) => {
        state.activeUser.communicationPreferences.socialMediaHandle = action.payload;
      },
       setCommunicationPreferences:(state,action:PayloadAction<CommunicationPreferences>)=>{
           state.activeUser.communicationPreferences=action.payload;
        },
   
        // Company functions
        addCompanyBasicInfo: (state, action: PayloadAction<CompanyBasicInfo>) => {
            state.activeCompany.basicCompanyInfo = action.payload;
        },
        addCompanyName: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.name = action.payload;
        },
        addCompanyIndustry: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.industry = action.payload;
        },
        addCompanyCrm: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.crm = action.payload;
        },
        addCompanyLocation: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.location = action.payload;
        },
        addCompanyMission: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.mission = action.payload;
        },
        addCompanyNumberOfEmployees: (state, action: PayloadAction<number>) => {
            state.activeCompany.basicCompanyInfo.numberOfEmployees = action.payload;
        },
        addCompanyRevenue: (state, action: PayloadAction<number>) => {
            state.activeCompany.basicCompanyInfo.revenue = action.payload;
        },
        addCompanyUsp: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.usp = action.payload;
        },
        addCompanyValue: (state, action: PayloadAction<string>) => {
            state.activeCompany.basicCompanyInfo.value = action.payload;
        },
        addCompanyCompetitor: (state, action: PayloadAction<Competitor>) => {
            state.activeCompany.competitorsInfo = [...state.activeCompany.competitorsInfo?.filter(i => i != action.payload), action.payload];
        },
        removeCompanyCompetitor: (state, action: PayloadAction<Competitor[]>) => {
            state.activeCompany.competitorsInfo = action.payload;
        },
        addCompanyCompetitors: (state, action: PayloadAction<Competitor[]>) => {
            state.activeCompany.competitorsInfo = [...action.payload];
        },
        removeActiveCompany: (state, action: PayloadAction<string>) => {
            state.activeCompany = {
                basicCompanyInfo: {
                    name: "Company Name",
                },
                competitorsInfo: [],
                successStories:[]
            }
        },
        // product functions
        selectProduct: (state, action: PayloadAction<Product>) => {
            const {businessName, description, id,
                highlightedFeatures, productName, sku, 
                gallery, benefits, complianceAndcertifications, 
                eventsOrActivities, supportAndMaintenance, 
                delivery, newsOrExogenous, pricing, successStories, testimonials} = action.payload;
            state.activeProduct.id = id;
            state.activeProduct.basicInfo = {businessName, description, productName, sku};
            state.activeProduct.featuresAndBenefits = {highlightedFeatures: highlightedFeatures ?? [], benefits: benefits ?? []};
            state.activeProduct.gallery = gallery;
            state.activeProduct.pricingAndPackages = {pricing: pricing ?? "", delivery: delivery ?? "", supportAndMaintenance: supportAndMaintenance ?? ""};
            state.activeProduct.successStories = successStories ?? [];
            state.activeProduct.testimonialAndSocialProof = {
                eventsOrActivities: eventsOrActivities ?? [],
                newsOrExogenous: newsOrExogenous ?? [],
                testimonials: testimonials ?? [],
                complianceAndcertifications: complianceAndcertifications ?? [],
            };
            
        },
        updateProductBasicInfo: (state, action: PayloadAction<ProductBasicInfo>) => {
              state.activeProduct.basicInfo = action.payload;
          },
        updateProductImages: (state, action: PayloadAction<string[]>) => {
              state.activeProduct.gallery = action.payload.map(item => ({thumbnail: item, url: item}));
        },
        updateProductFeaturesAndBenefits: (state, action: PayloadAction<ProductFeaturesAndBenefits>) => {
            state.activeProduct.featuresAndBenefits = action.payload;
        },
        updateProductSuccessStories: (state, action: PayloadAction<ProductTestimonial[]>) => {
            state.activeProduct.successStories = action.payload;
        },
        updatePricingAndPackages: (state, action: PayloadAction<ProductPricingAndPackages>) => {
            state.activeProduct.pricingAndPackages = action.payload;
        },
        updateProductTestimonial: (state, action: PayloadAction<ProductTestimonial[]>) => {
            state.activeProduct.testimonialAndSocialProof.testimonials = action.payload;
        },
        updateProductComplianceAndcertifications: (state, action: PayloadAction<string[]>) => {
            state.activeProduct.testimonialAndSocialProof.complianceAndcertifications = action.payload;
        },
        updateProductEventsOrActivities: (state, action: PayloadAction<string[]>) => {
            state.activeProduct.testimonialAndSocialProof.eventsOrActivities = action.payload;
        },
        updateProductNewsOrExogenous: (state, action: PayloadAction<string[]>) => {
            state.activeProduct.testimonialAndSocialProof.newsOrExogenous = action.payload;
        },
        // campaign functions
        selectCampaign: (state, action: PayloadAction<Campaign>) => {
            const {templateId, id, name, productId, productImage, 
                goalDescription,
                callToActionDescription,
                callToActionLink, 
                images,
                toneAudience, toneDomain, toneFormality, toneIntent,toneSubjectLinePreference,
                marketSegments, selectedLeadEmails
            } = action.payload; 
            state.activeCampaign.templateId = templateId;    
            state.activeCampaign.productId = productId;
            state.activeCampaign.productImage = productImage;
            state.activeCampaign.goal = {
            goalDescription};
            state.activeCampaign.callToAction={
                callToActionDescription,
                callToActionLink
            }
            state.activeCampaign.name = name;
            state.activeCampaign.selectedPhotos = images;
            state.activeCampaign.tone = {toneAudience, toneDomain, toneFormality, toneIntent,toneSubjectLinePreference};
            state.activeCampaign.marketSegments = marketSegments ?? [];
            state.activeCampaign.selectedLeads = selectedLeadEmails?.map(le => ({email: le, name: state.activeCampaign.leads?.find(l => l.emails?.includes(le))?.name ?? "", place_id: state.activeCampaign.leads?.find(l => l.emails?.includes(le))?.place_id ?? ""}))
        },
        selectCampaignLeads: (state, action: PayloadAction<GooglePlace[]>) => {
            state.activeCampaign.leads = action.payload;
            for(let place of (state.activeCampaign.selectedLeads ?? [])) {
                place.name = action.payload.find(l => l.emails?.includes(place.email))?.name ?? "";
                place.place_id= action.payload.find(l => l.emails?.includes(place.email))?.place_id ?? "";
            }
        },
        selectCampaignProduct: (state, action: PayloadAction<Product>) => {
            state.activeCampaign.productId = action.payload?.id;
            state.activeCampaign.productImage = action.payload?.image;
            state.activeCampaign.selectedPhotos = [...state.activeCampaign.selectedPhotos, ...(action.payload.gallery.map(gi => gi.url))];
        },
        setCampaignName: (state, action: PayloadAction<string>) => {
            state.activeCampaign.name = action.payload;
        },
        selectCampaignTemplate: (state, action: PayloadAction<string>) => {
            state.activeCampaign.templateId = action.payload;           
        },
        // setCampaignGoalCycleTime: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalCycleTime = action.payload;
        // },
        // setCampaignGoalLeads: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalLeads = action.payload;
        // },
        // setCampaignGoalWinRate: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalWinRate = action.payload;
        // },
        // setCampaignGoalRevenue: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalRevenue = action.payload;
        // },
        // setCampaignGoalProfitMargin: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalProfitMargin = action.payload;
        // },
        // setCampaignGoalCustomerAcquisitionCosts: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalCustomerAcquisitionCosts = action.payload;
        // },
        // setCampaignGoalCustomerRetention: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalCustomerRetention = action.payload;
        // },
        // setCampaignGoalChurnRate: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalChurnRate = action.payload;
        // },
        // setCampaignGoalUpSell: (state, action: PayloadAction<string>) => {
        //     state.activeCampaign.goal.goalCross_sellAndUpsell = action.payload;
        // },
        setCampaignGoalDescription: (state, action: PayloadAction<string>) => {
            state.activeCampaign.goal.goalDescription = action.payload;
        },
        setCampaignCallToActionDescription: (state, action: PayloadAction<string>) => {
            state.activeCampaign.callToAction.callToActionDescription = action.payload;
        },
        setCampaignCallToActionLink: (state, action: PayloadAction<string>) => {
            state.activeCampaign.callToAction.callToActionLink = action.payload;
        },
        setCampaignToneAudience: (state, action: PayloadAction<string>) => {
            state.activeCampaign.tone.toneAudience = action.payload;
        },
        setCampaigntoneDomain: (state, action: PayloadAction<string>) => {
            state.activeCampaign.tone.toneDomain = action.payload;
        },
        setCampaigntoneFormality: (state, action: PayloadAction<string>) => {
            state.activeCampaign.tone.toneFormality = action.payload;
        },
        setCampaigntoneIntent: (state, action: PayloadAction<string>) => {
            state.activeCampaign.tone.toneIntent = action.payload;
        },
        setCampaigntoneSubjectLinePreference:(state, action: PayloadAction<string>)=>{
            state.activeCampaign.tone.toneSubjectLinePreference=action.payload;
        },
        setSelectedLeads: (state, action: PayloadAction<LeadSourceAndContact[]>) => {            
            state.activeCampaign.selectedLeads = action.payload;
        },
        setCampaignActiveComposedEmail: (state, action: PayloadAction<CampaignComposedEmail>) => {            
            state.activeCampaign.activeComposedEmail = action.payload;
        },
        setCampaignComposedEmails: (state, action: PayloadAction<CampaignComposedEmail[]>) => {            
            state.activeCampaign.composedEmails = action.payload;
        },
        setCampaignSelectPhotos: (state, action: PayloadAction<string[]>) => {
            state.activeCampaign.selectedPhotos = [...new Set([...action.payload])];
        },
        setCampaignTargetCustomers: (state, action: PayloadAction<MarketSegment[]>) => {
            state.activeCampaign.marketSegments = action.payload;
        },
        setCampaignCompetitors: (state, action: PayloadAction<Competitor[]>) => {
            state.activeCampaign.competitors = action.payload;
        },
        setCampaignMarketSegment: (state, action: PayloadAction<MarketSegment[]>) => {
            state.activeCampaign.marketSegments = action.payload;
        },
        setCampaignSuccessStories: (state, action: PayloadAction<ProductTestimonial[]>) => {
            state.activeCampaign.successStories = action.payload;
        },
        setCampaignTestimonials: (state, action: PayloadAction<ProductTestimonial[]>) => {
            state.activeCampaign.testimonials = action.payload;
        },
        setCampaigntestimonialsIncluded: (state, action: PayloadAction<boolean>) => {
            state.activeCampaign.testimonialsIncluded = action.payload;
        },
        setCampaignsuccessStoriesIncluded: (state, action: PayloadAction<boolean>) => {
            state.activeCampaign.successStoriesIncluded = action.payload;
        },
        setCampaignMarketingSegmentIncluded: (state, action: PayloadAction<boolean>) => {
            state.activeCampaign.marketSegmentIncluded = action.payload;
        },
        setCampaigncompetitorsIncluded: (state, action: PayloadAction<boolean>) => {
            state.activeCampaign.competitorsIncluded = action.payload;
        },
        setCampaignLeadType: (state, action: PayloadAction<string>) => {
            state.activeCampaign.leadType = action.payload;
        },
        setCampaignGeneratedEmailTemplate: (state, action: PayloadAction<CampaignComposedEmail>) => {
            state.activeCampaign.generatedEmailTemplate = action.payload;
        },
        setCampaignEditingGeneratedEmailTemplate: (state, action: PayloadAction<boolean>) => {
            state.activeCampaign.editingGeneratedEmailTemplate = action.payload;
        },
        selectGenerativeImageJob: (state, action: PayloadAction<MidjourneyJob>) => {
            const job= action.payload;
            state.activeGenerativeImageJob.job = job;
        },
    }
})

// Action creators are generated for each case reducer function
export const {selectUser, addCompanyBasicInfo, 
    setName,
    setJobTitle,
    setLinkedinId,
    setSalesProfessionalEmail,
    setSalesProfessionalPhoneNumber,
    setUPS,
    setChurnRate,
    setCycleTime,
    setLeads,
    setProfitMargin,
    setRevenue,
    setUpSell,
    setWinRate,
    setEmail,
    setPhoneNumber,
    setSocialMediaHandle,
    setToneAndStyle,
    setCommunicationPreferences,
    setGoalsAndObjectives,
    setSalesProfessionalInfo,
    addCompanyCompetitor, removeCompanyCompetitor,
    addCompanyCrm, addCompanyIndustry, addCompanyLocation,
    addCompanyMission, addCompanyName, addCompanyNumberOfEmployees, addCompanyRevenue, 
    addCompanyUsp, addCompanyValue, 
    removeActiveCompany, addCompanyCompetitors,
    selectProduct, updateProductBasicInfo, updateProductImages,
    updateProductFeaturesAndBenefits, updateProductSuccessStories,
    updatePricingAndPackages, updateProductComplianceAndcertifications, updateProductEventsOrActivities, 
    updateProductNewsOrExogenous, updateProductTestimonial,
    selectCampaign, selectCampaignTemplate, setCampaignGoalDescription, selectCampaignLeads,
    // setCampaignGoalChurnRate,
    // setCampaignGoalCustomerAcquisitionCosts, setCampaignGoalCustomerRetention,
    // setCampaignGoalCycleTime, , setCampaignGoalLeads,
    // setCampaignGoalProfitMargin, setCampaignGoalRevenue, setCampaignGoalUpSell, setCampaignGoalWinRate,
    setCampaignToneAudience, setCampaigntoneDomain, setCampaigntoneFormality, setCampaigntoneIntent,setCampaigntoneSubjectLinePreference,
    setCampaignSelectPhotos, setCampaignName, setSelectedLeads,
    setCampaignComposedEmails, setCampaignActiveComposedEmail, 
    setCampaignTargetCustomers, setCampaignCompetitors, setCampaignSuccessStories, setCampaignTestimonials,
    setCampaigntestimonialsIncluded, setCampaignsuccessStoriesIncluded, setCampaigncompetitorsIncluded,
    setCampaignLeadType, selectCampaignProduct, setCampaignGeneratedEmailTemplate,setCampaignMarketingSegmentIncluded,
    setCampaignEditingGeneratedEmailTemplate,setCampaignMarketSegment,setCampaignCallToActionDescription,setCampaignCallToActionLink,
    selectGenerativeImageJob
    } = activeItemSlice.actions;
export default activeItemSlice.reducer;