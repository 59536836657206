import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface accountStep{
    step: number;
    title:string;
}
export interface account{
    step: number;
    title:string;
}
const initialState:account = {
   step:0,
   title:'Company Information',
}

export const accountStepSlice = createSlice({
    name: "accountStep",
    initialState,
    reducers: {
        updateStep: (state, action: PayloadAction<accountStep>) => {
          const {step,title }= action.payload;
          state.step=step;
          state.title=title;
        },
    }
})

// Action creators are generated for each case reducer function
export const {
    updateStep,
} = accountStepSlice.actions;
export default accountStepSlice.reducer;