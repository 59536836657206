import { httpsCallable } from "firebase/functions";
import { useFirestore, useFunctions } from "reactfire";
import { RequestType } from "./useProduct";
import { useState, useEffect, useContext } from 'react';
import { CompanyLeadList } from "../models/lead";
import { getPreciseDistance } from "geolib";
import { Query, collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { FIRESTORE } from "../Firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { UserContext } from "../contexts/UserContext";
import { ClientContext } from "../contexts/ClientContext";

export default function useLeadsList() {
  const functions = useFunctions();
  const firestore = useFirestore();
  const [leadList, setLeadList] = useState<CompanyLeadList[]>([]);
  const {activeClientId} = useContext(ClientContext);

  const appendLeadList = (list: CompanyLeadList) => {
    setLeadList(prev => [...prev.filter(pk => pk.listId !== list.listId), list])
  }

  useEffect(() => {
    if (!activeClientId) {
      return;
    } else {
      const q = query(collection(firestore, `${FIRESTORE.COMPANY}/${activeClientId}/${FIRESTORE.COMPANY_LIST}`), where("status", "!=", "deleted")) as Query<CompanyLeadList>;
      setLeadList([]);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type == "added") {
            appendLeadList({ ...change.doc.data(), id: change.doc.id } as CompanyLeadList);
          } else if (change.type == "modified") {
            appendLeadList({ ...change.doc.data(), id: change.doc.id } as CompanyLeadList);
          } else if (change.type == "removed") {
            setLeadList(prev => prev.filter(p => p.listId !== change.doc.data().listId))
          }
        })
      }, (err) => { console.log(err, activeClientId) });
      return unsubscribe;
    }
  }, [activeClientId])

  const createLeadList = async (searchText: string, area: string, leadListName: string, workflowId?: string, workflowName?: string) => {
    try {
      const getResults = httpsCallable(functions, "googlePlaces-googlePlacesToGridCall");
      const response = (await getResults({ requestType: RequestType.getGooglePlacesByQueryGrid, input: {clientId: activeClientId, area: area, searchText: searchText, leadListName: leadListName, workflowId: workflowId, workflowName: workflowName } }));
      const result = await response?.data as unknown as CompanyLeadList;
      console.log("result", result);
      return result;

    } catch (e) {
      console.log({ e });
    }
  }
  const getdistance = async (coordinates: any) => {
    const distanceMeter = await getPreciseDistance(
      {
        latitude: parseFloat((coordinates?.northeast?.lat ?? 0)?.toString()),
        longitude: parseFloat((coordinates?.northeast?.lng ?? 0)?.toString()),
      },
      {
        latitude: parseFloat((coordinates?.southwest?.lat ?? 0)?.toString()),
        longitude: parseFloat((coordinates?.southwest?.lng ?? 0)?.toString()),
      }
    );
    console.log("distance between coordinates", distanceMeter);
    return distanceMeter;
  };
  const deleteLeadList = async (clientId: string, leadList: CompanyLeadList) => {
    const fileRef = await doc(firestore, FIRESTORE.COMPANY, clientId, FIRESTORE.COMPANY_LIST, leadList?.listId);
    await setDoc(fileRef, { ...leadList, status: "deleted" }, { merge: true });
    return;
  }
  return {
    createLeadList, getdistance, deleteLeadList, leadList
  }

}