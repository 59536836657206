import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
export const firebaseConfig = {
	apiKey: "AIzaSyDRL_-VQxC3XQDCrvTAW5GJsFVvWdDRHgg",
	authDomain: "multiplied-ai-dev.firebaseapp.com",
	databaseURL: "https://multiplied-ai-dev-default-rtdb.firebaseio.com",
	projectId: "multiplied-ai-dev",
	storageBucket: "multiplied-ai-dev.appspot.com",
	messagingSenderId: "1066221293820",
	appId: "1:1066221293820:web:c06eae34b78856370cc779",
	measurementId: "G-S0ZC40JSRL",
	listingBucket: "gs://multiplied-ai-dev.appspot.com",
	storageBaseUrl: "https://firebasestorage.googleapis.com/v0/b/multiplied-ai-dev.appspot.com/o",
	defaultRandomUUID: "000000000000000000000000000000000000",
	imageOptimizedUrls: ['https://firebasestorage.googleapis.com/v0/b/multiplied-ai-listing-dev', "https://devcdn.multiplied-ai.com"]
};
