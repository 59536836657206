import { useContext, useEffect, useState } from 'react';
import {
	collection,
	onSnapshot,
	query,
	where,
} from 'firebase/firestore';
import { useFirestore, useFunctions } from 'reactfire';
import { FIRESTORE } from '../Firebase/firestore';
import { UserContext } from '../contexts/UserContext';
import {
	Notification,
} from '../models/models';
import { httpsCallable } from 'firebase/functions';
import { ClientContext } from '../contexts/ClientContext';
import moment from 'moment';

enum WorkflowRequestType {
  readNotification = "readNotification",
}

const WORKFLOW_CALL = "workflow-workflowCall";

export type PROMPT_TYPE = "output" | "moduleContent" | "outputSuggestion";

export default function useNotifications() {

    const firestore = useFirestore();
	const firebaseFunctions = useFunctions();
	const [loading, setLoading] = useState(false);
	const { isAuthenticated, user, activeUser } = useContext(UserContext);
	const [notifications, setNotifications] = useState<Notification[]>([]);
  const {activeClientId} = useContext(ClientContext);

    const appendNotification = (lead: Notification) => {
		setNotifications(prev => [...prev.filter(pk => pk.id !== lead.id), lead])
	}

	  useEffect(() => {
		if (!isAuthenticated || !activeClientId) {
		  return;
		} else {
		  setLoading(true);
		  const q = query(collection(firestore, FIRESTORE.NOTIFICATION), where("clientId", "==", activeClientId),
		  where("status", "==", "unread")		
      );
		  const unsubscribe = onSnapshot(q, (snapshot) => {
			snapshot.docChanges().forEach((change) => {
			  if (change.type == "added") {
				appendNotification({ ...change.doc.data(), id: change.doc.id } as Notification);
			  } else if (change.type == "modified") {
				appendNotification({ ...change.doc.data(), id: change.doc.id } as Notification);
			  } else if (change.type == "removed") {
				setNotifications(prev => prev.filter(p => p.id !== change.doc.data().id))
			  }
			})
			setLoading(false);
		  }, (err) => { console.log({fetchNotificationError: err}) });
		  return unsubscribe;
		}
	  }, [isAuthenticated, activeClientId])

	const readNotification = async (id: string) => {
		setLoading(true);
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({ requestType: WorkflowRequestType.readNotification, clientId: activeClientId, id});
			setLoading(false);
		} catch(err) {
			console.log(err);
			setLoading(false);
		}
	}

	return {
		loading,
	    readNotification,
        notifications: notifications.sort((a,b) => a.lastUpdated > b.lastUpdated ? -1 : 1),
	};
}
