import React, { useContext } from 'react';
import coverImg from '../../../assets/img/demo/cover-img.png';
import Button from '../../../components/bootstrap/Button';
import { Product } from '../../../models/models';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FaPlus } from 'react-icons/fa';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { CardActions } from '../../../components/bootstrap/Card';

function Products({ products }: { products: Product[] }) {
	const {
		setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);

	return (
		<div className='px-2 px-md-5 mb-4'>
			<p className='uppercase-title'>Products</p>

			<ScrollContainer horizontal={true} className={`scroll-container products-data-row`}>
				<div
					className='row-item position-relative bg-dark cursor-pointer no-shadow'
					onClick={() => {
						setSelectedFragmentType('product');
						setProductId('new');
						datalakeModal.show();
					}}>
					<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
						<FaPlus size={75} color='#71788B' className='mt-5' />
					</div>
					<div className='px-3 pb-4'>
						<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>Add</p>
					</div>
				</div>
				{products?.map((product) => (
				   <>
				   {!!product?.images?.[0]?.url && (
				   
					<div className='row-item position-relative'>
					   
						<img
							src={product.images?.[0]?.url}
							alt={product.name}
							className='img-fluid w-100 h-100'
							
							onClick={() => {
								setProductId(product?.id);
								datalakeModal.show();
							}}
						/>
						
						<div className='card-bottom-section px-3'>
							<div className='d-flex justify-content-between align-items-center'>
								<p className='uppercase text-white'>{product.name}</p>
								<CardActions>
									<Dropdown>
										<DropdownToggle hasIcon={false}>
											<Button
												icon='MoreHoriz'
												// color={themeStatus}
												shadow='default'
												aria-label='Edit'
												className='p-0'
												
											/>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd style={{bottom:"20px",right:0}}>
											<DropdownItem>
												<Button
													icon='Visibility'
													onClick={() => {
														setProductId(product?.id);
														datalakeModal.show();
													}}>
													View
												</Button>
											</DropdownItem>
											<DropdownItem isDivider />
											<DropdownItem>
												<Button
													icon='Delete'
													onClick={() => {
														deleteDataFragment(product);
													}}>
													Delete
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</div>
						</div>
					
					</div>
				   )}
					</>
				))}
			</ScrollContainer>
		</div>
	);
}

export default Products;
