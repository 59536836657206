import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { stat } from 'fs';

export interface companyStep{
    step: number;
    title:string;
}

const initialState:companyStep = {
   step:0,
   title:'Company Information',
}

export const companyStepSlice = createSlice({
    name: "companyStep",
    initialState,
    reducers: {
        updateStep: (state, action: PayloadAction<companyStep>) => {
          const {step,title }= action.payload;
          state.step=step;
          state.title=title;
        }
    }
})

// Action creators are generated for each case reducer function
export const {updateStep} = companyStepSlice.actions;
export default companyStepSlice.reducer;