import { useContext, useEffect, useState } from 'react';
import {
	Query,
	collection,
	deleteDoc,
	doc,
	getDocs,
	onSnapshot,
	query,
	setDoc,
	where,
	writeBatch,
} from 'firebase/firestore';
import { useFirebaseApp, useFirestore, useFunctions } from 'reactfire';
import { FIRESTORE } from '../Firebase/firestore';
import { cloneNumberStampedId, sequenceId, uniqueId } from '../util/util';
import { UserContext } from '../contexts/UserContext';
import {
	DataFragment,
	PROMPT_TYPE,
	Workflow,
	WorkflowCampaign,
	WorkflowComposedEmail,
	WorkflowOutput,
	WorkflowStepTemplate,
	WorkflowTemplate,
} from '../models/models';
import { httpsCallable } from 'firebase/functions';
import { ChatMessage } from '../models/chatgpt/models';
import { GooglePlace } from '../models/product';
import { LeadSourceAndContact } from '../models/campaign';
import { CompanyContext } from '../contexts/CompanyContext';
import { ClientContext } from '../contexts/ClientContext';
import { ModuleContext } from '../contexts/ModuleContext';
import { CompanyLeadList } from '../models/lead';
import { firebaseConfig } from '../Firebase/firebase';
import { getDownloadURL, getStorage, ref as fbRes, uploadBytes } from 'firebase/storage';
import moment from 'moment';

const rootCollection = FIRESTORE.WORKFLOW;

enum WorkflowRequestType {
	completion = 'completion',
	send_collaboration_request = 'send_collaboration_request',
	apply_suggestion_to_workflow_email_copy = 'apply_suggestion_to_workflow_email_copy',
	send_workflow_emails = 'send_workflow_emails',
	generate_workflow_emails = 'generate_workflow_emails',
	save_custome_leads = 'save_custome_leads',
	save_csv_leads_request = 'save_csv_leads_request',
	createNewWorkflowStep = 'createNewWorkflowStep',
	deleteWorkflowStep = 'deleteWorkflowStep',
	cropVideoOutput = 'cropVideoOutput',
	exportWorkflow = 'exportWorkflow',
}

const WORKFLOW_CALL = 'workflow-workflowCall';
const CAMPAIGN_CALL = 'campaign-campaignCall';

export default function useWorkflow(inputWorkflowId: string) {
	const { selectedModule } = useContext(ModuleContext);
	const blankVM = (): Workflow => {
		return {
			clientId: activeClientId ?? activeUser?.id ?? 'unknown',
			created: new Date().toISOString(),
			lastUpdated: new Date().toISOString(),
			data: {},
			id: uniqueId(),
			userId: activeUser?.id ?? '',
			moduleId: workflowTemplate?.moduleId ?? selectedModule,
			name: '',
			text: '',
			wfTemplateId: '',
			status: 'active',
		};
	};
	const app = useFirebaseApp();
	const firestore = useFirestore();
	const firebaseFunctions = useFunctions();
	const [loading, setLoading] = useState(false);
	const { activeUser, isAuthenticated } = useContext(UserContext);
	const [workflowId, setWorkflowId] = useState(inputWorkflowId);
	const [workflow, setWorkflow] = useState<Workflow>();
	const [workflowTemplate, setWorkflowTemplate] = useState<WorkflowTemplate>();
	const [workflowStep, setWorkflowStep] = useState<WorkflowStepTemplate>();
	const [draftWorkflow, setDraftWorkflow] = useState<Workflow>(blankVM());
	const [workflowOutput, setWorkflowOutput] = useState<WorkflowOutput[]>([]);
	const [allWorkflowOutput, setAllWorkflowOutput] = useState<WorkflowOutput[]>([]);
	const [allEmails, setAllEmails] = useState<LeadSourceAndContact[]>([]);
	const [workflowLeads, setWorkflowLeads] = useState<GooglePlace[]>([]);
	const [draftSelectedLeadEmails, setDraftSelectedLeadEmails] = useState<LeadSourceAndContact[]>(
		[],
	);
	const [selectedLeadEmails, setSelectedLeadEmails] = useState<LeadSourceAndContact[]>([]);
	const [draftAllEmails, setDraftAllEmails] = useState<LeadSourceAndContact[]>([]);
	const [personalEmails, setPersonalEmails] = useState<LeadSourceAndContact[]>([]);
	const [draftWorkflowLeads, setDraftWorkflowLeads] = useState<GooglePlace[]>([]);
	const [workflowEmailCopies, setWorkflowEmailCopies] = useState<WorkflowComposedEmail[]>([]);
	const { activeClientId } = useContext(ClientContext);
	const [eventWorkflowId, setEventWorkflowId] = useState<string[]>([]);
	const appendWorkflowLead = (lead: GooglePlace) => {
		if ((lead?.emails?.length ?? 0) > 0)
			setWorkflowLeads((prev) => [
				...prev.filter((pk) => pk.place_id !== lead.place_id),
				lead,
			]);
		else console.log('Skipping lead', lead.name, 'since it dont have emails');
	};
	const appendWorkflowEmailCopy = (copy: WorkflowComposedEmail) => {
		setWorkflowEmailCopies((prev) => [...prev.filter((pk) => pk.copyId !== copy.copyId), copy]);
	};
	const extractEmailsFromLeads = () => {
		let personalEmails: LeadSourceAndContact[] = [];
		let allEmails: LeadSourceAndContact[] = [];
		let allSelectedEmails: LeadSourceAndContact[] = [];
		for (let place of workflowLeads) {
			if (place?.webScrape && place?.webScrape?.emails?.length > 0) {
				let perEmails = place?.webScrape?.emails?.filter((i) => i?.type === 'personal');
				if (perEmails?.length > 0) {
					perEmails?.forEach((i) => {
						personalEmails.push({
							email: i?.value,
							companyName: place?.name,
							name:
								!!i?.first_name?.length || !!i?.last_name?.length
									? `${i?.first_name ?? ''} ${i?.last_name ?? ''}`
									: place?.name,
							place_id: place?.place_id,
							source: place?.source,
							location: place?.formatted_address,
						});
					});
				}
				place?.webScrape?.emails?.forEach((i) => {
					allEmails.push({
						email: i?.value,
						companyName: place?.name,
						name: `${i?.first_name ?? ''} ${i?.last_name ?? ''}`,
						place_id: place?.place_id,
						source: place?.source,
						type: i?.type === 'personal' ? 'personalized' : 'general',
						location: place?.formatted_address,
					});
					if (
						place?.selectedGenericEmails?.find((e) => e == i?.value) ||
						place?.selectedPersonalEmails?.find((e) => e == i?.value)
					) {
						allSelectedEmails?.push({
							email: i?.value,
							companyName: place?.name,
							name: `${i?.first_name ?? ''} ${i?.last_name ?? ''}`,
							place_id: place?.place_id,
							source: place?.source,
							type: i?.type === 'personal' ? 'personalized' : 'general',
							location: place?.formatted_address,
						});
					}
				});
			}
		}
		return { personalEmails, allEmails, allSelectedEmails };
	};
	useEffect(() => {
		if (!isAuthenticated || !workflow || workflowId == 'new') {
			return;
		} else {
			setLoading(true);
			const q = query(
				collection(firestore, `${FIRESTORE.WORKFLOW}/${workflow.id}/${FIRESTORE.LEADS}`),
			);
			setWorkflowLeads([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendWorkflowLead({
								...change.doc.data(),
								place_id: change.doc.id,
							} as GooglePlace);
						} else if (change.type == 'modified') {
							appendWorkflowLead({
								...change.doc.data(),
								place_id: change.doc.id,
							} as GooglePlace);
						} else if (change.type == 'removed') {
							setWorkflowLeads((prev) =>
								prev.filter((p) => p.place_id !== change.doc.data().id),
							);
						}
					});
					setLoading(false);
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated, workflow]);

	useEffect(() => {
		if (!!workflowLeads) {
			// console.log("------------------------draftWorkflowLeads",draftWorkflowLeads );
			setDraftWorkflowLeads([
				...draftWorkflowLeads,
				...workflowLeads?.filter(
					(workflowLead) =>
						!draftWorkflowLeads?.find((i) => i?.place_id == workflowLead.place_id),
				),
			]);
			const {
				personalEmails: extractedPersonalEmail,
				allEmails: extractedAllEmails,
				allSelectedEmails,
			} = extractEmailsFromLeads();
			setPersonalEmails([
				...personalEmails,
				...extractedPersonalEmail?.filter(
					(i) => !personalEmails?.find((e) => e?.place_id == i?.place_id),
				),
			]);
			// console.log("------------------------workflowLeads", workflowLeads);
			setSelectedLeadEmails([
				...selectedLeadEmails,
				...allSelectedEmails?.filter(
					(e) => !selectedLeadEmails?.find((a) => a?.place_id == e?.place_id),
				),
			]);
			setDraftSelectedLeadEmails([
				...draftSelectedLeadEmails,
				...allSelectedEmails?.filter(
					(e) => !draftSelectedLeadEmails?.find((a) => a?.place_id == e?.place_id),
				),
			]);
			setAllEmails([
				...allEmails,
				...extractedAllEmails?.filter(
					(a) => !allEmails?.find((e) => a?.place_id == e.place_id),
				),
			]);
		} else {
			setDraftWorkflowLeads([]);
			setPersonalEmails([]);
			setAllEmails([]);
		}
	}, [workflowLeads]);
	useEffect(() => {
		setWorkflowId(inputWorkflowId);
	}, [inputWorkflowId]);
	const appendWorkflowOutput = (output: WorkflowOutput) => {
		setWorkflowOutput((prev) => [...prev.filter((pw) => pw.id !== output.id), output]);
	};
	const appendAllWorkflowOutput = (output: WorkflowOutput) => {
		setAllWorkflowOutput((prev) => [...prev.filter((pw) => pw.id !== output.id), output]);
	};
	useEffect(() => {
		if (!isAuthenticated || !workflowId) {
			setWorkflow(undefined);
			setDraftWorkflow(blankVM());
		} else if (workflowId == 'new') {
			setWorkflow(undefined);
			initializeVMForNew();
			return;
		} else {
			resetVM();
			const unsubscribe = onSnapshot(
				doc(firestore, `${FIRESTORE.WORKFLOW}/${workflowId}`),
				(snapshot) => {
					const wfRecord = snapshot.data() as Workflow;
					setWorkflow(wfRecord);
					setDraftWorkflow(wfRecord);
				},
				(err) => {
					console.log('err on fetching workflow', err);
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated, workflowId]);

	useEffect(() => {
		if (!isAuthenticated || !workflowId) {
			setWorkflowOutput([]);
		} else if (workflowId == 'new') {
			setWorkflowOutput([]);
			return;
		} else {
			const q = query(
				collection(firestore, `${FIRESTORE.WORKFLOW}/${workflowId}/workflow-output`),
			);
			setWorkflowOutput([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendWorkflowOutput({
								...change.doc.data(),
								id: change.doc.id,
							} as WorkflowOutput);
						} else if (change.type == 'modified') {
							appendWorkflowOutput({
								...change.doc.data(),
								id: change.doc.id,
							} as WorkflowOutput);
						} else if (change.type == 'removed') {
							setWorkflowOutput((prev) =>
								prev.filter((p) => p.id !== change.doc.data().id),
							);
						}
					});
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated, workflowId]);

	useEffect(() => {
		if (!isAuthenticated || !eventWorkflowId?.length) {
			setAllWorkflowOutput([]);
		} else {
			setAllWorkflowOutput([]);
			eventWorkflowId?.forEach((id) => {
				const q = query(
					collection(firestore, `${FIRESTORE.WORKFLOW}/${id}/workflow-output`),
				);

				const unsubscribe = onSnapshot(
					q,
					(snapshot) => {
						snapshot.docChanges().forEach((change) => {
							if (change.type == 'added') {
								appendAllWorkflowOutput({
									...change.doc.data(),
									id: change.doc.id,
								} as WorkflowOutput);
							} else if (change.type == 'modified') {
								appendAllWorkflowOutput({
									...change.doc.data(),
									id: change.doc.id,
								} as WorkflowOutput);
							} else if (change.type == 'removed') {
								setAllWorkflowOutput((prev) =>
									prev.filter((p) => p.id !== change.doc.data().id),
								);
							}
						});
					},
					(err) => {
						console.log(err);
					},
				);
				return unsubscribe;
			});
		}
	}, [isAuthenticated, eventWorkflowId]);

	useEffect(() => {
		if (!isAuthenticated || !workflowId) {
			return;
		} else {
			setLoading(true);
			const q = query(
				collection(
					firestore,
					`${FIRESTORE.WORKFLOW}/${workflowId}/${FIRESTORE.WORKFLOW_EMAIL_COPIES}`,
				),
			);
			setWorkflowEmailCopies([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendWorkflowEmailCopy({
								...change.doc.data(),
								copyId: change.doc.id,
							} as WorkflowComposedEmail);
						} else if (change.type == 'modified') {
							appendWorkflowEmailCopy({
								...change.doc.data(),
								copyId: change.doc.id,
							} as WorkflowComposedEmail);
						} else if (change.type == 'removed') {
							setWorkflowEmailCopies((prev) =>
								prev.filter((p) => p.copyId !== change.doc.data().id),
							);
						}
					});
					setLoading(false);
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated, workflowId]);

	useEffect(() => {
		if (!isAuthenticated || !workflow || workflowId == 'new' || !workflow?.wfTemplateId) return;
		if (!!workflow?.wfTemplate) {
			console.log('setting workflow template from workflow');
			setWorkflowTemplate(workflow?.wfTemplate);
		} else {
			console.log('fetching workflow template from db ');
			const unsubscribe = onSnapshot(
				doc(
					firestore,
					`${FIRESTORE.MODULE}/${workflow.moduleId}/${FIRESTORE.WORKFLOW_TEMPLATE}/${workflow.wfTemplateId}`,
				),
				(snapshot) => {
					const template = snapshot.data() as WorkflowTemplate;
					setWorkflowTemplate(template);
					setWorkflowStep(template.steps[0]);
				},
				(err) => {
					console.log('err on fetching workflow template', err);
				},
			);
			return unsubscribe;
		}
	}, [workflow]);

	const initializeVMForNew = () => {
		setDraftWorkflow(blankVM());
		setWorkflow(blankVM());
		// setWorkflowTemplate(undefined);
	};

	const resetVM = () => {
		setDraftWorkflow(blankVM());
		setWorkflow(undefined);
		setWorkflowTemplate(undefined);
	};

	const saveWorkflow = async (file: Workflow) => {
		await setLoading(true);
		if (!file?.wfTemplateId || file?.wfTemplateId == '') {
			file = {
				...file,
				wfTemplateId: workflowTemplate?.id ?? '',
				wfTemplate: workflowTemplate,
				userId: activeUser?.id ?? '',
			};
		}
		const fileRef = await doc(firestore, rootCollection, file.id);
		await setDoc(fileRef, file, { merge: true });
		await setWorkflowId(file.id);
		await setLoading(false);
	};

	const saveWorkflowOutput = async (output: WorkflowOutput) => {
		const fileRef = await doc(
			firestore,
			`${FIRESTORE.WORKFLOW}/${workflowId}/workflow-output`,
			output?.id,
		);
		await setDoc(fileRef, output, { merge: true });
	};

	const deleteWorkflowOutput = async (output: WorkflowOutput) => {
		const fileRef = await doc(
			firestore,
			`${FIRESTORE.WORKFLOW}/${workflowId}/workflow-output`,
			output?.id,
		);
		await deleteDoc(fileRef);
	};

	const createWorkflow = async (file: Workflow) => {
		await setLoading(true);
		const fileRef = await doc(firestore, rootCollection, file.id);
		await setDoc(fileRef, file, { merge: true });
		await setLoading(false);
	};

	const generate = async (
		promptType: PROMPT_TYPE,
		input?: Record<string, string>,
		chatId?: string,
		combination?: any[],
	) => {
		setLoading(true);
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({
				requestType: WorkflowRequestType.completion,
				clientId: activeClientId,
				workflowId,
				promptType,
				input,
				id: chatId,
				combination,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};
	const saveWorkflowLeads = async (workflowId: string, files: GooglePlace[]) => {
		await setLoading(true);
		const batch = writeBatch(firestore);
		for (let file of files) {
			const fileRef = await doc(
				firestore,
				rootCollection,
				workflowId,
				FIRESTORE.LEADS,
				file.place_id,
			);
			batch.set(fileRef, file, { merge: true });
		}
		await batch.commit();
		await setLoading(false);
	};
	const getLeadsForListId = async (listId: string) => {
		let leads = [] as GooglePlace[];
		console.log('list id ', listId);
		console.log('lead activeClientId', activeClientId);
		const q = await query(
			collection(
				firestore,
				`${FIRESTORE.COMPANY}/${activeClientId}/${FIRESTORE.COMPANY_LEAD}`,
			),
			where('companyListIds', 'array-contains', listId),
		);
		const querySnapshot = await getDocs(q);
		await querySnapshot.forEach((doc) => {
			leads?.push(doc.data() as never as GooglePlace);
		});
		console.log('leadds', leads);
		return leads;
	};

	const applySuggestionToEmailCopy = async (
		copyId: string,
		chatId: string,
		suggestion: string,
	) => {
		setLoading(true);
		try {
			const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
			const response = await workflowCall({
				requestType: WorkflowRequestType.apply_suggestion_to_workflow_email_copy,
				clientId: activeClientId,
				workflowId: workflow?.id ?? workflowId,
				copyId,
				chatId,
				suggestion,
			});
			setLoading(false);
		} catch (err) {
			setLoading(false);
			throw err;
		}
	};
	const saveWorkflowEmailCopies = async (
		workflowId: string,
		files: Partial<WorkflowComposedEmail>[],
	) => {
		await setLoading(true);
		const batch = writeBatch(firestore);
		for (let file of files) {
			const fileRef = await doc(
				firestore,
				rootCollection,
				workflowId,
				FIRESTORE.WORKFLOW_EMAIL_COPIES,
				`${file.copyId}`,
			);
			batch.set(fileRef, file, { merge: true });
		}
		await batch.commit();
		await setLoading(false);
	};

	const saveWorkflowEmailCopy = async (
		workflowId: string,
		copyId: string,
		copy: Partial<WorkflowComposedEmail>,
	) => {
		const copyRef = await doc(
			firestore,
			`${FIRESTORE.WORKFLOW}/${workflowId}/${FIRESTORE.WORKFLOW_EMAIL_COPIES}`,
			copyId,
		);
		await setDoc(copyRef, copy, { merge: true });
		return;
	};
	const sendWorkflowEmails = async (emails: WorkflowComposedEmail[]) => {
		setLoading(true);
		try {
			const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
			const response = await workflowCall({
				requestType: WorkflowRequestType.send_workflow_emails,
				clientId: activeClientId,
				input: { emails },
			});
			const result = response.data as string;
			setLoading(false);
			return result;
		} catch (e) {
			console.log(e);
			setLoading(false);
			return '';
		}
	};

	const savePlaceholderEmails = async (leads: LeadSourceAndContact[], attachments: string[]) => {
		const placeholderCopies: Partial<WorkflowComposedEmail>[] = [];
		for (let lead of leads) {
			const copy: Partial<WorkflowComposedEmail> = {
				workflowId: workflow?.id ?? workflowId,
				copyId: `${lead.place_id}-${lead.email}`,
				email: lead.email,
				name: lead.name,
				place_id: lead.place_id,
				type: 'personalized',
				attachments: attachments,
				companyName: lead?.companyName ?? '',
				location: lead?.location ?? '',
				lastUpdated: new Date().toISOString(),
			};
			placeholderCopies.push(copy);
		}
		await saveWorkflowEmailCopies(workflow?.id ?? workflowId, placeholderCopies);
	};
	const saveCustomLeads = async (leads: GooglePlace[], list: CompanyLeadList) => {
		setLoading(true);
		try {
			console.log(`saveWorkflowEmailCopies`, leads);
			if (leads?.length > 0) {
				const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
				await workflowCall({
					requestType: WorkflowRequestType.save_custome_leads,
					clientId: activeClientId,
					workflowId: workflow?.id ?? workflowId,
					leads: leads,
					input: { list: list },
				});
			} else {
				console.log('huhiuhiu', leads);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			throw err;
		}
	};
	const generateWorkflowEmailCopies = async (
		leads: LeadSourceAndContact[],
		attachments: string[],
	) => {
		setLoading(true);
		try {
			console.log(`saveWorkflowEmailCopies`, leads, attachments);
			if (leads?.length > 0) {
				await savePlaceholderEmails(leads, attachments);
				const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
				await workflowCall({
					requestType: WorkflowRequestType.generate_workflow_emails,
					clientId: activeClientId,
					workflowId: workflow?.id ?? workflowId,
					leads: leads,
					input: { attachments: attachments },
				});
			} else {
				console.log('huhiuhiu', leads);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			throw err;
		}
	};

	const saveCsvLeadsRequest = async (leads: GooglePlace[]) => {
		setLoading(true);
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({
				requestType: WorkflowRequestType.save_csv_leads_request,
				googlePlaces: leads,
				clientId: activeClientId,
				workflowId: workflow?.id ?? workflowId,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const createNewWorkflowStep = async (data: any) => {
		setLoading(true);
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({
				requestType: WorkflowRequestType.createNewWorkflowStep,
				createWorkflowStepData: data,
				clientId: activeClientId,
				workflowId: workflow?.id ?? workflowId,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const deleteWorkflowStep = async (data: any) => {
		setLoading(true);
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({
				requestType: WorkflowRequestType.deleteWorkflowStep,
				chatId: data,
				clientId: activeClientId,
				workflowId: workflow?.id ?? workflowId,
			});
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const uploadVideoOutput = async (
		blob: Blob,
		thumbnail: Blob,
		workflowId: string,
		stepId: string,
	) => {
		console.log('Uploading audio...', blob);
		const LISTING_BUCKET = firebaseConfig.listingBucket;
		const storage = getStorage(app, LISTING_BUCKET);
		const yymmdd = new Date().toISOString().slice(0, 10).replace(/-/g, '');
		const filename = uniqueId()?.toLowerCase().replace(/\s/g, '_');
		try {
			const fileContainer = fbRes(
				storage,
				`${activeClientId}/workflowVideoOutput/${workflowId}/outputs/${yymmdd}_${filename}.webm`,
			);
			await uploadBytes(fileContainer, blob);
			const downloadUrl = await getDownloadURL(fileContainer);
			const thumbnailContainer = fbRes(
				storage,
				`${moment().format(
					'YYMMDD',
				)}/${uniqueId()}/${new Date().getTime()}-${filename}.jpeg`,
			);
			await uploadBytes(thumbnailContainer, thumbnail);
			const thumbnailUrl = await getDownloadURL(thumbnailContainer);
			console.log('download video url : ', downloadUrl);
			const output: WorkflowOutput = {
				id: sequenceId(),
				wfId: workflowId,
				name: `${stepId}-Recording`,
				outputType: 'visual',
				created: new Date().toISOString(),
				promptType: 'visual',
				promptId: '',
				data: {
					stepId: stepId,
					videoUrl: downloadUrl,
					thumbnailUrl: thumbnailUrl,
				},
				stepId: stepId,
				lastUpdated: new Date().toISOString(),
			};
			await saveWorkflowOutput(output);
			return downloadUrl;
		} catch (err) {
			console.log({ err });
		}
	};
	const cropOutputVideo = async (
		videoUrl: string,
		cropdata: { width: number; height: number; x: number; y: number },
	) => {
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({
				requestType: WorkflowRequestType.cropVideoOutput,
				input: { videoUrl: videoUrl, cropData: cropdata },
				clientId: activeClientId,
				workflowId: workflow?.id ?? workflowId,
			});
			return response?.data;
		} catch (err) {
			console.log(err);
		}
	};

	const exportWorkflow = async () => {
		const workflowCall = httpsCallable(firebaseFunctions, WORKFLOW_CALL);
		try {
			const response = await workflowCall({
				requestType: WorkflowRequestType.exportWorkflow,
				clientId: activeClientId,
				workflowId: workflow?.id ?? workflowId,
			});
			return {
				url: response?.data,
				type: 'success',
			};
		} catch (err) {
			console.log(err);
			return {
				url: err?.toString(),
				type: 'error',
			};
		}
	};
	const saveWorkflowCampaign = async (campaign: WorkflowCampaign) => {
		await setLoading(true);
		console.log(campaign);
		const camp: WorkflowCampaign = {
			...campaign,
			clientId: activeClientId ?? activeUser?.id ?? 'unknown',
		};
		const fileRef = await doc(firestore, FIRESTORE.CAMPAIGN, camp?.campaignId);
		await setDoc(fileRef, camp, { merge: true });
		await setLoading(false);
	};

	return {
		loading,
		setWorkflowId,
		workflow,
		workflowId,
		saveWorkflow,
		createWorkflow,
		workflowTemplate,
		workflowStep,
		setWorkflowStep,
		workflowOutput,
		eventWorkflowId,
		allWorkflowOutput,
		generate,
		draftWorkflowLeads,
		setWorkflowTemplate,
		draftSelectedLeadEmails,
		setDraftSelectedLeadEmails,
		saveWorkflowLeads,
		getLeadsForListId,
		selectedLeadEmails,
		workflowLeads,
		allEmails,
		applySuggestionToEmailCopy,
		workflowEmailCopies,
		saveWorkflowEmailCopies,
		saveWorkflowEmailCopy,
		sendWorkflowEmails,
		generateWorkflowEmailCopies,
		saveWorkflowOutput,
		saveCsvLeadsRequest,
		saveCustomLeads,
		createNewWorkflowStep,
		deleteWorkflowStep,
		uploadVideoOutput,
		deleteWorkflowOutput,
		cropOutputVideo,
		exportWorkflow,
		saveWorkflowCampaign,
		setEventWorkflowId,
	};
}
