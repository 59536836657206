import { useEffect, useState } from "react";
import Stripe from "stripe";
import Swal from "sweetalert2";

export const useStripe = (customerId?: string) => {
    const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY ?? "", {
        apiVersion: "2023-08-16",
    });

    const [paymentMethods, setPaymentMethods] = useState<Stripe.PaymentMethod[]>([]);
    const [paymenthMethodFetched, setPaymentMethodFetched] = useState(false);
    const [removingCreditCard, setRemoving] = useState({is: false, id: ""});

    useEffect(() => {
      if(!!paymenthMethodFetched){
        setPaymentMethodFetched(false);
        setPaymentMethods([]);
      }
    }, [customerId])

    useEffect(() => {
        if(!!customerId && !paymenthMethodFetched){
          (async() => {
            await fetchPaymentMethods();
            await setPaymentMethodFetched(true);
          })()
        }
      }, [customerId])

    const fetchPaymentMethods = async() => {
        if(!!customerId){
            const result = await stripe.paymentMethods.list({customer: customerId});
            await setPaymentMethods(result?.data);    
        }
    }    

    const removePaymentMethod = async(id: string) => {        
        if(paymentMethods?.length === 1){
          await Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: `Please add a new card before deleting this card.`,
          });
        }else{
          const result = await Swal.fire({
            icon: 'warning',
            title: 'Confirmation',
            text: `Are you sure, you want to delete this credit card?`,
            cancelButtonText: "No",
            cancelButtonColor: '#38cab3',
            showCancelButton: true,
          });
          if(result?.isConfirmed){
            await setRemoving({is: true, id});
            if(customerId){
              await stripe.paymentMethods.detach(id);
              await fetchPaymentMethods();
            }
            await Swal.fire({
              icon: 'success',
              title: 'Congratulations',
              text: `Card removed successfully.`,
            });    
            await setRemoving({is: false, id: ""});  
          }  
        }
      }    

    return {
        stripe,
        removingCreditCard,
        removePaymentMethod,
        paymentMethods,
        paymenthMethodFetched,
    }
}