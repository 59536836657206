import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import React, { ReactNode } from 'react';
import Modal, { ModalBody, ModalHeader } from '../../../components/bootstrap/Modal';
import { Modals } from '../../../components/Modals';
interface Props {
	id: Modals;
	children: ReactNode;
}
export default NiceModal.create(({ id, children }: Props) => {
	const modal = useModal(id);
	return (
		<Modal id={id} size={'xl'} isCentered>
			<ModalHeader className='p-0'>
				<></>
			</ModalHeader>
			<ModalBody
				className='alert-popup position-relative'
				style={{ overflowY: 'scroll', height: '80vh' }}>
				{children}
			</ModalBody>
		</Modal>
	);
});
