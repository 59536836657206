import React, { useContext } from 'react';
import { Image, User } from '../../../models/models';
import ScrollContainer from 'react-indiana-drag-scroll';
import { FaPlus } from 'react-icons/fa';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import { useModal } from '@ebay/nice-modal-react';
import { Modals } from '../../../components/Modals';
import Button from '../../../components/bootstrap/Button';
import Avatar, { AvatarGroup } from '../../../components/Avatar';
import striptags from 'striptags';
import { shorten } from '../../../util/util';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { CardActions } from '../../../components/bootstrap/Card';

function Users({ users }: { users: User[] }) {
	const {
		setFragmentId: setProductId,
		setSelectedFragmentType,
		deleteDataFragment,
	} = useContext(DatalakeContext);
	const datalakeModal = useModal(Modals.DATALAKE);
	return (
		<div className='px-2 px-md-5 mb-4'>
			<p className='uppercase-title'>People</p>
			<ScrollContainer horizontal={true} className={`scroll-container data-row`}>
				<div
					className='row-item position-relative bg-dark cursor-pointer'
					onClick={() => {
						setSelectedFragmentType('user');
						setProductId('new');
						datalakeModal.show();
					}}>
					<div className='h-75 w-100 text-center d-flex align-items-center justify-content-center'>
						<FaPlus size={75} color='#71788B' className='mt-5' />
					</div>
					<div className='px-3 pb-4'>
						<p className='shop-description text-white fs-4 mb-0 mt-2 px-2'>Add</p>
					</div>
				</div>
				{users?.map((user) => (
					<div className='row-item position-relative grey-card'>
						<div
							className='card-top-section cover-shadow px-3 pt-3'
							onClick={() => {
								setProductId(user?.id);
								datalakeModal.show();
							}}>
							<p>{striptags(user.text)}</p>
						</div>
						<div className='card-bottom-section px-3'>
							<div className='d-flex justify-content-between align-items-center p-2'>
								<div className='d-flex justify-content-between align-items-center gap-2'>
									<AvatarGroup showName={!user?.images?.[0]?.url}>
										<Avatar
											srcSet={user?.images?.[0]?.url}
											src={user?.images?.[0]?.url ?? ''}
											userName={`${user?.name}`}
										/>
									</AvatarGroup>

									<p className='uppercase'>{shorten(user?.name, 10)}</p>
								</div>
								<CardActions>
									<Dropdown>
										<DropdownToggle hasIcon={false}>
											<Button
												icon='MoreHoriz'
												// color={themeStatus}
												shadow='default'
												aria-label='Edit'
												className='p-0'
											/>
										</DropdownToggle>
										<DropdownMenu
											isAlignmentEnd
											style={{ bottom: '20px', right: 0 }}>
											<DropdownItem>
												<Button
													icon='Visibility'
													onClick={() => {
														setProductId(user?.id);
														datalakeModal.show();
													}}>
													View
												</Button>
											</DropdownItem>
											<DropdownItem isDivider />
											<DropdownItem>
												<Button
													icon='Delete'
													onClick={() => {
														deleteDataFragment(user);
													}}>
													Delete
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</CardActions>
							</div>
						</div>
					</div>
				))}
			</ScrollContainer>
		</div>
	);
}

export default Users;
