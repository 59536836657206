import {useContext, useEffect, useState} from "react";
import {
	Query,
	collection,
	deleteDoc,
	doc,
	onSnapshot,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import { useFirestore } from "reactfire";
import { arraysEqual } from "../util/util";
import { UserContext } from "../contexts/UserContext";
import { ClientContext } from "../contexts/ClientContext";
import { DataFragment } from "../models/models";

export const ROOT_FOLDER = ["/"];

export default function useFolders<T extends {folder: string[], id: string, lastUpdated: string}>(rootCollection: string, filterMethod: (obj: T, filter: string) => boolean, initialFolder: string[] = ROOT_FOLDER) {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
	const {activeClientId } = useContext(ClientContext);
  const {activeUser, isAuthenticated } = useContext(UserContext);
  const [currentFolder, setCurrentFolder] = useState<string[]>(initialFolder);
  const [filesInChildFolders, setFilesInChildFolders] = useState<T[]>([]);
  const [folders, setFolders] = useState<{path: string, name: string, fileCount: number}[]>([]);
  const [allFiles, setAllFiles] = useState<T[]>([]);
  const [files, setFiles] = useState<T[]>([]);
  const [file, setFile] = useState<T | undefined>(undefined);
  const [fileId, setFileId] = useState<string>();
  const [suggestedfiles, setSuggestedFiles] = useState<T[]>([]);
	const [filter, setFilter] = useState("");
  
  const [filteredAllFiles, setFilteredAllFiles] = useState<T[]>([]);
  const [filteredSuggestedFiles, setFilteredSuggestedFiles] = useState<T[]>([]);
  const [filteredFilesInChildFolders, setFilteredFilesInChildFolders] = useState<T[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<T[]>([]);
  
  const appendToAllFiles = (fragment: T) => {
		setAllFiles((prev) => [...prev.filter((pk) => pk.id !== fragment.id), fragment]);
	};

  useEffect(() => {
    setFilteredFiles(files.filter(filterFile).sort((a,b) => a.lastUpdated > b.lastUpdated ? -1 : 1));
  }, [files, filter])

  useEffect(() => {
    setFilteredAllFiles(allFiles.filter(filterFile).sort((a,b) => a.lastUpdated > b.lastUpdated ? -1 : 1));
  }, [allFiles, filter])

  useEffect(() => {
    setFilteredSuggestedFiles(allFiles.filter(filterFile).sort((a,b) => a.lastUpdated > b.lastUpdated ? -1 : 1)?.slice(0, 5));
  }, [allFiles, filter])

  useEffect(() => {
    setFilteredFilesInChildFolders(filesInChildFolders.filter(filterFile));
  }, [filesInChildFolders, filter])

	useEffect(() => {
		if (!isAuthenticated || !activeClientId) {
			return;
		} else {
			setLoading(true);
			// console.log('Fetching for client id', isAuthenticated, activeUser, activeClientId);
			const q = query(
				collection(firestore, `${rootCollection}`),
				where('clientId', '==', activeClientId),
			) as Query<T>;
			setAllFiles([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendToAllFiles({
								...change.doc.data(),
								id: change.doc.id,
							} as T);
						} else if (change.type == 'modified') {
							appendToAllFiles({
								...change.doc.data(),
								id: change.doc.id,
							} as T);
						} else if (change.type == 'removed') {
							setAllFiles((prev) =>
								prev.filter((p) => p.id !== change.doc.data().id),
							);
						}
					});
					setLoading(false);
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [activeClientId, isAuthenticated]);

  useEffect(()=> {
    setFiles(allFiles?.filter((p) => arraysEqual(p.folder, currentFolder) || (!p.folder && arraysEqual(currentFolder, ROOT_FOLDER))));
    setSuggestedFiles(allFiles?.sort((a,b) => a.lastUpdated > b.lastUpdated ? -1 : 1)?.slice(0, 5));
    setFilesInChildFolders(allFiles?.filter(
      (p) => ((p.folder ?? ROOT_FOLDER).length > currentFolder.length && arraysEqual((p.folder ?? ROOT_FOLDER).slice(0, currentFolder.length), currentFolder))));
  }, [allFiles, currentFolder])

  useEffect(()=> {
    const folderNames = [...new Set(filteredFilesInChildFolders?.map(p => p.folder[currentFolder.length]))];
    const folderObjs = folderNames.map(f => ({
      path: [...currentFolder, f].join("/"),
      name: f, 
      fileCount: filteredFilesInChildFolders.filter(p => p.folder[currentFolder.length] == f).length}));
      // console.log({folderNames, currentFolder, folderObjs});
      setFolders(folderObjs);
  }, [filteredFilesInChildFolders, currentFolder]);

  useEffect(() => {
		if (!fileId || fileId == 'new') {
			setFile(undefined);
			return;
		} else {
			const unsubscribe = onSnapshot(
				doc(firestore, `${rootCollection}/${fileId}`),
				(snapshot) => {
					const record = snapshot.data() as T;
					setFile(record as T);
				},
				(err) => {
					console.log('err on fetching file', err);
				},
			);
			return unsubscribe;
		}
	}, [fileId]);

  const openFolder = (path: string) => {
    console.log("setting currnet folder", path, ["/", ...path.split("/").slice(2)]);
    setCurrentFolder(["/", ...path.split("/").slice(2)]);
  }

  const openChildFolder = (folderName: string) => {
    setCurrentFolder(prev => [...prev, folderName]);
  }

  const openBreadCrumb = (folderName: string) => {
    const path = currentFolder.slice(0,currentFolder.indexOf(folderName)+1).join("/")
    openFolder(path);
  }

  const saveFile = async (file: T) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await setDoc(fileRef, {...file, lastUpdated: new Date().toISOString()}, { merge: true });
    await setLoading(false);
  }
  
  const createFile = async (file: T) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await setDoc(fileRef, {...file, folder: currentFolder, lastUpdated: new Date().toISOString()}, { merge: true });
    await setLoading(false);
  }

  const deleteFile = async (file: T) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id);
    await deleteDoc(fileRef);
    await setLoading(false);
  }

  const filterFile = (file: T) => filterMethod(file, filter);

  const isRootFolder = arraysEqual(currentFolder, ROOT_FOLDER);

  

  return {currentFolder, folders, loading, fileId, file, filter,
    files: filteredFiles, 
    suggestedfiles: filteredSuggestedFiles, 
    allFiles: filteredAllFiles, 
    setFilter, openFolder, openChildFolder, openBreadCrumb, saveFile, createFile, deleteFile, setFileId, isRootFolder };
}