import React, { useContext, useRef, useState } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { useFirebaseApp } from 'reactfire';
import { firebaseConfig } from '../Firebase/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { isValidUrl, uniqueId, urlToFileObject } from '../util/util';
import { UserContext } from '../contexts/UserContext';
import useTranscribe from '../hooks/useTranscribe';
import Button from './bootstrap/Button';
import Spinner from './bootstrap/Spinner';
import { WorkflowContext } from '../contexts/WorkflowContext';
import { ClientContext } from '../contexts/ClientContext';
import Swal from 'sweetalert2';
import useDarkMode from '../hooks/useDarkMode';

const FileInput = ({
	setFileUrl,
	convertWebToText,
	uploadingWeb,
	setUsingFileInput,
	setFileInputType,
	fromFormComponent,
	selectedFileType
}: {
	setFileUrl: (url: string, text: string, type: 'speech' | 'pdf' | 'web') => void;
	uploadingWeb: boolean;
	fromFormComponent: boolean;
	convertWebToText: () => void;
	setUsingFileInput: React.Dispatch<React.SetStateAction<boolean>>;
	setFileInputType: React.Dispatch<React.SetStateAction<string>>;
	selectedFileType?: string;
}) => {
	const app = useFirebaseApp();
	const { profile } = useContext(UserContext);
	const [file, setFile] = useState<any | null>(null);
	const [uploadingPdf, setUploadingPdf] = useState(false);
	const [uploadingSpeech, setUploadingSpeech] = useState(false);
	const [openUrlModal, setOpenUrlModal] = useState(false);
	const [uploadingUrl, setUploadingUrl] = useState(false);
	const [urlInput, setUrlInput] = useState('');
	const { activeClientId } = useContext(ClientContext);
	const inputRef = useRef<any>(null);
	const { workflow } = useContext(WorkflowContext);
	const recorderControls = useAudioRecorder(
		{
			noiseSuppression: true,
			echoCancellation: true,
		},
		(err) => console.table(err),
	);
	const { getDocumentToText, getSpeechToText } = useTranscribe();

	const handleRecordingComplete = async (blob: Blob) => {
		await uploadAudio(blob);
	};

	const uploadAudio = async (blob: Blob) => {
		setUploadingSpeech(true);
		const LISTING_BUCKET = firebaseConfig.listingBucket;
		const storage = getStorage(app, LISTING_BUCKET);
		const yymmdd = new Date().toISOString().slice(0, 10).replace(/-/g, '');
		try {
			const fileContainer = ref(
				storage,
				`${profile?.clientIds?.[0]}/audios/${yymmdd}/${uniqueId()}/${profile?.name
					?.toLowerCase()
					.replace(/\s/g, '_')}.webm`,
			);
			await uploadBytes(fileContainer, blob);
			const downloadUrl = await getDownloadURL(fileContainer);
			if (downloadUrl) {
				const audioFile = await urlToFileObject(
					downloadUrl,
					`${profile?.name?.toLowerCase().replace(/\s/g, '_')}.webm`,
				);
				const result = await getSpeechToText(downloadUrl, workflow?.clientId ?? 'clientId');
				setFileUrl(downloadUrl, result, 'speech');
			}
		} catch (err) {
			console.log({ err });
		}
		setUploadingSpeech(false);
	};

	const handleFileChange = async (event: any) => {
		setUploadingPdf(true);
		const fileObj = event?.target?.files && event?.target?.files[0];
		console.log("fileObj: " + fileObj)
		if (!fileObj) {
			return;
		}
		event.target.value = null;
		setFile(fileObj);
		if (fileObj) {
			console.log("file.type", fileObj.type)
			if (fileObj?.type === 'application/pdf') {
				const LISTING_BUCKET = firebaseConfig.listingBucket;
				const storage = getStorage(app, LISTING_BUCKET);
				const yymmdd = new Date().toISOString().slice(0, 10).replace(/-/g, '');
				try {
					const fileContainer = ref(
						storage,
						`${activeClientId}/documents/${yymmdd}/${uniqueId()}/${fileObj?.name}`,
					);
					await uploadBytes(fileContainer, fileObj);
					const downloadUrl = await getDownloadURL(fileContainer);
					const result = await getDocumentToText(downloadUrl);
					console.log("result-",`start${result}end`);
					console.log("result length",result.length);
					if(result.length > 0){
						setFileUrl(downloadUrl, result, 'pdf');
					}
					else {
						Swal.fire({
							icon: 'warning',
							title: 'Invalid File',
							text: 'Please upload Valid Document that contains data.',
							showCancelButton: false,
							showConfirmButton: true,
							allowOutsideClick: true,
						});
					}
				} catch (err) {
					throw err;
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Invalid File',
					text: 'Please upload Valid Document.',
					showCancelButton: false,
					showConfirmButton: true,
					confirmButtonColor: 'rgba(0, 56, 255, 1)',
					allowOutsideClick: true,
				});
			}
		}
		setUploadingPdf(false);
	};

	const handleClick = () => {
		if (!!inputRef?.current) {
			inputRef?.current.click();
		} else {
			console.log("inpuref not found", inputRef);
		}
	};
	const [openMenu, setOpenMenu] = useState(false);

	const { getWebToText } = useTranscribe();
	const { darkModeStatus } = useDarkMode();

	const handleSubmit = async (url: string) => {
		setUploadingUrl(true);
		if (!!isValidUrl(url)) {
			const result = await getWebToText({
				url: url,
				referenceId: workflow?.clientId ?? 'clientId',
			});
			setFileUrl(url, result?.htmlTextArray, 'web');
			// setValue(result?.htmlTextArray);
			console.log('url result', result.htmlTextArray);
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Invalid Url.',
				allowOutsideClick: true,
				confirmButtonText: 'Okay',
				cancelButtonColor: '#38cab3',
				confirmButtonColor: 'rgba(0, 56, 255, 1)',
			});
		}
		setUploadingUrl(false);
		setOpenUrlModal(false);
	};

	return (
		<>
			<div>
				<div
					className='modal'
					role='dialog'
					style={{ display: openUrlModal ? 'block' : 'none' }}>
					<div className='modal-dialog' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title'>Enter URL</h5>
								<button
									type='button'
									className='close'
									onClick={() => {
										setOpenUrlModal(false);
									}}>
									<span>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								<input
									type='text'
									className='form-control'
									value={urlInput}
									onChange={(e) => setUrlInput(e.target.value)}
								/>
							</div>
							<div className='modal-footer'>
								<button
									type='button'
									className='btn btn-Secondary'
									onClick={() => {
										setOpenUrlModal(false);
									}}>
									Cancel
								</button>
								<button
									type='button'
									className='btn btn-blue btn py-2 px-4'
									style={{ marginLeft: '20px' }}
									disabled={uploadingUrl}
									onClick={() => {
										handleSubmit(urlInput);
									}}>
									{uploadingUrl ? (
										<span>
											Saving <Spinner isSmall inButton color={'light'} />
										</span>
									) : (
										<span>Submit</span>
									)}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='d-flex mt-4 mx-4 position-relative input-container'>
				<div>
					<div
						className={`input-option-btn-wrapper
							show position-absolute h-auto`}
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}>
						{fromFormComponent ? <Button
							icon='KeyboardVoice'
							onClick={(e: any) => {
								e?.preventDefault();
								if (selectedFileType == "audioScrap") {
									setUsingFileInput(false);
									setFileInputType("");
								} else {
									setUsingFileInput(true);
									setFileInputType("audioScrap");
								}
							}}
							style={{
								border: 'none',
								backgroundColor: 'white',
								padding: 12,
							}}
							className={`my-2 input-option-btn ${darkModeStatus ? 'shadow-sm' : ''}`}></Button> : <div className='my-2'>
							<AudioRecorder
								onRecordingComplete={handleRecordingComplete}
								recorderControls={recorderControls}
								audioTrackConstraints={{
									noiseSuppression: true,
									echoCancellation: true,
								}}
								onNotAllowedOrFound={(err) => console.table(err)}
								downloadOnSavePress={false}
								downloadFileExtension="webm"
								mediaRecorderOptions={{
									audioBitsPerSecond: 128000,
								}}
								showVisualizer={true}
							/>

						</div>}
						<input
							style={{ display: 'none' }}
							ref={inputRef}
							disabled={uploadingSpeech}
							type='file'
							onChange={handleFileChange}
						/>
						{!uploadingPdf && !uploadingSpeech && (
							<Button
								icon='AttachFile'
								onClick={(e: any) => {
									e?.preventDefault();
									setUsingFileInput(false);
									setFileInputType("");
									handleClick();
								}}
								isDisable={uploadingPdf}
								style={{
									border: 'none',
									backgroundColor: 'white',
									padding: 12,
								}}
								className={`my-2 input-option-btn ${darkModeStatus ? 'shadow-sm' : ''}`}></Button>
						)}
						{!uploadingWeb && !uploadingSpeech && (
							<Button
								icon='Language'
								onClick={() => {
									if (fromFormComponent) {
										if (selectedFileType == "websiteScrap") {
											setUsingFileInput(false);
											setFileInputType("");
										} else {
											setUsingFileInput(true);
											setFileInputType("websiteScrap");
										}
									} else {
										setOpenUrlModal(true);
									}
								}}
								isDisable={uploadingWeb}
								style={{
									border: 'none',
									backgroundColor: 'white',
									padding: 12,
								}}
								className={`my-2 input-option-btn ${darkModeStatus ? 'shadow-sm' : ''}`}></Button>
						)}
						{(uploadingSpeech || uploadingPdf || uploadingWeb) && (
							<Spinner className='my-2 input-option-btn' />
						)}
					</div>
				</div>
				{/* <Button
					icon={openMenu ? 'Close' : 'Menu'}
					onClick={() => setOpenMenu(!openMenu)}
					style={{ border: 'none', backgroundColor: 'white' }}
					className='my-2 input-option-btn option-toggle-btn'></Button> */}
			</div>
		</>
	);
};

export default FileInput;
