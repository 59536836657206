import { useContext, useEffect, useState } from "react";
import "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Gallery, ListingRequest, PRODUCT_STATUS, Product, ProductTestimonial } from '../models/product';
import slug from 'slug';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useFirebaseApp, useFirestore } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { CompanyContext } from "../contexts/CompanyContext";
import { useList } from "react-use";
import { HighlightedFeatures } from "../redux/active-product/model";
import { SuccessStories } from "../models/company";

const PRODUCT = "product";
export enum RequestType {
    deleteProduct = "deleteProduct",
    listProduct = "listProduct",
    getGooglePlacesByQuery = "getGooglePlacesByQuery",
    getGooglePlacesByQueryGrid = "getGooglePlacesByQueryGrid",
}

export default function useProduct(inputProductId: string) {
  const firestore = useFirestore();
  const firebase = useFirebaseApp();
  const functions = getFunctions(firebase);
  const [isBusy, setIsBusy] = useState(false);
  const [productId, setProductId] = useState(inputProductId);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const {activeCompany} = useContext(CompanyContext);
  const [basicInfo, setBasicInfo] = useState<Partial<Product>>({businessName: '', productName: '', description: '', sku: ''});
  const [highlightedFeatures, {set: setHighlightedFeatures, upsert: upsertHighlightedFeatures, removeAt: removeHighlightedFeaturesAt}] = useList<HighlightedFeatures>([]);
  const [benefits, {set: setBenefits, upsert: upsertBenefits, removeAt: removeBenefitsAt}] = useList<string>([]);
  const [pricingAndPackages, setPricingAndPackages] = useState<Partial<Product>>({pricing: '', delivery: '', supportAndMaintenance: ''});
  const [testimonials, {set: setTestimonials, upsert: upsertTestimonial, removeAt: removeTestimonialAt}] = useList<ProductTestimonial>([]);
  const [complianceAndcertifications, {set: setComplianceAndcertifications, upsert: upsertComplianceAndcertification, removeAt: removeComplianceAndcertificationAt}] = useList<string>([]);
  const [eventsOrActivities, {set: setEventsOrActivities, upsert: upsertEventOrActivity, removeAt: removeEventOrActivityAt}] = useList<string>([]);
  const [newsOrExogenous, {set: setNewsOrExogenous, upsert: upsertNewsOrExogenous, removeAt: removeNewsOrExogenousAt}] = useList<string>([]);
  const [successStories, {set: setSuccessStories, upsert: upsertSuccessStory, removeAt: removeSuccessStoryAt}] = useList<SuccessStories>([]);
  const [gallery, {set: setGallery}] = useList<Gallery>([]);

  const removeBenefit = (value: string) => removeBenefitsAt(benefits.indexOf(value));
  const removeComplianceAndcertification = (value: string) => removeComplianceAndcertificationAt(complianceAndcertifications.indexOf(value));
  const removeEventOrActivity = (value: string) => removeEventOrActivityAt(eventsOrActivities.indexOf(value));
  const removeNewsOrExogenous = (value: string) => removeNewsOrExogenousAt(newsOrExogenous.indexOf(value));
  const removeHighlightedFeature = (value: HighlightedFeatures) => removeHighlightedFeaturesAt(highlightedFeatures.findIndex((v) => v.title == value.title && v.description == value.description));
  const removeTestimonial = (value: ProductTestimonial) => removeTestimonialAt(testimonials.findIndex((v) => v.name == value.name && v.description == value.description));
  const removeSuccessStory = (value: SuccessStories) => removeSuccessStoryAt(successStories.findIndex((v) => v.name == value.name && v.description == value.description));

  const initializeVM = (product: Product) => {
    if(product) {
      const {businessName, productName, description, sku} = product;
      const {pricing, delivery, supportAndMaintenance} = product;
      setBasicInfo({businessName, productName, description, sku});
      setHighlightedFeatures(product.highlightedFeatures ?? []);
      setBenefits(product.benefits ?? []);
      setPricingAndPackages({pricing, delivery, supportAndMaintenance});
      setComplianceAndcertifications(product.complianceAndcertifications ?? []);
      setEventsOrActivities(product.eventsOrActivities ?? []);
      setNewsOrExogenous(product.newsOrExogenous ?? []);
      setTestimonials(product.testimonials ?? []);
      setSuccessStories(product.successStories ?? []);
      setGallery(product.gallery ?? []);
    }
  }

  useEffect(() => {
    if (!productId) {
      setProduct(undefined);
      return;
    } else if (productId == "new") {
      setProduct(undefined);
      intializeVMForNew();
      return;
    } else {
      resetVM();
      const unsubscribe = onSnapshot(doc(firestore, `${FIRESTORE.PRODUCT}/${productId}`), (snapshot) => {
          const productRecord = snapshot.data() as Product;
          if(!product || product.id !== productRecord.id)
            initializeVM(productRecord);
          setProduct(productRecord as Product);
      }, (err) => { console.log("err on fetching activeuser", err); });
      return unsubscribe;
    }
  }, [productId]);

  const resetVM = () => {
    setBasicInfo({businessName: '', productName: '', description: '', sku: ''});
    setPricingAndPackages({pricing: '', delivery: '', supportAndMaintenance: ''});
    setHighlightedFeatures([]);
    setBenefits([]);
    setComplianceAndcertifications([]);
    setEventsOrActivities([]);
    setNewsOrExogenous([]);
    setTestimonials([]);
    setSuccessStories([]);
    setGallery([]);
}

  const intializeVMForNew = () => {
    setBasicInfo({businessName: activeCompany?.name ?? '', productName: '', description: '', sku: ''});
    setPricingAndPackages({pricing: '', delivery: '', supportAndMaintenance: ''});
    setHighlightedFeatures([]);
    setBenefits([]);
    setComplianceAndcertifications([]);
    setEventsOrActivities([]);
    setNewsOrExogenous([]);
    setTestimonials([]);
    setSuccessStories([]);
    setGallery([]);
  }

  const reconstruct = (vm: Partial<Product>, dbProduct: Product | undefined, images?: Gallery[]): ListingRequest => {
      const idSlug = slug(`${vm.productName ?? dbProduct?.productName}-${new Date().getTime()}`);

      const partialProduct: Partial<Product> = {
          ...vm,
          id: dbProduct?.id ?? idSlug,
          image: images?.[0]?.url ?? dbProduct?.image,
          gallery: images ?? dbProduct?.gallery,
          createdAt: dbProduct?.createdAt ?? new Date().toISOString(),
          lastUpdated: new Date().toISOString(),
          status: vm?.status ?? dbProduct?.status ?? PRODUCT_STATUS.ACTIVE,
      }
      return { product: partialProduct };
  };

  const fetchProduct = async (productId: string): Promise<Product> => {
      const ref = doc(firestore, `${PRODUCT}/${productId}`);
      const product = (await getDoc(ref)).data() as Product;
      return product;
  };

  const save = async (partialProductViewModel: Partial<Product>, images?: Gallery[]): Promise<boolean | string> => {
      setIsBusy(true);
      try {
          const saveRequest = reconstruct(partialProductViewModel, product, images);
          const saveProduct = httpsCallable(functions, "product-productCall");
          await saveProduct({ requestType: RequestType.listProduct, input: saveRequest });
          setProductId(saveRequest.product.id!);
          setIsBusy(false);
          return true;
      } catch (err: any) {
          console.error("Error adding/updating product", err);
          setIsBusy(false);
          return err.toString();
      }
  };
    return {productId, setProductId, product, basicInfo, gallery, 
      highlightedFeatures, upsertHighlightedFeatures, removeHighlightedFeature, 
      testimonials, upsertTestimonial, removeTestimonial,
      complianceAndcertifications, upsertComplianceAndcertification, removeComplianceAndcertification,
      eventsOrActivities, upsertEventOrActivity, removeEventOrActivity,
      newsOrExogenous, upsertNewsOrExogenous, removeNewsOrExogenous,
      successStories, upsertSuccessStory, removeSuccessStory,
      benefits, upsertBenefits, removeBenefit, pricingAndPackages, save, isBusy, resetVM }
}