import { useFirestore } from 'reactfire';
import { useState, useEffect } from 'react';
import { Query, collection, onSnapshot, query, where } from 'firebase/firestore';
import { FIRESTORE } from '../Firebase/firestore';
import { DbUser } from '../Firebase/models';
import { ActiveUser } from '../redux/active-item/active-itemSlice';

export default function useUsers(activeUser?: ActiveUser) {
	const firestore = useFirestore();
	const [users, setusers] = useState<DbUser[]>([]);

	const appendusers = (list: DbUser) => {
		setusers((prev) => [...prev.filter((pk) => pk.id !== list.id), list]);
	};

	useEffect(() => {
		if (!activeUser?.clientIds) {
			return;
		}
		const q = query(
			collection(firestore, `${FIRESTORE.USER}`),
			where('clientIds', 'array-contains-any', activeUser?.clientIds),
		) as Query<DbUser>;
		setusers([]);
		const unsubscribe = onSnapshot(
			q,
			(snapshot) => {
				snapshot.docChanges().forEach((change) => {
					if (change.type == 'added') {
						appendusers({ ...change.doc.data(), id: change.doc.id } as DbUser);
					} else if (change.type == 'modified') {
						appendusers({ ...change.doc.data(), id: change.doc.id } as DbUser);
					} else if (change.type == 'removed') {
						setusers((prev) => prev.filter((p) => p.id !== change.doc.data().id));
					}
				});
			},
			(err) => {
				console.log({ fetchUserError: err });
			},
		);
		return unsubscribe;
	}, [activeUser]);

	return {
		users,
	};
}
