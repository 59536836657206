import { useContext, useEffect, useState } from "react";
import { collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useFirestore, useFunctions } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { getAuth } from "firebase/auth";
import { UserContext } from "../contexts/UserContext";
import { Workflow, WorkflowCampaign, WorkflowTemplate } from "../models/models";
import { ClientContext } from "../contexts/ClientContext";

const rootCollectionWorkflow = FIRESTORE.WORKFLOW;
const rootCollectionWorkflowTemplate = FIRESTORE.WORKFLOW_TEMPLATE;

export default function useWorkflows() {
  const firestore = useFirestore();
  const [loading, setLoading] = useState(false);
  const { activeUser, isAuthenticated } = useContext(UserContext);
  const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>([]);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [campaigns, setCampaigns] = useState<WorkflowCampaign[]>([]);
  const {activeClientId} = useContext(ClientContext);

  const appendCampaignTemplate = (template: WorkflowTemplate) => {
    setWorkflowTemplates(prev => [...prev.filter(pk => pk.id !== template.id), template])
  }
  const appendWorkflows = (workflow: Workflow) => {
    setWorkflows(prev => [...prev.filter(pk => pk.id !== workflow.id), workflow])
  }
  const appendCampaigns = (campaign: WorkflowCampaign) => {
    setCampaigns(prev => [...prev.filter(pk => pk.campaignId !== campaign.campaignId), campaign])
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    } else {
      setLoading(true);
      const q = query(collection(firestore, `${rootCollectionWorkflowTemplate}`));
    
      setWorkflows([]);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type == "added") {
            appendCampaignTemplate({ ...change.doc.data(), id: change.doc.id } as WorkflowTemplate);
          } else if (change.type == "modified") {
            appendCampaignTemplate({ ...change.doc.data(), id: change.doc.id } as WorkflowTemplate);
          } else if (change.type == "removed") {
            setWorkflowTemplates(prev => prev.filter(p => p.id !== change.doc.data().id))
          }
        })
        setLoading(false);
      }, (err) => { console.log(err) });
      return unsubscribe;
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated || !activeClientId) {
      return;
    } else {
      setLoading(true);
      console.log("fetching workflows..."+activeClientId);
      const q = query(collection(firestore, `${rootCollectionWorkflow}`),where("status", "==", "active"  ), where("clientId", "in", (!!activeClientId ? [activeClientId] : [activeUser?.id])));
      setWorkflows([]);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log("workflows fetched");
        snapshot.docChanges().forEach((change) => {
          if (change.type == "added") {
            appendWorkflows({ ...change.doc.data(), id: change.doc.id } as Workflow);
          } else if (change.type == "modified") {
            appendWorkflows({ ...change.doc.data(), id: change.doc.id } as Workflow);
          } else if (change.type == "removed") {
            setWorkflows(prev => prev.filter(p => p.id !== change.doc.data().id))
          }
        })
        setLoading(false);
      }, 
      (err) => { console.log(err) });
      return unsubscribe;
    }
  }, [isAuthenticated, activeUser, activeClientId])

  useEffect(() => {
    if (!isAuthenticated || !activeClientId) {
      return;
    } else {
      setLoading(true);
      console.log("fetching campaigns..."+activeClientId);
      const q = query(collection(firestore, `${FIRESTORE.CAMPAIGN}`), where("clientId", "in", (!!activeClientId ? [activeClientId] : [activeUser?.id])));
      setCampaigns([]);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        console.log("campaigns fetched");
        snapshot.docChanges().forEach((change) => {
          if (change.type == "added") {
            appendCampaigns({ ...change.doc.data(), campaignId: change.doc.id } as WorkflowCampaign);
          } else if (change.type == "modified") {
            appendCampaigns({ ...change.doc.data(), campaignId: change.doc.id } as WorkflowCampaign);
          } else if (change.type == "removed") {
            setCampaigns(prev => prev.filter(p => p.campaignId !== change.doc.data().id))
          }
        })
        setLoading(false);
      }, 
      (err) => { console.log(err) });
      return unsubscribe;
    }
  }, [isAuthenticated, activeUser, activeClientId])


  // const saveWorkflow = async (workflow: Workflow) => {
  //   await setLoading(true);
  //   const fileRef = await doc(firestore, rootCollectionWorkflow, workflow?.id);
  //   await setDoc(fileRef, workflow, { merge: true });
  //   await setLoading(false);
  //   return workflow;
  // }

  const saveWorkflow = async (workflow: Workflow) => {
    await setLoading(true);
    const fileRef = doc(firestore, rootCollectionWorkflow, workflow?.id);
    await setDoc(fileRef, { ...workflow, status: "active" }, { merge: true });
    await setLoading(false);
    return workflow;
  }


  return { workflows, workflowTemplates, loading, saveWorkflow, campaigns};
}