
import { useFirestore, useFunctions } from 'reactfire';
import { useState, useEffect, useContext } from 'react';
import { Query, collection, doc, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { FIRESTORE } from "../Firebase/firestore";
import { UserContext } from "../contexts/UserContext";
import { Client, Prompt, Workflow, WorkflowStepTemplate,Function } from "../models/models";
import { httpsCallable } from "firebase/functions";
import { ChatCollaboration, ChatCompletionResponse } from '../models/chat';
import { ClientContext } from '../contexts/ClientContext';
import { WorkflowContext } from '../contexts/WorkflowContext';
import {substituteShallow} from '../util/promptutil'
import { ChatConversation } from '../models/chatgpt/models';

enum RequestType {
  aiCollaboration = "aiCollaboration",
  stepAiCollaboration = "stepAiCollaboration"
}

const CHAT_GPT_CALL = "chatGPT-chatGPTCall";

export default function useChatCollaborator() {
    const firebaseFunctions = useFunctions();
    const firestore = useFirestore();
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState<ChatCollaboration[]>([]);
    const [activeChat, setActiveChat] = useState<ChatCollaboration | undefined>();
    const {activeUser } = useContext(UserContext);
    const {activeClientId} = useContext(ClientContext);
    const {workflow,workflowStep,workflowId} = useContext(WorkflowContext)
    const [newClientId, setNewClientId] = useState({})
    const sortedChats = chats?.sort((a,b) => a.lastUpdated > b.lastUpdated ? -1 : 1);

    const appendChats = (chat: ChatCollaboration) => {
      setChats(prev => [...prev.filter(pk => pk.id !== chat.id), chat]);
    }

    useEffect(() => {
        if (!activeUser?.clientIds?.length) {
            return;
        } else {
            const q = query(collection(firestore, FIRESTORE.CHAT_COLLABORATOR), where('clientId', "==", activeClientId)) as Query<ChatCollaboration>;
            setChats([]);
            const unsubscribe = onSnapshot(q, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type == "added") {
                        appendChats({ ...change.doc.data(), id: change.doc.id } as ChatCollaboration);
                    } else if (change.type == "modified") {
                        appendChats({ ...change.doc.data(), id: change.doc.id } as ChatCollaboration);
                    } else if (change.type == "removed") {
                        setChats(prev => prev.filter(p => p.id !== change.doc.data().id))
                    }
                })
            }, (err) => { console.log(err) });
            return unsubscribe;
        }
    }, [activeClientId])


    const newChatCollaboration = async () => {
      setActiveChat(undefined);
      setLoading(false);
    }

    const chatGPTName = (input?: string) => (input ?? "").replaceAll(".", "-").replaceAll("/", "_").replace(/[^a-zA-Z0-9_-]/g, "").substring(0, 60);

    const send = async (content: string, chatId?: string) => {
      setLoading(true);
      const chatGPT = httpsCallable(firebaseFunctions, CHAT_GPT_CALL);
      try {
        const response = await chatGPT({requestType: RequestType.aiCollaboration, clientId: activeClientId, message: {role: "user", name: chatGPTName(activeUser?.name), content},
          id: chatId, promptId: 'ai-collaborator-output'});
          setActiveChat(response.data as ChatCollaboration);
          console.log(response.data);

        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    }


    const sendAiCollaborator = async (content: string, chatId?: string) => {
      setLoading(true);
      const chatGPT = httpsCallable(firebaseFunctions, CHAT_GPT_CALL);
      try {
        const response = await chatGPT({requestType: RequestType.stepAiCollaboration, clientId: activeClientId, message: {role: "user", name: chatGPTName(activeUser?.name), content},
          id: chatId,workflowStep,workflowId});
          setActiveChat(response.data as ChatCollaboration);
          console.log(response.data);

        setLoading(false);
      } catch(err) {
        setLoading(false);
      }
    }
    

    return {
      chats: sortedChats, activeChat, setActiveChat, newChatCollaboration, send, loading, setLoading,sendAiCollaborator
    }

}