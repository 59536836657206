import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import AuthContext from '../../contexts/authContext';
import { authMenu } from '../../menu';
import { RootState } from '../../store/store';
import { useSelector } from "react-redux";
import { useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { UserContext } from '../../contexts/UserContext';
import Lottie from 'react-lottie';
import loadJson from '../../Lottie/93486-load.json';


interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
	| ReactElement<ISubHeaderProps>[]
	| ReactElement<IPageProps>
	| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		const { isAuthenticated, setRedirectUrl } = useContext(UserContext);
		const app = useFirebaseApp();
		const auth = getAuth(app);

		const navigate = useNavigate();
		const location = useLocation();
    useEffect(() => {
      if(typeof isAuthenticated !== "undefined") {
        if (isProtected && !isAuthenticated) {
			setRedirectUrl(location.pathname);
          navigate(authMenu.login.path);
        }
      }
		}, [isAuthenticated]);

		return (
      <div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
      {typeof isAuthenticated === "undefined" ? (
      <div className='d-flex justify-content-center align-items-center overflow-hidden' style={{ height: 800 }}>
      <Lottie height={400} width={400} options={{ loop: true, autoplay: true, animationData: loadJson }} />
      </div>)
        :
          <>{children}</>
      }
        </div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
