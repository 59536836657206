import React, { FC } from 'react';
import PropTypes from 'prop-types';
import whiteLogo from '../assets/img/mai-logo.svg';
import blackLogo from '../assets/img/mai-logo-black.svg';



interface ILogoProps {
	width?: number;
	height?: number;
	color?: string;
}
const Logo: FC<ILogoProps> = ({ width, height, color }) => {
	return (
		<img src={color == 'black' ? blackLogo : whiteLogo} loading='lazy' width={height !== 854 && !!height ? height * (2155 / 854) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height} alt="logo" />
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	color: PropTypes.string,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	color: 'white'
};

export default Logo;
