import React, { useRef, useState } from 'react';
import { PaymentElement, useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Stripe from 'stripe';
import { Client } from '../../../../models/models';
import Spinner from '../../../../components/bootstrap/Spinner';

export var Card = function ({
	stripe,
	activeClient,
	returnUrl,
}: {
	stripe: Stripe;
	activeClient: Client;
	returnUrl: string;
}) {
	const stripeHook = useStripe();
	var elements = useElements();
	const [errorMessage, setErrorMessage] = useState('');
	const [saving, setSaving] = useState(false);

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		setSaving(true);
		if (elements == null || stripeHook === null) {
			return;
		}
		const { error: submitError } = await elements.submit();
		if (submitError) {
			setErrorMessage(submitError?.message ?? '');
			return;
		}
		const intent = await stripe.setupIntents.create({
			customer: activeClient?.stripeId,
			automatic_payment_methods: { enabled: true },
		});

		if (!!intent?.client_secret) {
			return await stripeHook.confirmSetup({
				elements: elements,
				clientSecret: intent?.client_secret,
				confirmParams: {
					return_url: returnUrl,
				},
			});
		}
		await setSaving(false);
	};
	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<button
				type='submit'
				className='btn btn-blue btn py-2 px-4 mt-3'
				disabled={!stripeHook || !elements}>
				{!saving ? (
					'Submit'
				) : (
					<span>
						Submitting <Spinner isSmall inButton color={'light'} />
					</span>
				)}
			</button>
			{errorMessage && <div>{errorMessage}</div>}
		</form>
	);
};

export const StripeCardInput = ({
	stripe,
	activeClient,
	returnUrl,
}: {
	stripe: Stripe;
	activeClient: Client;
	returnUrl: string;
}) => {
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');

	return (
		<Elements
			stripe={stripePromise}
			options={{
				mode: 'setup',
				currency: 'usd',
				amount: 0,
			}}>
			<Card stripe={stripe} activeClient={activeClient} returnUrl={returnUrl} />
		</Elements>
	);
};
