import "firebase/auth";
import {useState} from "react";
import {httpsCallable} from "firebase/functions";
import { ProfileUpdateRequest } from "../models/profile";
import { useFunctions } from "reactfire";

enum RequestTypes {
  updateProfile = "updateProfile",
}

export default function useUpdateProfile() {
  const functions = useFunctions();
  const updateProfile = async (request: ProfileUpdateRequest): Promise<ProfileUpdateRequest> => {
    try {
    const userCallable = httpsCallable(functions, 'user-userCall');
      const result = await userCallable({requestType: RequestTypes.updateProfile, input: request});
      const response = result.data as ProfileUpdateRequest;
      return response;
    } catch(err) {
      console.log("Error while updating profile",err);
      throw(err);
    }
  }

  return {updateProfile};
}
