import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { TourProvider } from '@reactour/tour';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ToastContainer } from 'react-toastify';
import ThemeContext from '../contexts/ThemeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { ToastCloseButton } from '../components/bootstrap/Toasts';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import {
	AuthProvider,
	FirebaseAppProvider,
	FirestoreProvider,
	FunctionsProvider,
	StorageProvider,
	useFirebaseApp,
} from 'reactfire';
import { firebaseConfig } from '../Firebase/firebase';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { ManageAuthUser } from '../hooks/useUser';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { LoadScript } from '@react-google-maps/api';
import { UserProvider } from '../contexts/UserContext';
import { CampaignsProvider } from '../contexts/CampaignsContext';
import { LeadListProvider } from '../contexts/LeadListContext';
import { ProductsProvider } from '../contexts/ProductsContext';
import { MidJourneyProvider } from '../contexts/MidJourneyContext';
import { CompanyProvider } from '../contexts/CompanyContext';
import NiceModal from '@ebay/nice-modal-react';
import { ModuleProvider } from '../contexts/ModuleContext';
import { DatalakeProvider } from '../contexts/DatalakeContext';
import { NotificationsProvider } from '../contexts/NotificationContext';
import { ClientProvider } from '../contexts/ClientContext';
import { ChatCollaboratorProvider } from '../contexts/ChatCollaboratorContext';
import { CalendarProvider } from '../contexts/CalendarContext';
ReactGA.initialize('G-S0ZC40JSRL');

const App = () => {
	getOS();

	const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
	const firestoreInstance = getFirestore(app);
	const functionsInstance = getFunctions(app);
	const storageInstance = getStorage(app);
	const auth = getAuth(app);
	dayjs.extend(localizedFormat);
	dayjs.extend(relativeTime);
	const location = useLocation();
	useEffect(() => {
		// runs on location, i.e. route, change
		// console.log('handle route change here', location);
		ReactGA.send({ hitType: 'pageview', page: location.pathname });
	}, [location]);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
			document.documentElement.removeAttribute('data-bs-theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});
	const localClientId = localStorage.getItem('activeClientId');
	return (
		<ThemeProvider theme={theme}>
			<AuthProvider sdk={auth}>
				<NiceModal.Provider>
					<FirestoreProvider sdk={firestoreInstance}>
						<FunctionsProvider sdk={functionsInstance}>
							<StorageProvider sdk={storageInstance}>
								<UserProvider>
									<ClientProvider
										currentClientId={!!localClientId ? localClientId : ''}>
										<ChatCollaboratorProvider>
											<CompanyProvider>
												<CampaignsProvider>
													<CalendarProvider>
														<ModuleProvider>
															<LeadListProvider>
																<DatalakeProvider>
																	<MidJourneyProvider>
																		<NotificationsProvider>
																			<Provider store={store}>
																				<TourProvider
																					steps={steps}
																					styles={styles}
																					showNavigation={
																						false
																					}
																					showBadge={
																						false
																					}>
																					<LoadScript
																						googleMapsApiKey={
																							firebaseConfig.apiKey
																						}
																						libraries={[
																							'places',
																						]}>
																						<div
																							ref={
																								ref
																							}
																							className='app'
																							style={{
																								backgroundColor:
																									fullScreenStatus
																										? 'var(--bs-body-bg)'
																										: undefined,
																								zIndex: fullScreenStatus
																									? 1
																									: undefined,
																								overflow:
																									fullScreenStatus
																										? 'scroll'
																										: undefined,
																							}}>
																							{/* <AsideRoutes /> */}
																							<Wrapper />
																						</div>
																					</LoadScript>
																					<Portal id='portal-notification'>
																						<ReactNotifications />
																					</Portal>
																					<ToastContainer
																						closeButton={
																							ToastCloseButton
																						}
																						toastClassName='toast show'
																					/>
																				</TourProvider>
																			</Provider>
																		</NotificationsProvider>
																	</MidJourneyProvider>
																</DatalakeProvider>
															</LeadListProvider>
														</ModuleProvider>
													</CalendarProvider>
												</CampaignsProvider>
											</CompanyProvider>
										</ChatCollaboratorProvider>
									</ClientProvider>
								</UserProvider>
							</StorageProvider>
						</FunctionsProvider>
					</FirestoreProvider>
				</NiceModal.Provider>
			</AuthProvider>
		</ThemeProvider>
	);
};

export default App;
