import {useContext, useEffect, useState} from "react";
import { collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { useFirebaseApp, useFirestore, useFunctions } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { Campaign, CampaignTemplate } from "../models/campaign";
import { GooglePlace } from "../models/product";
import { CampaignComposedEmail } from "../models/campaign";
import { httpsCallable } from "firebase/functions";
import { cloneName, cloneNumberStampedId } from "../util/util";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { UserContext } from "../contexts/UserContext";
import { ClientContext } from "../contexts/ClientContext";

const rootCollectionCampaign = FIRESTORE.CAMPAIGN;
const rootCollectionCampaignTemplate = FIRESTORE.CAMPAIGN_TEMPLATE;

export default function useCampaigns() {
  const firestore = useFirestore();
  const functions = useFunctions();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
	const {activeUser, isAuthenticated } = useContext(UserContext);
  const {activeClientId} = useContext(ClientContext);
  const [campaignTemplates, setCampaignTemplates] = useState<CampaignTemplate[]>([]);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const appendCampaignTemplate = (campaign: CampaignTemplate) => {
    setCampaignTemplates(prev => [...prev.filter(pk => pk.id !== campaign.id), campaign])
  }
  const appendCampaigns = (campaign: Campaign) => {
    setCampaigns(prev => [...prev.filter(pk => pk.id !== campaign.id), campaign])
  }

  useEffect(() => {
    if (!isAuthenticated){
     return;   
    }else{
        setLoading(true);
        const q = query(collection(firestore, `${rootCollectionCampaignTemplate}`));
        setCampaigns([]);
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type == "added") {
              appendCampaignTemplate({...change.doc.data(), id: change.doc.id} as CampaignTemplate);
            } else if (change.type == "modified") {
              appendCampaignTemplate({...change.doc.data(), id: change.doc.id} as CampaignTemplate);
            } else if (change.type == "removed") {
              setCampaignTemplates(prev => prev.filter(p => p.id !== change.doc.data().id))
            }
          })
          setLoading(false);
        },(err)=>{console.log(err)});
        return unsubscribe;    
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated || !activeClientId){
     return;   
    }else{
        setLoading(true);
        const q = query(collection(firestore, `${FIRESTORE.CAMPAIGN}`), where("clientId", "==", (activeClientId ?? activeUser?.id)), where("status", "==", "active"), orderBy("lastUpdated", "desc"));
        setCampaigns([]);
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type == "added") {
              appendCampaigns({...change.doc.data(), id: change.doc.id} as Campaign);
            } else if (change.type == "modified") {
              appendCampaigns({...change.doc.data(), id: change.doc.id} as Campaign);
            } else if (change.type == "removed") {
              setCampaigns(prev => prev.filter(p => p.id !== change.doc.data().id))
            }
          })
          setLoading(false);
        },(err)=>{console.log(err)});
        return unsubscribe;    
    }
  }, [isAuthenticated, activeUser])


  const cloneCampaign = async (file: Campaign) => {
    await setLoading(true);
    const newId = cloneNumberStampedId(file.id);
    const newFile: Campaign = {...file, 
      id: newId, 
      name: cloneName(file.name),
      leadCount: 0,
      created: new Date().toISOString(),
      lastUpdated: new Date().toISOString(),
      selectedLeadEmails: [],
      marketSegments: []
    };


    const fileRef = await doc(firestore, rootCollectionCampaign, newId);
    await setDoc(fileRef, newFile, { merge: true });
    await setLoading(false);
    return newFile;
  }

  const deleteCampaign = async (campaign: Campaign) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollectionCampaign, campaign?.id);
    await setDoc(fileRef, {...campaign, status: "deleted"}, { merge: true });
    await setLoading(false);
  }


  return { deleteCampaign, campaigns, cloneCampaign, campaignTemplates };
}