import "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useFirebaseApp, useFirestore } from "reactfire";
import { getNameFromImageUrl } from "../util/util";
import { InputWebScrape, NewWebScrape } from "../models/webScrape";
import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { ClientContext } from "../contexts/ClientContext";

export enum RequestType {
    documentToText = "documentToText",
    speechToText = "speechToText",
    webScrape = "webScrape",
}
const CHAT_GPT_CALL = "chatGPT-chatGPTCall";
const WEB_SCRAPE_CALL = "webScrape-webScrapeCall";

export default function useTranscribe() {
    const firestore = useFirestore();
    const firebase = useFirebaseApp();
    const functions = getFunctions(firebase);
    const {activeClientId} = useContext(ClientContext);

    const getDocumentToText = async(url: string) => {
        try{
            const getResults = httpsCallable(functions, CHAT_GPT_CALL);
            const response = (await getResults({ requestType: RequestType.documentToText, clientId: activeClientId, fileName: getNameFromImageUrl(url), url}));
            return response?.data as string;
        }catch(e){
            console.log({e});
            return url;
        }
    }
    
    const getSpeechToText = async(url: string, refId: string) => {
        try {                        
            const getResults = httpsCallable(functions, CHAT_GPT_CALL);
            const response = (await getResults({ requestType: RequestType.speechToText, clientId: activeClientId, fileName: getNameFromImageUrl(url), url, refId: refId}));            
            return response?.data as string;
        }catch(e){
            console.log({e});
            return url;
        }
    }

    const getWebToText = async(input: InputWebScrape) => {
        try{
            console.log({input});
            
            const getResults = httpsCallable(functions, WEB_SCRAPE_CALL);
            const response = (await getResults({ requestType: RequestType.webScrape, input: {...input, clientId: activeClientId}}));       
            console.log({response: response?.data});
            return response?.data as NewWebScrape;
        }catch(e){
            console.log({e});
            return {
                url: input?.url,
                referenceId: input?.referenceId,
                htmlTextArray: input?.url,
            } as NewWebScrape;
        }
    }

    return{
        getDocumentToText,
        getSpeechToText,
        getWebToText
    }
}