import { Dispatch, SetStateAction, createContext } from 'react';
import useClient from '../hooks/useClients';
import { Client, SendgridApi } from '../models/models';
import { HttpsCallableResult } from 'firebase/functions';
import { useStripe } from '../hooks/useStripe';
import Stripe from 'stripe';

interface IClientContext {
    clients: Client[];
    activeClient: Client | undefined;
    activeClientId: string;
    setActiveClientId: Dispatch<SetStateAction<string>>;
    saveClient: (client: Client) => Promise<any>;
    inviteMembers: (emails: string[]) => Promise<HttpsCallableResult<unknown> | undefined>;
    verifyClientIdToMember: (clientId: string, secretCode: string) => Promise<false | Client | {
        msg: string;
    }>;
    addModuleToClient: (moduleId: string) => Promise<false | HttpsCallableResult<unknown>>;
    createCustomerPortalSession: () => Promise<{sessionToken: string, url: string}>;
    removeClient: () => void;
    createNewClient: () => Promise<false | HttpsCallableResult<unknown>>;
    fetchSendgridApiKeys: () => Promise<SendgridApi[]>;
    addSendgridApiKey: (apiKey: string, emails: string[]) => Promise<SendgridApi[]>;
    removeSendgridApiKey: (id: string) => Promise<SendgridApi[]>;
    makeDefaultSendgridApiKey: (id: string) => Promise<SendgridApi[]>;
    addSendgridApiKeyEmail: (id: string, emails: string[]) => Promise<SendgridApi[]>;
    removeSendgridApiKeyEmail: (id: string, emails: string[]) => Promise<SendgridApi[]>;
    makeDefaultSendgridApiKeyEmail: (id: string, emails: string[]) => Promise<SendgridApi[]>;
    paymentMethods: Stripe.PaymentMethod[];
    paymenthMethodFetched: boolean;
    removePaymentMethod: (id: string) => void;
    stripe: Stripe;
    removingCreditCard: {
        is: boolean;
        id: string;
    };
    setActiveClient: Dispatch<SetStateAction<Client | undefined>>
}

export const ClientContext = createContext<IClientContext>({} as IClientContext);

export const ClientProvider = ({ children, currentClientId }: { children: any, currentClientId: string }) => {
    const { clients, activeClient, activeClientId, setActiveClientId, saveClient, inviteMembers, verifyClientIdToMember, addModuleToClient,
      createCustomerPortalSession, removeClient, createNewClient, 
      fetchSendgridApiKeys, addSendgridApiKey, removeSendgridApiKey, makeDefaultSendgridApiKey,
        addSendgridApiKeyEmail, removeSendgridApiKeyEmail, makeDefaultSendgridApiKeyEmail,setActiveClient} = useClient(currentClientId)

    const {paymentMethods, paymenthMethodFetched, removePaymentMethod, stripe, removingCreditCard} = useStripe(activeClient?.stripeId);    

    return (
        <ClientContext.Provider value={{
            clients, activeClient, activeClientId, setActiveClientId, saveClient, inviteMembers, verifyClientIdToMember, addModuleToClient,
            createCustomerPortalSession, removeClient, createNewClient, fetchSendgridApiKeys, addSendgridApiKey, removeSendgridApiKey,
            makeDefaultSendgridApiKey, addSendgridApiKeyEmail, removeSendgridApiKeyEmail, makeDefaultSendgridApiKeyEmail,
            paymenthMethodFetched, paymentMethods, removePaymentMethod, stripe, removingCreditCard,setActiveClient
        }}>
            {children}
        </ClientContext.Provider>
    );
};
