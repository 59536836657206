import { Dispatch, SetStateAction, createContext } from 'react';
import useClient from '../hooks/useClients';
import { HttpsCallableResult } from 'firebase/functions';
import useChatCollaborator from '../hooks/useChatCollaborator';
import { ChatCollaboration } from '../models/chat';

interface IChatCollaboratorContext {
    chats: ChatCollaboration[];
    activeChat?: ChatCollaboration;
    setActiveChat: Dispatch<SetStateAction<ChatCollaboration | undefined>>;
    newChatCollaboration: () => Promise<void>;
    send: (content: string, chatId?: string) => Promise<void>;
    sendAiCollaborator: (content: string, chatId?: string) => Promise<void>;
    loading: boolean;
}

export const ChatCollaboratorContext = createContext<IChatCollaboratorContext>({} as IChatCollaboratorContext);

export const ChatCollaboratorProvider = ({ children }: { children: any }) => {
    const { chats, activeChat, setActiveChat, newChatCollaboration, send, sendAiCollaborator, loading } = useChatCollaborator()

    return (
        <ChatCollaboratorContext.Provider value={{
            chats, activeChat, setActiveChat, newChatCollaboration, send, sendAiCollaborator, loading
        }}>
            {children}
        </ChatCollaboratorContext.Provider>
    );
};
