import {useContext, useEffect, useState} from "react";
import { collection, deleteDoc, doc, getDocs, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { FIRESTORE } from "../Firebase/firestore";
import { Company, CompanyBasicInfo, Competitor, SuccessStories } from "../models/company";
import { UserContext } from "../contexts/UserContext";
import { Gallery } from "../models/product";
import { useList } from "react-use";
import { ActiveUser } from "../redux/active-item/active-itemSlice";

const rootCollection = FIRESTORE.COMPANY;

export default function useCompanies(clientId?: string) {
  const firestore = useFirestore(); 
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const {activeUser} = useContext(UserContext);
  const [successStories, {set: setSuccessStories, upsert: upsertSuccessStory, removeAt: removeSuccessStoryAt}] = useList<SuccessStories>([]);
  const [competitors, {set: setCompetitors, upsert: upsertCompetitors, removeAt: removeCompetitorAt}] = useList<Competitor>([]);
  
  const initialBasicInfo: CompanyBasicInfo = {
    industry: '',
    crm: '',
    name: '',
    numberOfEmployees: 0,
    revenue: 0,
    mission: '',
    location: '',
    value: '',
    usp: '',
  };

  const [basicInfo, setBasicInfo] = useState<CompanyBasicInfo>(initialBasicInfo);

  const [gallery,setGallery]=useState<Gallery[]>([]);
  const [primaryLogo,setPrimaryLogo]=useState<string>('');


  const appendCompany = (company: Company) => {
    setCompanies(prev => [...prev.filter(pk => pk.id !== company.id), company])
  }
// useEffect(()=>{
//   if(!activeUser?.id){
//     return
//   }
//   else{
//    fetchCompanyForUser(activeUser)
//   }
// },[activeUser?.id]);

const initialVM=(company:Company)=>{
  const {industry, crm, name, numberOfEmployees,revenue,mission,location,value,usp}=company;
  setBasicInfo({industry, crm, name, numberOfEmployees,revenue,mission,location,value,usp})

}
  useEffect(() => {
    if (!activeUser?.clientIds?.length){
     return;   
    }else{
        setLoading(true);
        const q = query(collection(firestore, `${rootCollection}`), where("clientId", "in", (activeUser.clientIds)));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type == "added") {
              appendCompany({...change.doc.data(), id: change.doc.id} as Company);
            } else if (change.type == "modified") {
              appendCompany({...change.doc.data(), id: change.doc.id} as Company);
            } else if (change.type == "removed") {
              setCompanies(prev => prev.filter(p => p.id !== change.doc.data().id))
            }
          })
          setLoading(false);
        },(err)=>{console.log(err, activeUser)});
        return unsubscribe;    
    }
  }, [activeUser?.clientIds])

  const saveCompany = async (file: Partial<Company>) => {  
    if(!activeUser)
      throw new Error("User not found");
    // let clientId = profile?.clientIds?.[0] ?? profile?.id ?? file?.clientId; 
    // let updateedUser: DbUser = {
    //   ...activeUser,
    // clientIds: [clientId]
    // 
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, file.id!);
    await setDoc(fileRef, file, { merge: true });
    // const fileRef1 = await doc(firestore, FIRESTORE.USER, file.userId);
    // await setDoc(fileRef1, updateedUser, { merge: true });

    await setLoading(false);
  }

  const deleteCompany = async (id: string) => {
    await setLoading(true);
    const fileRef = await doc(firestore, rootCollection, id);
    await deleteDoc(fileRef);
    await setLoading(false);
  }
  const removeSuccessStory = (value: SuccessStories) => removeSuccessStoryAt(successStories.findIndex((v) => v.name == value.name && v.description == value.description));
  const removeCompetitors = (value: Competitor) => removeCompetitorAt(competitors.findIndex((v) => v.name == value.name && v.advantage == value.advantage&& v.disadvantage == value.disadvantage));

  const fetchCompanyForUser = async (user: ActiveUser) => {
    console.log("Fetching company for ", user);
    if(!user?.clientIds?.[0])
      return undefined;
    const q = query(collection(firestore, `${rootCollection}`), where("clientId", "==", (user.clientIds[0])));
    setCompanies([]);
    const querySnapshot = await getDocs(q);
    const companies: Company[] = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      companies.push(doc.data() as Company);
      initialVM(companies?.[0])
    });  
    return companies?.[0];
  }
  return {loading,
     saveCompany, deleteCompany, companies,successStories,setSuccessStories,upsertSuccessStory,
     removeSuccessStory,primaryLogo,gallery,setPrimaryLogo,setGallery ,fetchCompanyForUser,
     competitors,
     setCompetitors,
     upsertCompetitors,
     removeCompetitors,
     basicInfo,
     setBasicInfo,
    };
}