import { useFirestore, useFunctions } from "reactfire";
import { useState, useEffect, useContext } from 'react';
import { Query, collection, doc, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { FIRESTORE } from "../Firebase/firestore";
import { UserContext } from "../contexts/UserContext";
import { ImaginePrompt } from "../models/chat";
import { ClientContext } from "../contexts/ClientContext";

export default function useMidJourney() {
    const firestore = useFirestore();
    const [midjourneyImagines, setMidjourneyImagines] = useState<ImaginePrompt[]>([]);
    const { activeUser } = useContext(UserContext);
    const {activeClientId} = useContext(ClientContext);


const appendMidjourneyImagines=(list:ImaginePrompt)=>{
    setMidjourneyImagines(prev => [...prev.filter(pk => pk.id !== list.id), list])
}

    useEffect(() => {
        if (!activeClientId) {
          return;
        } else {
          const q = query(collection(firestore, `${FIRESTORE.MIDJOURNEY_IMAGINE}`), where("clientId", "==", activeClientId), orderBy("lastUpdated", "desc")) as Query<ImaginePrompt>;
        setMidjourneyImagines([]);
          const unsubscribe = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              if (change.type == "added") {
                appendMidjourneyImagines(change.doc.data() as ImaginePrompt);
              } else if (change.type == "modified") {
                appendMidjourneyImagines(change.doc.data() as ImaginePrompt);
              } else if (change.type == "removed") {
                setMidjourneyImagines(prev => prev.filter(p => p.id !== change.doc.data().id))
              }
            })
          }, (err) => { console.log(err) });
          return unsubscribe;
        }
      }, [activeClientId])
    return {
        midjourneyImagines
    }

}