import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {Gallery, Product} from "../../models/product";
import { ProductBasicInfoWithGallery } from '../active-product/model';

export interface recentActivityState {
    recentProducts: ProductBasicInfoWithGallery[];
}

const initialState: recentActivityState = {
    recentProducts: [],
}

export const recentActivitySlice = createSlice({
    name: "recentActivity",
    initialState,
    reducers: {
        selectRecentProduct: (state, action: PayloadAction<Product>) => {
          const {businessName, description, highlightedFeatures, productName, sku, gallery} = action.payload;
          //remove current product from array if already there
          state.recentProducts = state?.recentProducts?.filter(i => i?.sku != sku);
          if(state.recentProducts?.length === 5){
            const currentProduct = {
                businessName, description, highlightedFeatures, productName, sku, gallery,
            };
            state.recentProducts = [currentProduct, ...state?.recentProducts?.slice(0, 3)];
          }else{
            const currentProduct = {
                businessName, description, highlightedFeatures, productName, sku, gallery,
            };
            state.recentProducts = [currentProduct, ...state?.recentProducts];
          }
        },
        removeRecentProduct: (state, action: PayloadAction<string>) => {
          const sku = action.payload;
          state.recentProducts = state?.recentProducts?.filter(i => i?.sku != sku);
        },

    }
})

// Action creators are generated for each case reducer function
export const {selectRecentProduct, removeRecentProduct, } = recentActivitySlice.actions;
export default recentActivitySlice.reducer;