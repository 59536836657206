import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import slug from "slug";
import { firebaseConfig } from "../Firebase/firebase";
import {getResizedImage } from "../util/util";
import { useFirebaseApp } from "reactfire";

interface KV {
  key: string;
  value: string;
  deleted: boolean;
}

export default function useImageUpload() {
  const firebaseApp=useFirebaseApp();
  const LISTING_BUCKET = firebaseConfig.listingBucket;
  const storage = getStorage(firebaseApp, LISTING_BUCKET);
  const FOLDER_PREFIX = moment().format("YYMMDD");

  const MAX_WIDTH = 320;
  const MAX_HEIGHT = 180;
  const MIME_TYPE = "image/jpeg";
  const QUALITY = 0.4;

  const slugFileName = (name:string) => {
    slug.charmap["."] = "-";
    const s = slug(name);
    slug.reset();
    return s;
  };

  function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number) {
    let width = img.width;
    let height = img.height;
  
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
      }
    }
    return [width, height];
  }

  const uploadCompressedImages = async (file: File) => {
    let downloadUrl = '';
    const getUrl: Promise<string> =  new Promise(async (resolve, reject) => {
      try {
        let name = file.name;
        const extn = name?.split(".")?.pop();
        name = name?.split(".")?.slice(0, -1)?.join(".");
        let finalName = `${new Date().getTime()}-${slugFileName(name)}.${extn}`;
  
        let downloadUrl = '';
  
        const blobUrl = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobUrl;
        img.onerror = function () {
          URL.revokeObjectURL(this.src);
          console.log("Cannot load image");
          reject(new Error("Cannot load image"));
        };
  
        img.onload = async function () {
          URL.revokeObjectURL(img.src);
          const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas?.getContext("2d");
          ctx?.drawImage(img, 0, 0, newWidth, newHeight);
  
          await canvas.toBlob(
            async (blob) => {
              const fileContainer = ref(
                storage,
                `${FOLDER_PREFIX}/${firebaseConfig.defaultRandomUUID}/${finalName}`
              );
              if (!!blob) {
                await uploadBytes(fileContainer, blob);
                downloadUrl = await getDownloadURL(fileContainer);
                resolve(downloadUrl); // Resolve the Promise with the download URL
              }
            },
            MIME_TYPE,
            QUALITY
          );
        };
  
        // Handle errors that might occur during the process
        img.onerror = function () {
          URL.revokeObjectURL(this.src);
          console.log("Cannot load image");
          reject(new Error("Cannot load image"));
        };
  
      } catch (err) {
        reject(err);
      }
    })

    await getUrl.then(url => {
      downloadUrl = url;
    })
    
    return downloadUrl;
  };


  const uploadVariationImages = async (file: File) => {
    let name = file.name;
    const extn = name?.split(".")?.pop();
    name = name?.split(".")?.slice(0, -1)?.join(".");
    let finalName = `${new Date().getTime()}-${slugFileName(name)}.${extn}`;

    const blobUrl = URL.createObjectURL(file);
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(
        storage,
        `${FOLDER_PREFIX}/${
          firebaseConfig.defaultRandomUUID
        }/${finalName}`
      );
      await uploadBytes(fileContainer, blob);            
      const downloadUrl = await getDownloadURL(fileContainer);
      let namePart = downloadUrl.split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";
      const cdnUrl = `${firebaseConfig.storageBaseUrl}/${namePart?.replaceAll("%2F", "/")}`;
      const resizedImage = getResizedImage(cdnUrl);
      // console.log({cdnUrl, resizedImage});
      return downloadUrl;
    } catch (err) {
      throw err;
    }
  };
  

  const uploadBase64toStorage = async (
    blobUrl: string,
    folderPrefix: string,
    name: string,
    fileType: string
  ) => {
    try {
      const blob = await fetch(blobUrl).then((r) => r.blob());
      const fileContainer = ref(storage, `${folderPrefix}/${name}.${fileType}`);
      await uploadBytes(fileContainer, blob);
      const url = await getDownloadURL(fileContainer);
      let namePart = url.replaceAll("%2F", "/").split("/").pop() ?? "";
      if (namePart) namePart = namePart.split("?")?.[0] ?? "";
      const cdnUrl = `${firebaseConfig.storageBaseUrl}/${folderPrefix}/${namePart}`;
      return url;
    } catch (err) {
      // console.log("Error uploading");
      throw err;
    }
  };

  const uploadBlobtoStorage = async (
    blob: Blob,
    folderPrefix: string,
    name: string,
    fileType: string
  ) => {
    try {
      const fileContainer = ref(storage, `${folderPrefix}/${name}.${fileType}`);
      await uploadBytes(fileContainer, blob);
      const url = await getDownloadURL(fileContainer);
      return url;
    } catch (err) {
      throw err;
    }
  };

  return {
    uploadVariationImages,
    uploadCompressedImages,
    uploadBase64toStorage,
    uploadBlobtoStorage,
  };
}
