import { createContext, useEffect, useState } from 'react';
import useLeadsList from '../hooks/useLeadsList';
import { CompanyLeadList } from '../models/lead';

interface ILeadListContext {
	leadList: CompanyLeadList[];
	createLeadList: (
		clientId: string,
		searchText: string,
		area: string,
		leadListName: string,
		workflowId?: string | undefined,
		workflowName?: string | undefined
	) => Promise<CompanyLeadList | undefined>;
	deleteLeadList: (clientId: string, leadList: CompanyLeadList) => Promise<void>;
	getdistance: (coordinates: any) => Promise<number>;
}

export const LeadListContext = createContext<ILeadListContext>({} as ILeadListContext);

export const LeadListProvider = ({ children }: { children: any }) => {
	const { leadList, createLeadList, deleteLeadList, getdistance } = useLeadsList();

	return (
		<LeadListContext.Provider value={{ leadList, createLeadList, deleteLeadList, getdistance }}>
			{children}
		</LeadListContext.Provider>
	);
};
