import { onSnapshot, collection, query, doc, setDoc,getDoc , deleteDoc} from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { FIRESTORE } from '../Firebase/firestore';
import { UserContext } from '../contexts/UserContext';
import { Module,LicensedModule} from '../models/module';
import { Workflow, WorkflowTemplate } from '../models/models';
import { ClientContext } from '../contexts/ClientContext';

const rootCollection = FIRESTORE.MODULE;
const workflowCollection = FIRESTORE.WORKFLOW;
const rootclientCollection = FIRESTORE.CLIENTS;
export default function useModule() {
	const firestore = useFirestore();
	const [modules, setModules] = useState<Module[]>([]);
	const [licensedModules, setLicensedModules] = useState<LicensedModule[]>([]);
	const [workflowTemplates, setWorkflowTemplates] = useState<WorkflowTemplate[]>([]);
	const { isAuthenticated } = useContext(UserContext);
	const [selectedModule, setSelectedModule] = useState<string>(licensedModules?.[0]?.id);
	const {activeClientId} = useContext(ClientContext);

  useEffect(()=> {
	const localSelectedModule = localStorage.getItem("selectedModule");
    if(licensedModules?.length) {
		if(!!localSelectedModule && licensedModules.find(module => module?.id === localSelectedModule)){
			setSelectedModule(localSelectedModule);
		}
		else {
			setSelectedModule(licensedModules?.[0]?.id);
		}
    }
  }, [licensedModules])

  useEffect(() => {
	const localSelectedModule = localStorage.getItem("selectedModule");
	if(localSelectedModule != selectedModule && !!selectedModule){
		console.log("changed");
		
		localStorage.setItem("selectedModule", selectedModule);
	}
  }, [selectedModule])

	const appendModule = (module: Module) => {
		setModules((prev) => [...prev.filter((pk) => pk.id !== module.id), module]);
	};
	const appendWorkflowTemplate = (workflowTemplate: WorkflowTemplate) => {
		setWorkflowTemplates((prev) => [
			...prev.filter((pk) => pk.id !== workflowTemplate.id),
			workflowTemplate,
		]);
	};

	const deleteWorkflow = async (workflowId: string) => {
		const workflowRef = await doc(firestore, workflowCollection, workflowId);
		await setDoc(workflowRef, { id: workflowId, status: 'deleted' }, { merge: true });
	};

  const markWorkflowComplete = async (workflowId: string) => {
		const workflowRef = await doc(firestore, workflowCollection, workflowId);
		await setDoc(workflowRef, { id: workflowId, status: 'completed' }, { merge: true });
	};

	useEffect(() => {
		if (!isAuthenticated) {
			return;
		} else {
			const q = query(collection(firestore, `${rootCollection}`));
			setModules([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendModule({ ...change.doc.data(), id: change.doc.id } as Module);
						} else if (change.type == 'modified') {
							appendModule({ ...change.doc.data(), id: change.doc.id } as Module);
						} else if (change.type == 'removed') {
							setModules((prev) => prev.filter((p) => p.id !== change.doc.data().id));
						}
					});
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated]);
	const getLicensedModules = async () => {
		const docRef = doc(firestore, `${rootclientCollection}/${activeClientId}`);
		const docSnapshot = await getDoc(docRef);
		const tempLicensedModules = docSnapshot.data()?.licensedModules;
		return tempLicensedModules;
	  };
	  useEffect(() => {
		if (!isAuthenticated || !activeClientId) {
		  return;
		} else {
		  const getLicensedModulesAsync = async () => {
			const tempLicensedModules = await getLicensedModules();
			const simplifiedModules = tempLicensedModules.map((module:string) => ({
				id: module,
			  }));
			setLicensedModules(simplifiedModules);
		  };  
		  getLicensedModulesAsync();
		}
	  }, [isAuthenticated, activeClientId]);
	useEffect(() => {
		if (!isAuthenticated) {
			return;
		} else {
			const q = query(
				collection(firestore, `${rootCollection}/${selectedModule}/workflow-template`),
			);
			// setModules([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendWorkflowTemplate({
								...change.doc.data(),
								id: change.doc.id,
							} as WorkflowTemplate);
						} else if (change.type == 'modified') {
							appendWorkflowTemplate({
								...change.doc.data(),
								id: change.doc.id,
							} as WorkflowTemplate);
						} else if (change.type == 'removed') {
							setWorkflowTemplates((prev) =>
								prev.filter((p) => p.id !== change.doc.data().id),
							);
						}
					});
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [isAuthenticated, selectedModule]);
	const createWorkflow = async (file: Workflow) => {
		// const updatedFile = { ...file, status: 'deleted' };
		const workflowData = { ...file, status: 'active' };
		const fileRef = await doc(firestore, workflowCollection, file.id);
		await setDoc(fileRef, file, { merge: true });
	};
	return {
		modules,
		licensedModules,
		workflowTemplates,
		createWorkflow,
		deleteWorkflow,
		selectedModule,
		setSelectedModule,
    markWorkflowComplete
	};
}
