import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, authMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';

const headers: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.signUp.path, element: null },
	{ path: authMenu.page404.path, element: null },
	{
		path: dashboardPagesMenu.workflows.path,
		element: <DashboardHeader />,
	},
	{
		path: "mission-builder/:id",
		element: <DashboardHeader />,
	},
	{
		path: "/workflow/:id",
		element: <DashboardHeader />,
	},
	{
		path: dashboardPagesMenu.datalake.path,
		element: <DashboardHeader />,
	},
	{
		path: dashboardPagesMenu.billing.path,
		element: <DashboardHeader />,
	},
	{
		path: dashboardPagesMenu.payment.path,
		element: <DashboardHeader />,
	},
	{
		path: dashboardPagesMenu.workspace.path,
		element: <DashboardHeader />,
	},
	{
		path: '/',
		element: <DashboardHeader />,
	},
	{
		path: `*`,
		element: null,
	},
];

export default headers;
