import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { CommunicationPreferences, Profile, SalesProfessionalInfo } from '../models/profile';
import { useFirebaseApp, useFirestore, useFirestoreCollectionData } from 'reactfire';
import { FIRESTORE } from '../Firebase/firestore';
import { useEffect, useState } from 'react';
import {
	setSalesProfessionalInfo,
	setCommunicationPreferences,
	setGoalsAndObjectives,
} from '../redux/active-item/active-itemSlice';
// import { useDispatch } from "react-redux";
import { getAuth } from 'firebase/auth';

const rootCollection = FIRESTORE.USER;

export default function useProfile() {
	const firestore = useFirestore();
	const app = useFirebaseApp();
	const auth = getAuth(app);
	// const dispatch = useDispatch();
	const [profile, setProfile] = useState<Profile>({
		id: '',
		name: '',
		jobTitle: '',
		email: '',
		phoneNumber: '',
		linkedinId: '',
		UPS: '',
		clientIds: [],
		cycleTime: '',
		leads: '',
		winRate: '',
		revenue: '',
		profitMargin: '',
		churnRate: '',
		upSell: '',
		toneAndStyle: '',
		socialMediaHandle: [],
		photoUrl: '',
	});
	const [salesProfessionalInfo, setSalesProfessionalInfo] = useState<SalesProfessionalInfo>({
		name: '',
		email: '',
		jobTitle: '',
		linkedinId: '',
		phoneNumber: '',
		photoUrl: '',
		UPS: '',
	});
	const [communicationPreferences, setCommunicationPreferences] =
		useState<CommunicationPreferences>({
			email: '',
			phoneNumber: '',
			toneAndStyle: '',
			socialMediaHandle: [],
		});
	const saveProfile = async (profile: Partial<Profile>) => {
		const profData = await {
			...profile,
			id: profile?.id ?? auth.currentUser?.uid ?? '',
			toneAndStyle: profile?.toneAndStyle ?? "",
			socialMediaHandle: profile?.socialMediaHandle ?? [],

		};
		const profileRef = await doc(firestore, rootCollection, profData?.id);
		console.log('submiting', profData);
		await setDoc(profileRef, profData, { merge: true });
		console.log('Profile saved!');
		return;
	};


	useEffect(() => {
		if (auth?.currentUser?.uid) {
      console.log("fetching profile for", auth?.currentUser)
      const unsubscribe = onSnapshot(
        doc(firestore, `${rootCollection}/${auth?.currentUser?.uid}`),
        (snapshot) => {
          const profileRecord = snapshot.data() as Profile;
          setProfile(profileRecord as Profile);
        },
        (err) => {
          console.log('err on fetching activeuser', err, auth?.currentUser?.uid);
        },
      );
      return unsubscribe;
      }
	}, [auth?.currentUser?.uid]);

	useEffect(() => {
		if (profile) {
			setSalesProfessionalInfo({
				name: profile?.name,
				email: profile?.email,
				phoneNumber: profile?.phoneNumber,
				jobTitle: profile?.jobTitle,
				linkedinId: profile?.linkedinId,
				UPS: profile?.UPS,
			});
			// dispatch(setGoalsAndObjectives({
			//     cycleTime: profile?.cycleTime ?? "",
			//     leads: profile?.leads,
			//     winRate: profile?.winRate ?? "",
			//     revenue: profile?.revenue ?? "",
			//     profitMargin: profile?.profitMargin ?? "",
			//     churnRate: profile?.churnRate ?? "",
			//     upSell: profile?.upSell ?? ""
			// }));
			setCommunicationPreferences({
				email: profile?.email,
				phoneNumber: profile?.phoneNumber,
				toneAndStyle: profile?.toneAndStyle,
				socialMediaHandle: profile?.socialMediaHandle,
			});
		}
	}, [profile]);

	return { saveProfile, profile, salesProfessionalInfo, communicationPreferences };
}
