import { Dispatch, SetStateAction, createContext, useState } from 'react';
import useModule from '../hooks/useModule';
import { Workflow, WorkflowCampaign, WorkflowTemplate } from '../models/models';
import { LicensedModule, Module } from '../models/module';
import useWorkflows from '../hooks/useWorkflows';

interface IModuleContext {
	modules: Module[];
	licensedModules:LicensedModule[];
	workflowTemplates: WorkflowTemplate[];
	workflows: Workflow[];
	deleteWorkflow: (a: string) => void;
  markWorkflowComplete: (wfId: string) => Promise<void>;
	createWorkflow: (file: Workflow) => Promise<void>;
	searchText: string;
	setSearchText: (a: string) => void;
	selectedModule: string;
	setSelectedModule: Dispatch<SetStateAction<string>>;
	campaigns: WorkflowCampaign[];
}

export const ModuleContext = createContext<IModuleContext>({} as IModuleContext);

export const ModuleProvider = ({ children }: { children: any }) => {
	const {
		modules,
		licensedModules,
		workflowTemplates,
		createWorkflow,
		deleteWorkflow,
		selectedModule,
		setSelectedModule,
    markWorkflowComplete
	} = useModule();
	const { workflows, campaigns } = useWorkflows();
	const [searchText, setSearchText] = useState('');

	const searchedModules = modules?.filter((i) =>
		`
	${i?.name} 
	${i?.text}
	`
			?.toLowerCase()
			?.includes(searchText?.toLowerCase()),
	);

	const searchedWorkflowTemplates = workflowTemplates?.filter((i) =>
		`
	${i?.name} ${i?.text} ${i.type?.name}
	${i?.created}  
	${i.type?.text} ${i.type?.icon}  
	${i?.subType?.name} ${i?.subType?.text}  
	${i?.steps?.map((j) => j?.name)}
	${i?.steps?.map((j) => j?.text)} 
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.name))} 
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.datalakeType))}  
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.inputType))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.label))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.objectFields))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.className))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.options))}
	${i?.steps?.map((j) => j?.fields?.map((k) => k?.required))}
	${i?.lastUpdated}
	`
			?.toLowerCase()
			?.includes(searchText?.toLowerCase()),
	);

	const searchedWorkflows = workflows?.filter((i) => {
		const searchString = `${i?.name ?? ''} ${i?.data ?? ''} ${i?.text ?? ''} ${
			i?.clientId ?? ''
		}`;
		const lowerCaseSearchString = searchString.toLowerCase();
		const lowerCaseSearchText = searchText?.toLowerCase();

		return (
			lowerCaseSearchString.includes(lowerCaseSearchText) && i?.moduleId === selectedModule
		);
	});
	const selectedWorkflowTemplates = workflowTemplates?.filter(
		(i) => i?.moduleId == selectedModule,
	);
	return (
		<ModuleContext.Provider
			value={{
				modules,
				licensedModules,
				workflowTemplates: selectedWorkflowTemplates,
				workflows: searchedWorkflows,
				createWorkflow,
				searchText,
				setSearchText,
				deleteWorkflow,
				selectedModule,
				setSelectedModule,
				campaigns,
        markWorkflowComplete
			}}>
			{children}
		</ModuleContext.Provider>
	);
};
