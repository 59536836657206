import { useFirestore, useFunctions } from 'reactfire';
import { useState, useEffect, useContext } from 'react';
import { Query, collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { FIRESTORE } from '../Firebase/firestore';
import { UserContext } from '../contexts/UserContext';
import { Client, SendgridApi } from '../models/models';
import { httpsCallable } from 'firebase/functions';
enum ClientsRequestType {
    inviteMembers = "inviteMembers",
    addMember = "addMember",
    createCustomerPortalSession = "createCustomerPortalSession",
    createNewClient = "createNewClient",
    buyModule = "buyModule",
    addSendgridApiKey = "addSendgridApiKey",
    removeSendgridApiKey = "removeSendgridApiKey",
    fetchSendgridApiKeys = "fetchSendgridApiKeys",
    makeDefaultSendgridApiKey = "makeDefaultSendgridApiKey",
	addSendgridApiKeyEmail = "addSendgridApiKeyEmail",
    removeSendgridApiKeyEmail = "removeSendgridApiKeyEmail",
    makeDefaultSendgridApiKeyEmail = "makeDefaultSendgridApiKeyEmail",
}
const CLIENT_CALL = 'clients-clientCall';

export default function useClient(currentId: string) {
	const functions = useFunctions();
	const firestore = useFirestore();
	const [clients, setClients] = useState<Client[]>([]);
	const [activeClient, setActiveClient] = useState<Client>();
	const [activeClientId, setActiveClientId] = useState<string>('');
	const { activeUser } = useContext(UserContext);

	const appendClients = (client: Client) => {
		setClients((prev) => [...prev.filter((pk) => pk.id !== client.id), client]);
		if (
			!!activeClientId &&
			activeClient?.id == activeClientId &&
			activeClient?.id == client?.id
		) {
			setActiveClient(client);
		}
	};
	const removeClient = () => {
		setActiveClient(undefined);
		localStorage.setItem('activeClientId', '');
		localStorage.removeItem("selectedModule");
		setActiveClientId('');
		setClients([]);
	};
	useEffect(() => {
		if (!activeUser) {
			return;
		} else if (!!currentId && clients?.length > 0) {
			// console.log('in having client id condition', currentId);
			let current = clients?.find((client) => client.id == currentId);
			let activeCurrent = clients?.find(client => client.id == activeClientId);
			if (!!current && current?.id !== activeClientId && !activeCurrent?.id) {
				localStorage.setItem('activeClientId', current?.id);
				setActiveClient(current);
				setActiveClientId(current?.id);
			} else if (activeClientId) {
        //leave as is
				localStorage.setItem('activeClientId', activeClientId);
      } else {
				let firstClient = clients?.[0];
				localStorage.setItem('activeClientId', firstClient?.id);
				setActiveClient(firstClient);
				setActiveClientId(firstClient?.id);
			}
		} else if (!currentId && !activeClientId && clients?.length > 0) {
			// console.log('in not having client id condition', currentId, ' --', activeClientId);

			let firstClient = clients?.[0];
			localStorage.setItem('activeClientId', firstClient?.id);
			setActiveClient(firstClient);
			setActiveClientId(firstClient?.id);
		}
	}, [currentId, clients]);

	useEffect(() => {
		if (!activeClientId || activeClientId == activeClient?.id) {
			return;
		} else {
			let current = clients?.find((client) => client.id == activeClientId);
			if (!!current) {
				localStorage.setItem('activeClientId', current?.id);
				setActiveClient(current);
			}
		}
	}, [activeClientId,clients]);

	useEffect(() => {
		if (!activeUser?.clientIds?.length) {
			return;
		} else {
			const q = query(
				collection(firestore, FIRESTORE.CLIENTS),
				where('id', 'in', activeUser?.clientIds),
			) as Query<Client>;
			setClients([]);
			const unsubscribe = onSnapshot(
				q,
				(snapshot) => {
					snapshot.docChanges().forEach((change) => {
						if (change.type == 'added') {
							appendClients({ ...change.doc.data(), id: change.doc.id } as Client);
						} else if (change.type == 'modified') {
							appendClients({ ...change.doc.data(), id: change.doc.id } as Client);
						} else if (change.type == 'removed') {
							setClients((prev) => prev.filter((p) => p.id !== change.doc.data().id));
						}
					});
				},
				(err) => {
					console.log(err);
				},
			);
			return unsubscribe;
		}
	}, [activeUser?.clientIds?.length]);

	const saveClient = async (client: Partial<Client>) => {
		if (!client || !client?.id) return false;

		try {
			const clientRef = await doc(firestore, FIRESTORE.CLIENTS, client.id!);
			await setDoc(clientRef, client, { merge: true });
			if (client.id) {
				setActiveClientId(client?.id);
			}
			// console.log('client saved');
			return true;
		} catch (err: any) {
			console.error('Error adding/updating client', err);
			// setIsBusy(false);
			return err.toString();
		}
	};
	const addModuleToClient = async (moduleId: string) => {
		if (!activeClientId || !activeUser?.id || !activeClient?.billingClientId) return false;
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.buyModule,
				clientId: activeClientId,
				billingClientId: activeClient?.billingClientId,
				input: { moduleId: moduleId },
			});
			return response;
		} catch (err: any) {
			console.error('Error adding module to client', err.toString());
			return false;
		}
	};
	const createNewClient = async () => {
		if (!activeClientId || !activeUser?.id || !activeClient?.billingClientId) return false;
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.createNewClient,
				clientId: activeClientId,
				billingClientId: activeClient?.billingClientId,
				input: { currentClientsCount: clients?.length },
			});
			return response;
		} catch (err: any) {
			console.error('Error adding module to client', err.toString());
			return false;
		}
	};

	const inviteMembers = async (emails: string[]) => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.inviteMembers,
				clientId: activeClientId,
				emails: emails,
				input: { sender: activeUser?.id },
			});
			// let client = clients?.find(i => i?.id == activeClientId);
			// setActiveClient(client);
			return response;
		} catch (err) {
			console.log('errror on inviting member', err);
		}
	};
	const verifyClientIdToMember = async (clientId: string, secretCode: string) => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.addMember,
				clientId: clientId,
				input: {
					userId: activeUser?.id,
					secretCode: secretCode,
					userEmail: activeUser?.email,
				},
			});
			return response?.data as Client | { msg: string };
		} catch (err) {
			console.log('error on verifying client id to new member', err);
			return false;
		}
	};

	const createCustomerPortalSession = async () => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.createCustomerPortalSession,
				clientId: activeClientId,
			});
			return response?.data as { sessionToken: string; url: string };
		} catch (e) {
			return {} as { sessionToken: string; url: string };
		}
	};

	const fetchSendgridApiKeys = async () => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.fetchSendgridApiKeys,
				clientId: activeClientId,
			});
			return response?.data as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const addSendgridApiKey = async (apiKey: string, emails: string[]) => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.addSendgridApiKey,
				apiKey,
				emails,
				clientId: activeClientId,
			});
			return response?.data as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const removeSendgridApiKey = async (id: string) => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.removeSendgridApiKey,
				id,
				clientId: activeClientId,
			});
			return response?.data as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

    const makeDefaultSendgridApiKey = async (id: string) => {
        try {
            const clientCall = httpsCallable(functions, CLIENT_CALL);
            const response = await clientCall({ requestType: ClientsRequestType.makeDefaultSendgridApiKey, id, clientId: activeClientId});
            return response?.data as SendgridApi[];    
        }catch(e){
            return [];
        }
    }

	const addSendgridApiKeyEmail = async (id: string, emails: string[]) => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.addSendgridApiKeyEmail,
				id,
				emails,
				clientId: activeClientId,
			});
			return response?.data as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

	const removeSendgridApiKeyEmail = async (id: string, emails: string[]) => {
		try {
			const clientCall = httpsCallable(functions, CLIENT_CALL);
			const response = await clientCall({
				requestType: ClientsRequestType.removeSendgridApiKeyEmail,
				id,
				emails,
				clientId: activeClientId,
			});
			return response?.data as SendgridApi[];
		} catch (e) {
			return [];
		}
	};

    const makeDefaultSendgridApiKeyEmail = async (id: string, emails: string[]) => {
        try {
            const clientCall = httpsCallable(functions, CLIENT_CALL);
            const response = await clientCall({ requestType: ClientsRequestType.makeDefaultSendgridApiKeyEmail, id, emails, clientId: activeClientId});
            return response?.data as SendgridApi[];    
        }catch(e){
            return [];
        }
    }

    return {
        clients, activeClient, activeClientId, setActiveClientId, saveClient, inviteMembers, verifyClientIdToMember, addModuleToClient,
        createCustomerPortalSession, removeClient, createNewClient,
        fetchSendgridApiKeys, addSendgridApiKey, removeSendgridApiKey, makeDefaultSendgridApiKey,
		addSendgridApiKeyEmail, removeSendgridApiKeyEmail, makeDefaultSendgridApiKeyEmail,setActiveClient
    }

}
