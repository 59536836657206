import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';
import { getAuth } from 'firebase/auth';
import { useFirebaseApp, useFirestore, useFirestoreDocData } from 'reactfire';
import { getFunctions } from 'firebase/functions';
import { FIRESTORE } from '../Firebase/firestore';
import { DocumentReference } from 'firebase/firestore';
import { DbUser } from '../Firebase/models';
import { CommunicationPreferences, SalesProfessionalInfo } from '../models/profile';
import {doc} from 'firebase/firestore';

export interface IAuthContextProps {
	user?: DbUser & SalesProfessionalInfo & CommunicationPreferences;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	// const firebaseApp = useFirebaseApp();
	// const auth = getAuth(firebaseApp);
	// auth.useDeviceLanguage();
	// const functions = getFunctions(firebaseApp);

	// const firestore = useFirestore();
	// const userDocRef = doc(firestore, `${FIRESTORE.USER}/${auth?.currentUser?.uid}`) as DocumentReference<DbUser & SalesProfessionalInfo & CommunicationPreferences>;
	// const { data, status } = useFirestoreDocData(userDocRef, { idField: "id" });

	return <AuthContext.Provider value={{user: undefined}}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
