import React, { useContext, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import dayjs from 'dayjs';
import Icon from '../../../components/icon/Icon';
import { ClientContext } from '../../../contexts/ClientContext';
import loadJson from '../../../Lottie/93486-load.json';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Lottie from 'react-lottie';
import { useLocation, useNavigate } from 'react-router-dom';
import Stripe from 'stripe';
import Spinner from '../../../components/bootstrap/Spinner';
import { StripeCardInput } from './stripe/StripeCardInput';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';

const AcceptPaymentForm = () => {
	const { activeClient, stripe } = useContext(ClientContext);
	const location = useLocation();
	const navigate = useNavigate();
	const pricingPlanId = location?.search
		?.split('?')?.[1]
		?.split('&')?.[0]
		?.split('=')?.[1] as string;

	const PaymentForm = () => {
		return (
			<PageWrapper isProtected={false} title={'Accept payment'} className={'bg-light'}>
				<Page className='p-0'>
					<div className='row h-100 align-items-center justify-content-center'>
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-3d-dark' data-tour='login-page'>
								<CardBody>
									<div className='text-center h1 fw-bold mt-5'>
										Payment Method
									</div>
									<p className='text-center text-muted mb-5'>
										Lorem, ipsum dolor sit amet consectetur adipisicing elit.
										Fuga obcaecati, dolorem possimus quia debitis inventore
										earum maiores
									</p>
									{activeClient && (
										<StripeCardInput
											stripe={stripe}
											activeClient={activeClient}
											returnUrl={`${process.env.REACT_APP_BASE_URL}/billing?plan=${pricingPlanId}`}
										/>
									)}
								</CardBody>
							</Card>
						</div>
					</div>
				</Page>
			</PageWrapper>
		);
	};

	if (!activeClient || !pricingPlanId) {
		return (
			<div
				className='d-flex justify-content-center align-items-center overflow-hidden'
				style={{ height: 400 }}>
				<Lottie
					height={200}
					width={200}
					options={{ loop: true, autoplay: true, animationData: loadJson }}
				/>
			</div>
		);
	} else return <PaymentForm />;
};

export default AcceptPaymentForm;
