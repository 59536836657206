export const dashboardPagesMenu = {
	workflows: {
		id: 'workflows',
		text: 'Workflows',
		path: '/workflows',
		icon: 'Campaign',
		subMenu: null,
	},
	datalake: {
		id: 'datalake',
		text: 'Knowledge Base',
		path: '/kb',
		icon: 'Analytics',
		subMenu: null,
	},
	billing: {
		id: 'billing',
		text: 'Billing',
		path: '/billing',
		icon: 'MonetizationOn',
		subMenu: null,
	},
	payment: {
		id: 'payment',
		text: 'Payment',
		path: '/payment',
		icon: 'MonetizationOn',
		subMenu: null,
	},
	workspace: {
		id: 'workspace',
		text: 'Workspace',
		path: '/workspace',
		icon: 'Apartment',
		subMenu: null,
	},
	company: {
		id: 'company',
		text: 'Company',
		path: '/company',
		icon: 'Apartment',
		subMenu: null,
	},
	lead: {
		id: 'leads',
		text: 'Leads',
		path: '/leads',
		icon: 'Groups',
		subMenu: null,
	},
	imagegen: {
		id: 'genimageJobs',
		text: 'Media Library',
		path: '/media',
		icon: 'RecentActors',
		subMenu: null,
	},
};

export const childPages = {};

export const authMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/signup',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '/404',
		icon: 'ReportGmailerrorred',
	},
};

