import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import cartReducer from "../redux/cart/cartSlice";
import activePromptReducer from '../redux/active-prompt/activePromptSlice';
import currentStepReducer from '../redux/active-product/currentStepSlice';
import campaignReducer from '../redux/campaign/campaignStepSlice';
import companyReducer from '../redux/company/companyStepSlice';
import accountReducer from '../redux/account/accountStepSlice';
import recentActivityReducer from '../redux/recent-activity/recentActivitySlice';
import activeItemReducer from "../redux/active-item/active-itemSlice";
// import fileMetaReducer from '../../components/react-image-editor/redux/fileMeta';
// import stageDataReducer from '../../components/react-image-editor/redux/currentStageData';
// import stageDataListReducer from '../../components/react-image-editor/redux/StageDataList';
// import imageAssetListReducer from '../../components/react-image-editor/redux/imageAssetList';
import imageEditorReducer from '../redux/image-editor/imageEditorSlice';

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    activePrompt: activePromptReducer,
    step: currentStepReducer,
    campaignStep: campaignReducer,
    companyStep: companyReducer,
    accountStep: accountReducer,
    recentActivity: recentActivityReducer,
    activeItem: activeItemReducer,
    // fileMeta: fileMetaReducer,
    // currentStageData: stageDataReducer,
    // stageDataList: stageDataListReducer,
    // imageAssetList: imageAssetListReducer,
    imageEditor: imageEditorReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch