import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {CartItem} from "./model";

export interface CartState {
    carts: CartItem[];
}

const initialState: CartState = {
    carts: []
}

export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<CartItem>) => {
            state.carts.push(action.payload);
        },
        removeFromCart: (state, action: PayloadAction<CartItem>) => {
            state.carts = state.carts.filter(item => item.id != action.payload.id);
        },
        removeOneFromCart: (state, action: PayloadAction<CartItem>) => {
            const matchingItem = state.carts.find(item => item.id == action.payload.id);
            if(matchingItem) {
                if(matchingItem.qnty > 1)
                    matchingItem.qnty--;
                else if(matchingItem.qnty == 1)
                    state.carts = state.carts.filter(item => item.id != action.payload.id);
            }
        }
    }
})

// Action creators are generated for each case reducer function
export const {addToCart, removeFromCart, removeOneFromCart} = cartSlice.actions;

export default cartSlice.reducer;