import React, { lazy } from 'react';
import { childPages, dashboardPagesMenu, authMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';
import DataLake from '../pages/presentation/datalake/index';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	GENIMAGEJOBS: lazy(() => import('../pages/presentation/genimage/ListPage')),
	WORKFLOWLIST: lazy(() => import('../pages/presentation/workflow/WorkflowList')),
	WORKSPACE: lazy(() => import('../pages/presentation/settings/WorkspaceSettings')),
	BILLING: lazy(() => import('../pages/presentation/billing/BillingDashboard')),
	PAYMENT: lazy(() => import('../pages/presentation/billing/PaymentDashboard')),
	COMPANY: lazy(() => import('../pages/presentation/company/manageCompany')),
	LEADLIST: lazy(() => import('../pages/presentation/lead/LeadList')),
	DATALAKE: lazy(() => import('../pages/presentation/datalake/index')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		// path: dashboardPagesMenu.dashboard.path,
		path: '/',
		element: <LANDING.WORKFLOWLIST />,
	},
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authMenu.login.path,
		element: <Login />,
	},
	{
		path: authMenu.signUp.path,
		element: <Login isSignUp />,
	},
	{
		path: dashboardPagesMenu.imagegen.path,
		element: <LANDING.GENIMAGEJOBS />,
	},
	{
		path: dashboardPagesMenu.lead.path,
		element: <LANDING.LEADLIST />,
	},
	{
		path: dashboardPagesMenu.workflows.path,
		element: <LANDING.WORKFLOWLIST />,
	},
	{
		path: dashboardPagesMenu.workspace.path,
		element: <LANDING.WORKSPACE />,
	},
	{
		path: dashboardPagesMenu.billing.path,
		element: <LANDING.BILLING />,
	},
	{
		path: dashboardPagesMenu.payment.path,
		element: <LANDING.PAYMENT />,
	},
	{
		path: dashboardPagesMenu.company.path,
		element: <LANDING.COMPANY />,
	},
	{
		path: dashboardPagesMenu.datalake.path,
		element: <DataLake />,
	},
];
const contents = [...presentation];

export default contents;
