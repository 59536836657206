import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import {
	PROMPT_TYPE,
	Workflow,
	WorkflowCampaign,
	WorkflowComposedEmail,
	WorkflowOutput,
	WorkflowStepTemplate,
	WorkflowTemplate,
} from '../models/models';
import useWorkflow from '../hooks/useWorkflow';
import { GooglePlace } from '../models/product';
import { LeadSourceAndContact } from '../models/campaign';
import { CompanyLeadList } from '../models/lead';
import useCollaboration from '../hooks/useCollaboration';
import { CollaborationThread, Collaborator } from '../models/collaboration';

interface IWorkflowContext {
	loading: boolean;
	saveWorkflow: (file: any) => Promise<void>;
	createWorkflow: (file: Workflow) => Promise<void>;
	workflow: Workflow | undefined;
	workflowId: string;
	workflowTemplate: WorkflowTemplate | undefined;
	setWorkflowStep: Dispatch<SetStateAction<WorkflowStepTemplate | undefined>>;
	workflowStep?: WorkflowStepTemplate;
	workflowOutput?: WorkflowOutput[];
	allWorkflowOutput: WorkflowOutput[];
	generate: (
		promptType: PROMPT_TYPE,
		input?: Record<string, string> | undefined,
		chatId?: string | undefined,
		combination?: any[],
	) => Promise<void>;
	draftWorkflowLeads: GooglePlace[];
	setWorkflowTemplate: Dispatch<SetStateAction<WorkflowTemplate | undefined>>;
	setDraftSelectedLeadEmails: Dispatch<SetStateAction<LeadSourceAndContact[]>>;
	draftSelectedLeadEmails: LeadSourceAndContact[];
	saveWorkflowLeads: (workflowId: string, files: GooglePlace[]) => Promise<void>;
	getLeadsForListId: (listId: string) => Promise<GooglePlace[]>;
	selectedLeadEmails: LeadSourceAndContact[];
	workflowLeads: GooglePlace[];
	allEmails: LeadSourceAndContact[];
	eventWorkflowId: string[];
	applySuggestionToEmailCopy: (
		copyId: string,
		chatId: string,
		suggestion: string,
	) => Promise<void>;
	workflowEmailCopies: WorkflowComposedEmail[];
	saveWorkflowEmailCopies: (
		workflowId: string,
		files: Partial<WorkflowComposedEmail>[],
	) => Promise<void>;
	sendWorkflowEmails: (emails: WorkflowComposedEmail[]) => Promise<string>;
	generateWorkflowEmailCopies: (
		leads: LeadSourceAndContact[],
		attachments: string[],
	) => Promise<void>;
	saveWorkflowOutput: (output: WorkflowOutput) => void;
	saveCsvLeadsRequest: (leads: GooglePlace[]) => Promise<void>;
	saveCustomLeads: (leads: GooglePlace[], list: CompanyLeadList) => Promise<void>;
	saveWorkflowEmailCopy: (
		workflowId: string,
		copyId: string,
		copy: Partial<WorkflowComposedEmail>,
	) => Promise<void>;
	collaborationThreads: CollaborationThread[];
	collaborators: Collaborator[];
	addCollaborationQuestion: (
		id: string,
		suggestion: string,
		workflowId: string,
		clientId: string,
		stepName: string,
		dataFragmentId?: string,
	) => Promise<string | true | undefined>;
	addCollaborators: (
		collaborators: Collaborator[],
		workflowId: string,
		clientId: string,
		stepName: string,
	) => Promise<string | true | undefined>;
	removeCollaborators: (
		collaborators: Collaborator[],
		workflowId: string,
		clientId: string,
	) => Promise<string | true | undefined>;
	createNewWorkflowStep: (a: any) => void;
	deleteWorkflowStep: (a: any) => void;
	uploadVideoOutput: (
		blob: Blob,
		thumbnail: Blob,
		workflowId: string,
		stepId: string,
	) => Promise<string | undefined>;
	deleteWorkflowOutput: (output: WorkflowOutput) => void;
	cropOutputVideo: (
		videoUrl: string,
		cropdata: {
			width: number;
			height: number;
			x: number;
			y: number;
		},
	) => Promise<unknown>;
	exportWorkflow: () => void;
	saveWorkflowCampaign: (campaign: WorkflowCampaign) => Promise<void>;
	setEventWorkflowId: Dispatch<SetStateAction<string[]>>;
}

export const WorkflowContext = createContext<IWorkflowContext>({} as IWorkflowContext);

export const WorkflowProvider = ({
	children,
	inputWorkflowId,
}: {
	children: any;
	inputWorkflowId: string;
}) => {
	const {
		loading,
		saveWorkflow,
		createWorkflow,
		workflow,
		workflowId,
		setWorkflowId,
		setWorkflowStep,
		workflowStep,
		workflowTemplate,
		workflowOutput,
		allWorkflowOutput,
		eventWorkflowId,
		generate,
		draftWorkflowLeads,
		setWorkflowTemplate,
		draftSelectedLeadEmails,
		setDraftSelectedLeadEmails,
		saveWorkflowLeads,
		getLeadsForListId,
		selectedLeadEmails,
		workflowLeads,
		allEmails,
		applySuggestionToEmailCopy,
		workflowEmailCopies,
		saveWorkflowEmailCopies,
		saveWorkflowEmailCopy,
		sendWorkflowEmails,
		generateWorkflowEmailCopies,
		saveWorkflowOutput,
		saveCsvLeadsRequest,
		saveCustomLeads,
		createNewWorkflowStep,
		deleteWorkflowStep,
		uploadVideoOutput,
		deleteWorkflowOutput,
		cropOutputVideo,
		exportWorkflow,
		saveWorkflowCampaign,
		setEventWorkflowId,
	} = useWorkflow(inputWorkflowId);

	const {
		collaborationThreads,
		collaborators,
		addCollaborationQuestion,
		addCollaborators,
		removeCollaborators,
	} = useCollaboration(workflowId);

	useEffect(() => {
		setWorkflowId(inputWorkflowId);
	}, [inputWorkflowId]);

	useEffect(() => {
		setWorkflowId(inputWorkflowId);
	}, [inputWorkflowId]);

	return (
		<WorkflowContext.Provider
			value={{
				loading,
				saveWorkflow,
				createWorkflow,
				workflow,
				workflowId,
				workflowTemplate,
				setWorkflowTemplate,
				workflowStep,
				setWorkflowStep,
				workflowOutput,
				allWorkflowOutput,
				eventWorkflowId,
				saveWorkflowEmailCopy,
				draftWorkflowLeads,
				generate,
				draftSelectedLeadEmails,
				setDraftSelectedLeadEmails,
				saveWorkflowLeads,
				getLeadsForListId,
				selectedLeadEmails,
				workflowLeads,
				allEmails,
				applySuggestionToEmailCopy,
				workflowEmailCopies,
				saveWorkflowEmailCopies,
				sendWorkflowEmails,
				generateWorkflowEmailCopies,
				saveWorkflowOutput,
				saveCsvLeadsRequest,
				saveCustomLeads,
				collaborationThreads,
				collaborators,
				addCollaborationQuestion,
				addCollaborators,
				removeCollaborators,
				createNewWorkflowStep,
				deleteWorkflowStep,
				uploadVideoOutput,
				deleteWorkflowOutput,
				cropOutputVideo,
				exportWorkflow,
				saveWorkflowCampaign,
				setEventWorkflowId,
			}}>
			{children}
		</WorkflowContext.Provider>
	);
};
