import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	sendEmailVerification,
} from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';
import { Form } from 'react-bootstrap';
import useUpdateProfile from '../../../hooks/useUpdateProfile';
import ReactGA from 'react-ga4';
import { dashboardPagesMenu, authMenu } from '../../../menu';
import { UserContext } from '../../../contexts/UserContext';
import { CompanyContext } from '../../../contexts/CompanyContext';
import Icon from '../../../components/icon/Icon';
import Swal from 'sweetalert2';
import { ClientContext } from '../../../contexts/ClientContext';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({isSignUp}) => {
	const app = useFirebaseApp();
	const auth = getAuth(app);
  const navigate = useNavigate();
	let [searchParams] = useSearchParams();
  const planId = searchParams.get("plan");

	const [err, setError] = useState("");
	const { updateProfile } = useUpdateProfile();
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [forgotPassEmailSent, setForgotPassEmailSent] = useState(false);
	const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
	const [toggleReConfirmPassword, setToggleReConfirmPassword] = useState(false);
	const [togglePassword, setTogglePassword] = useState(false);

	const [loginData, setLoginData] = useState({
		email: '',
		password: '',
	});
	const [signupData, setSignupData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		name: '',
	});
	const [forgotEmail, setForgotEmail] = useState('');

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (isSignUp) {
				Signup(e);
			} else {
				Login(e);
			}
		}
	};

	const { email: loginEmail, password: loginPassword } = loginData;

	const {
		email: signupEmail,
		password: signupPassword,
		confirmPassword: signUpConfirmPassword,
		name: signupName,
	} = signupData;

	const loginchangeHandler = (e: any) => {
		setLoginData({ ...loginData, [e.target.name]: e.target.value });
		setError('');
	};

	const signupchangeHandler = (e: any) => {
		setSignupData({ ...signupData, [e.target.name]: e.target.value });
		setError('');
		setInvalidPassword(false);
	};

	const Login = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
			.then(async (userCredential) => {
				localStorage.setItem('activeClientId', '');
				if (userCredential?.user?.emailVerified) {
					ReactGA.set({ userId: userCredential.user.email });
				} else {
					await sendEmailVerification(userCredential?.user);
					await auth.signOut();
					await Swal.fire({
						title: 'Error',
						text: 'Please verify your email first.',
						allowOutsideClick: true,
						confirmButtonText: 'Okay',
						cancelButtonColor: '#38cab3',
						confirmButtonColor: 'rgba(0, 56, 255, 1)',
					});
				}
			})
			.catch((err) => {
				console.log(err);
				setError(err.message);
			});
		setIsLoading(false);
	};
	const ForgotPassword = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		await sendPasswordResetEmail(auth, forgotEmail)
			.then((e) => {
				console.log('forgot email sent');
				setForgotPassEmailSent(true);
				setShowForgotPassword(false);
			})
			.catch((err) => {
				console.log(err);
				setError(err.message);
			});
		setIsLoading(false);
	};
	const [invalidPassword, setInvalidPassword] = useState(false);	

	const Signup = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		if(!planId){
			const result = await Swal.fire({
				icon: "warning",
				title: 'Select Plan',
				text: "Please select a plan before signing up.",
				allowOutsideClick: false,
				showCancelButton: false,
				confirmButtonText: 'Okay',
				cancelButtonColor: '#38cab3',
			});
			if(result?.isConfirmed){
				window.location.href = 'https://multiplied.ai/pricing';
			}
		}else{
			if (signUpConfirmPassword == signupPassword) {
				await createUserWithEmailAndPassword(auth, signupEmail, signupPassword)
					.then(async (userCredential) => {
						// ReactGA.set({ userId: userCredential.user.email })
						await sendEmailVerification(userCredential?.user);
						await updateProfile({
							name: signupName,
							email: signupEmail,
						});
						await Swal.fire({
							title: 'Verify your email to start using Multiplied.Ai',
							text: "We have sent you a verification email. Verify your email address so we know it’s really you.",
							allowOutsideClick: true,
							confirmButtonText: 'Okay',
							confirmButtonColor: 'rgba(0, 56, 255, 1)',
							cancelButtonColor: '#38cab3',
						});
						await auth?.signOut();
						navigate(authMenu.login.path);
					})
					.catch((err) => {
						console.log(err);
						setError(err.message);
					});
			} else {
				setInvalidPassword(true);
			}
		}
		setIsLoading(false);
	};

	const { activeUser, user, isAuthenticated, salesProfessionalInfo, redirectUrl } = useContext(UserContext);
	const {removeClient} = useContext(ClientContext);
	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);

	useEffect(() => {
		if (activeUser && isAuthenticated) {
			// console.log('redrirect urll', activeUser, isAuthenticated, redirectUrl);
			{
				handleOnClick();
			}
		}
	}, [activeUser, isAuthenticated]);

  useEffect(() => {
    if(isSignUp) {
      if(!planId) {
        window.location.href = 'https://multiplied.ai/pricing';
      }
    }
  }, [isSignUp, planId])

	const handleOnClick = async () => {
		await localStorage.setItem('activeClientId', '');
		removeClient();
		if (!activeUser || !user?.emailVerified){
			throw new Error("User not found");
		}else{
			if(!!planId && planId?.length > 0){
				navigate(`/accept-payment?plan=${planId}`);
			} else if(!!redirectUrl){
				navigate(redirectUrl);
			} else{
			  navigate(dashboardPagesMenu.workflows.path);
		  }
	  };
  }
	const [isLoading, setIsLoading] = useState<boolean>(false);

	return (
		<PageWrapper
			isProtected={false}
			title={isSignUp ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': !isSignUp, 'bg-light': isSignUp })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-4'>
									<Link
										to={dashboardPagesMenu.workflows.path}
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo width={200} color='light' />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									{!showForgotPassword && (
										<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
											<div className='col'>
												<Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight={isSignUp}
													className='rounded-1 w-100'
													size='lg'
													onClick={() => {
														setSignInPassword(false);
                            navigate(authMenu.login.path);
													}}>
													Login
												</Button>
											</div>
											<div className='col'>
												<Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight={!isSignUp}
													className='rounded-1 w-100'
													size='lg'
													onClick={() => {
														setSignInPassword(false);
                            navigate(authMenu.signUp.path);
													}}>
													Sign Up
												</Button>
											</div>
										</div>
									)}
								</div>

								{showForgotPassword ? (
									<>
										<div className='text-center h1 fw-bold mt-5'>
											Forgot Password
										</div>
										<div className='text-center h4 text-muted mb-5'>
											Please enter your email to change password!
										</div>
									</>
								) : (
									<LoginHeader isNewUser={isSignUp} />
								)}
								{forgotPassEmailSent && (
									<Alert
										isLight
										icon='Lock'
										isDismissible
										className='flex-nowrap'>
										<div className='row'>
											<div className='col-12'>
												The Password reset email has been sent <br /> Please
												check your Inbox!
											</div>
										</div>
									</Alert>
								)}

								{/* {err ? (
									
									<Alert
										isLight
										icon='Lock'
										isDismissible
										className='flex-nowrap'>
										{err ? (
											<strong className='text-danger'>
												Invalid Email or Password
											</strong>
										) : (
											<div className='row'>
												<div className='col-12'>
													<strong>Email:</strong> {loginEmail}
												</div>
												<div className='col-12'>
													<strong>Password:</strong> {loginPassword}
												</div>
											</div>
										)}
									</Alert> */}
									{!isSignUp && err ? (
                                    <Alert
                                        isLight
                                        icon='Lock'
                                        isDismissible
                                        className='flex-nowrap'
                                         >
                                      <strong className='text-danger'>
                                        Invalid Email or Password
                                      </strong>
                                     </Alert>
									
								) : (
									<></>
								)}
								{showForgotPassword ? (
									<form className='row g-4'>
										<div className='col-12'>
											<FormGroup
												id='forgot-email'
												isFloating
												label='Your email'>
												<Form.Control
													className={`form-control ${
														err ? 'is-invalid' : ''
													}`}
													placeholder='Your email'
													name='email'
													type='email'
													value={forgotEmail}
													onChange={(e) => setForgotEmail(e.target.value)}
													required
													autoComplete='email'
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												color='warning'
												className='w-100 py-3'
												onClick={ForgotPassword}>
												{isLoading ? <Spinner /> : 'Submit'}
											</Button>
										</div>
										<div
											className='col-12 text-center bold text-decoration-underline cursor-pointer'
											onClick={() => setShowForgotPassword(false)}>
											Back
										</div>
									</form>
								) : (
									<form className='row g-4'>
										{isSignUp ? (
											<>
												<div className='col-12'>
													<FormGroup
														id='signup-name'
														isFloating
														label='Your name'>
														<Form.Control
															className={`form-control ${
																err ? 'is-invalid' : ''
															}`}
															placeholder='Your name'
															name='name'
															type='text'
															value={signupName}
															onChange={signupchangeHandler}
															required
															autoComplete='name'
														/>
													</FormGroup>
												</div>
												<div className='col-12'>
													<FormGroup
														id='signup-email'
														isFloating
														label='Your email'>
														<Form.Control
															className={`form-control ${
																err ? 'is-invalid' : ''
															}`}
															placeholder='Your email'
															name='email'
															type='email'
															value={signupEmail}
															onChange={signupchangeHandler}
															required
															autoComplete='email'
														/>
													</FormGroup>
												</div>
												<div className='col-12 position-relative'>
													<FormGroup
														id='signup-password'
														isFloating
														label='Password'>
														<Form.Control
															className={`form-control ${
																err ? 'is-invalid' : ''
															}`}
															style={{ paddingRight: 35 }}
															placeholder='Password'
															name='password'
															type={
																toggleConfirmPassword
																	? 'text'
																	: 'password'
															}
															value={signupPassword}
															onChange={signupchangeHandler}
															required
															autoComplete='password'
														/>
														<span
															className='position-absolute cursor-pointer'
															onClick={() => {
																setToggleConfirmPassword(
																	!toggleConfirmPassword,
																);
															}}
															style={{
																right: 5,
																top: '50%',
																transform: 'translate(-50%,-25%)',
															}}>
															<Icon
																icon={
																	toggleConfirmPassword
																		? 'Visibility'
																		: 'Visibility Off'
																}
																size={'lg'}
															/>
														</span>
													</FormGroup>
												</div>
												<div className='col-12 position-relative'>
													<FormGroup
														id='confirm-signup-password'
														isFloating
														label='Confirm Password'>
														<Form.Control
															className={`form-control ${
																invalidPassword ? 'is-invalid' : ''
															}`}
															placeholder='Password'
															style={{ paddingRight: 35 }}
															name='confirmPassword'
															type={
																toggleReConfirmPassword
																	? 'text'
																	: 'password'
															}
															value={signUpConfirmPassword}
															onChange={signupchangeHandler}
															required
															autoComplete='password'
															onKeyDown={handleKeyDown}
														/>
														<span
															className='position-absolute cursor-pointer'
															onClick={() => {
																setToggleReConfirmPassword(
																	!toggleReConfirmPassword,
																);
															}}
															style={{
																right: 5,
																top: '50%',
																transform: 'translate(-50%,-25%)',
															}}>
															<Icon
																icon={
																	toggleReConfirmPassword
																		? 'Visibility'
																		: 'Visibility Off'
																}
																size={'lg'}
															/>
														</span>
													</FormGroup>
													{invalidPassword ? (
														<p className='text-error mb-0 mt-2'>
															The password confirmation does not
															match.
														</p>
													) : (
														<></>
													)}
												</div>
												<div className='col-12'>
													<Button
														color='info'
														className='w-100 py-3'
														isDisable={isLoading}
														onClick={Signup}>
														{isLoading ? <Spinner /> : 'Sign Up'}
													</Button>
												</div>
											</>
										) : (
											<>
												<div className='col-12'>
													<FormGroup
														id='loginEmail'
														isFloating
														label='Your email'
														className={classNames({
															'd-none': signInPassword,
														})}>
														<Form.Control
															className={`form-control ${
																err ? 'is-invalid' : ''
															}`}
															placeholder='Enter your email'
															name='email'
															type='email'
															value={loginEmail}
															onChange={loginchangeHandler}
															required
															autoComplete='email'
														/>
													</FormGroup>
													{signInPassword && (
														<div className='text-center h4 mb-3 fw-bold'>
															Hi,{' '}
															{salesProfessionalInfo?.name ?? 'user'}{' '}
															.
														</div>
													)}
													<div className='position-relative'>
														<FormGroup
															id='loginPassword'
															isFloating
															label='Password'
															className={'mt-3'}>
															<Form.Control
																className={`form-control ${
																	err ? 'is-invalid' : ''
																}`}
																placeholder='Enter your password'
																name='password'
																style={{ paddingRight: 35 }}
																type={
																	togglePassword
																		? 'text'
																		: 'password'
																}
																value={loginPassword}
																onChange={loginchangeHandler}
																autoComplete='password'
																required
																onKeyDown={handleKeyDown}
															/>
															<span
																className='position-absolute cursor-pointer'
																onClick={() => {
																	setTogglePassword(
																		!togglePassword,
																	);
																}}
																style={{
																	right: 5,
																	top: '50%',
																	transform:
																		'translate(-50%,-25%)',
																}}>
																<Icon
																	icon={
																		togglePassword
																			? 'Visibility'
																			: 'Visibility Off'
																	}
																	size={'lg'}
																/>
															</span>
														</FormGroup>
													</div>
												</div>
												<div
													className='col-12 text-decoration-underline cursor-pointer'
													onClick={() => setShowForgotPassword(true)}>
													Forgot password?
												</div>
												<div className='col-12'>
													<Button
														color='warning'
														className='w-100 py-3'
														onClick={Login}>
														{isLoading ? <Spinner /> : 'Login'}
													</Button>
												</div>
											</>
										)}
									</form>
								)}
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href={'/'}
								className={classNames('text-decoration-none me-3', {
									'link-light': isSignUp,
									'link-dark': !isSignUp,
								})}>
								Privacy policy
							</a>
							<a
								href={'/'}
								className={classNames('link-light text-decoration-none', {
									'link-light': isSignUp,
									'link-dark': !isSignUp,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
