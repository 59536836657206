import { useContext } from 'react';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { DataFragment } from '../../../models/models';
import { DatalakeContext } from '../../../contexts/DatalakeContext';
import Icon from '../../../components/icon/Icon';
import { ClientContext } from '../../../contexts/ClientContext';

function FolderBreadCrumb () {
	const { themeStatus } = useDarkMode();
  const {activeClient} = useContext(ClientContext);
	const { currentFolder, openFolder } = useContext(DatalakeContext);
  const open = (index: number) => {
    const path = currentFolder.slice(0,index+1).join("/");
    openFolder(path);
  }

	return (
		<div className='d-flex align-items-center' style={{ display: 'flex', flexDirection: 'row' }}>
      <p className=' d-flex mb-0 align-items-center' >
        {currentFolder.map((folderName, index) => <div className=' d-flex align-items-center' key={folderName + index}>
        <span className='d-flex cursor-pointer' onClick={() => open(index)}>{folderName == "/" ? activeClient?.name : folderName}</span>
        {index < currentFolder.length - 1 && (
        <label className='border-0 bg-transparent cursor-pointer'>
          <Icon icon='ChevronRight' color='primary' />
        </label>
        )}
        </div>
        )}
      </p>
		</div>
	);
}

export default FolderBreadCrumb;
