import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from './bootstrap/OffCanvas';
import Icon from './icon/Icon';
import { FiEdit } from 'react-icons/fi';
import chatgptImg from '../assets/img/ai/chatgpt-logo.png';
import { BsCheck2, BsClipboard2 } from 'react-icons/bs';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { ChatCollaboratorContext } from '../contexts/ChatCollaboratorContext';
import moment from 'moment';
import { UserContext } from '../contexts/UserContext';
import { Button } from 'react-bootstrap';
import { TbLayoutSidebarRightExpand, TbLayoutSidebarLeftExpand } from 'react-icons/tb';
import useDarkMode from '../hooks/useDarkMode';
import { spacedTitle } from '../util/util';
import Lottie from 'react-lottie';
import loadJson from '../Lottie/dots-loader.json';
import loadJsonWhite from '../Lottie/dots-loader-white.json';
import { ContentCopy } from './icon/material-icons';
import Popovers from './bootstrap/Popovers';


interface Props {
	openAICollaboratorTab: boolean;
	setOpenAICollaboratorTab: Dispatch<SetStateAction<boolean>>;
}

function AICollaboratorChat({ openAICollaboratorTab, setOpenAICollaboratorTab }: Props) {
	const [message, setMessage] = useState('');
	const [textareaHeight, setTextareaHeight] = useState('auto');
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const { activeUser } = useContext(UserContext);
	const { darkModeStatus } = useDarkMode();
	const [disableSearch, setDisableSearch] = useState(false);
	const [expandChatMenu, setExpandChatMenu] = useState(false);
	const [copyIndex, setCopyIndex] = useState<number>();
	const { chats, activeChat, setActiveChat, newChatCollaboration, send, loading } =
		useContext(ChatCollaboratorContext);
	const firstTopic = chats?.[0];

	const handleTextareaChange = (event: { target: { value: React.SetStateAction<string> } }) => {
		setMessage(event.target.value);
		adjustTextareaHeight(event.target.value);
	};
	const copyToClipboard = (copyText: any) => {
		const processedText = spacedTitle(copyText);
		navigator.clipboard.writeText(processedText);
	};
	const [showCopyMessage, setShowCopyMessage] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	useEffect(() => {
		let reset;
		if (copyIndex != 0) {
			reset = setTimeout(() => {
				setCopyIndex(0);
			}, 2000);
		} else {
			if (reset) {
				clearTimeout(reset);
			}
		}
	}, [copyIndex]);
	const adjustTextareaHeight = (value: any) => {
		setTextareaHeight('auto'); // Reset height to auto
		if (value) {
			const maxHeight = 24 * 6; // Maximum height (24px per row, 6 rows)
			if (textareaRef.current && textareaRef.current.scrollHeight <= maxHeight) {
				setTextareaHeight(textareaRef.current.scrollHeight + 'px');
			} else {
				setTextareaHeight(maxHeight + 'px');
			}
		} else {
			setTextareaHeight('auto'); // Reset height when value is cleared
		}
	};

	const sendMessage = async () => {
		setDisableSearch(true);
		await send(message, activeChat?.id);
		if (textareaRef.current) {
			textareaRef.current.value = '';
			setMessage('');
		}
		setDisableSearch(false);
	};

	
	const handleMouseEnter = () => {
		setShowCopyMessage(true);
	  };
	
	  const handleMouseLeave = () => {
		setShowCopyMessage(false);
	  };


	return (
		<OffCanvas
			id='helpCanvas'
			titleId='offcanvasExampleLabel'
			placement='end'
			isOpen={openAICollaboratorTab}
			setOpen={setOpenAICollaboratorTab}
			style={{ zIndex: 999999999 }}>
			{/* don't remove */}
			<div className='bg-cover'></div>
			<OffCanvasHeader
				setOpen={setOpenAICollaboratorTab}
				className='align-items-center black-text z-100'>
				<Icon
					icon='Menu'
					size={'2x'}
					className='d-md-none'
					onClick={() => setExpandChatMenu(true)}
				/>

				{expandChatMenu ? (
					<TbLayoutSidebarRightExpand
						size={20}
						className='d-md-block d-none cursor-pointer'
						onClick={() => {
							setExpandChatMenu(false);
						}}
					/>
				) : (
					<TbLayoutSidebarLeftExpand
						size={20}
						className='d-md-block d-none cursor-pointer'
						onClick={() => {
							setExpandChatMenu(true);
						}}
					/>
				)}
				<OffCanvasTitle id='offcanvasExampleLabel'>AI Collaborator</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody className='p-0  z-100'>
				{loading ? (
					<div className='h-100 w-100 d-flex align-items-center justify-content-center'>
						<Lottie
							height={300}
							width={300}
							options={{
								loop: true,
								autoplay: true,
								animationData: darkModeStatus ? loadJsonWhite : loadJson,
							}}
						/>
					</div>
				) : activeChat ? (
					<>
						{activeChat.conversation?.map((conversation, index) =>
							conversation.role == 'user' ? (
								<div
									className='group-sender w-100 px-3'
									style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
									<div className='p-4 px-0'>
										<div className='d-flex align-items-start gap-4'>
											<div className='avatar-wrapper d-flex align-items-center justify-content-center flex-shrink-0'>
												<p className='mb-0 text-white uppercase'>
													{activeUser?.name?.charAt(0) ??
														activeUser?.email?.charAt(0)}
												</p>
											</div>
											<div className='content-wrapper d-flex gap-2'>
												<div>
													<p className='mb-0'>{conversation.content}</p>
												</div>
												<div
													className='cursor-pointer'
													onClick={() => {
														if (conversation.content) {
															setMessage(conversation.content);
														}
													}}>
													<FiEdit color='#d9d9e3' size={16} />
												</div>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div
									className='group-receiver w-100 px-3'
									style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}>
									<div className='p-4 px-0'>
										<div className='d-flex align-items-start gap-4'>
											<div className='avatar-wrapper p-0 d-flex align-items-center justify-content-center flex-shrink-0'>
												<img
													src={chatgptImg}
													alt='chat-gpt'
													className='img-fluid'
												/>
											</div>
											<div className='content-wrapper d-flex gap-2'>
												<div>
													{conversation.function_call
														? (
																conversation.function_call
																	?.arguments as Record<
																	string,
																	any
																>
														  )?.body
														: conversation.content}
												</div>
												<div
													className='cursor-pointer'
													onClick={() => {
														const argumentsContent =
															conversation.function_call?.arguments;

														if (argumentsContent) {
															const argumentText = Object.values(
																argumentsContent,
															)
																.filter(
																	(value) =>
																		typeof value === 'string',
																)
																.join(' ');

															if (argumentText) {
																copyToClipboard(argumentText);
															} else {
																copyToClipboard(
																	conversation.content,
																);
															}
														} else {
															copyToClipboard(conversation.content);
														}
														setCopyIndex(index + 1);
													}}>
													{copyIndex == index + 1 ? (
														<BsCheck2 color='#d9d9e3' size={16} />
													) : (										
													<div className='col-auto'>
													<div
													  onMouseEnter={handleMouseEnter}
													  onMouseLeave={handleMouseLeave}
													  style={{ position: 'relative' }}
													>
													  <Icon icon='ContentCopy' />
													  {/* <Icon icon='ContentCopy' style={{ fontSize: iconSize }} /> */}
													  {showCopyMessage && (
														<div
														  style={{
															position: 'absolute',
															left: '20px',
															top: '0',
															backgroundColor: 'rgba(0, 0, 0, 0.5)',
															color: '#fff',
															padding: '5px',
															borderRadius: '10px',
															outline:'1px solid  rgba(0, 0, 0, 0.5)',
															marginLeft:'-35px',
															marginTop:'25px'
														  }}
														>
														  Copy
														</div>
													  )}
													</div>
												  </div>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							),
						)}
					</>
				) : null}
			</OffCanvasBody>
			<div className=' z-100'>
				<div className=' py-3 ps-3 pe-2 chat-input-container d-flex align-items-end m-2 mb-3 bg-white'>
					<textarea
						ref={textareaRef}
						placeholder='Send a message'
						className='w-100 p-0 bg-white'
						rows={1}
						style={{ height: textareaHeight }}
						value={message}
						onChange={handleTextareaChange}
					/>
					<button
						className='p-0 m-0 btn-transparent'
						onKeyDown={(e) => {
							if (e?.key == 'Enter') {
								sendMessage();
							}
						}}
						disabled={disableSearch}
						style={{}}>
						{!disableSearch ? (
							<Icon
								tabIndex={2}
								icon='Send'
								className={`cursor-pointer mb-1 bg-green chat-submit-btn ${
									darkModeStatus ? 'dark-mode p-1' : 'light-mode'
								}`}
								onClick={() => sendMessage()}
							/>
						) : (
							<Lottie
								height={20}
								width={40}
								style={{ transform: 'scale(3.5)' }}
								options={{ loop: true, autoplay: true, animationData: loadJson }}
							/>
						)}
					</button>
				</div>
			</div>
			<div
				className={`h-100 position-absolute chat-history ${
					expandChatMenu ? 'expand-history' : 'collapse-history '
				}  `}>
				<div className='chat-history-header d-flex justify-content-between align-items-center p-2 gap-2 position-relative'>
					<div className='col-9 col-md-12'>
						<button
							className='chat-btn w-100 d-flex align-items-center justify-content-start gap-2'
							onClick={() => {
								newChatCollaboration();
							}}>
							<Icon icon='Add' className='material-icon' size={'lg'} /> New chat
						</button>
					</div>
					<div className='col-3 d-md-none'>
						<button
							className='chat-btn'
							onClick={() => {
								window.innerWidth < 768
									? setExpandChatMenu(false)
									: setExpandChatMenu(!expandChatMenu);
							}}>
							<TbLayoutSidebarLeftExpand size={20} />
						</button>
					</div>
				</div>
				<div className='chat-history-body p-2 h-100'>
					<h3 className='chat-title'>Previous 7 days</h3>
					<div className='chat-history-container h-100'>
						{chats?.map((chat) => (
							<div
								className={`d-flex align-items-center px-2 py-3 gap-2 chat-row ${
									activeChat?.id == chat?.id ? 'active' : ''
								}`}>
								<Icon icon='Mode Comment' className='flex-shrink-0' />
								<p
									key={chat.id}
									className='cursor-pointer chat-text flex-shrink-0 mb-0'
									onClick={() => setActiveChat(chat)}>
									{chat.name} ({moment(chat.lastUpdated).fromNow()})
								</p>
								<div className='gradient-cover'></div>
							</div>
						))}
					</div>
				</div>
			</div>
		</OffCanvas>
	);
}

export default AICollaboratorChat;
