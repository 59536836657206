import React, { useState ,ReactNode} from 'react';

// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { FirebaseAppProvider } from 'reactfire';
import { firebaseConfig } from './Firebase/firebase';
import { Metric } from 'web-vitals';
import ReactGA from "react-ga4";
ReactGA.initialize("G-S0ZC40JSRL");


const children = (
	<FirebaseAppProvider firebaseConfig={firebaseConfig}>
		<AuthContextProvider>
			<ThemeContextProvider>
				<Router>
					<React.StrictMode>
						<App />
					</React.StrictMode>
				</Router>
			</ThemeContextProvider>
		</AuthContextProvider>
	</FirebaseAppProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric: Metric) => {
	ReactGA.event({
		action: metric.name,
		category: 'Web Vitals',
		label: metric.id,
		nonInteraction: true,
		value: metric.value,
	});
});
