import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authMenu } from '../menu';
import DefaultFooter from '../pages/_layout/_footers/DefaultFooter';

const footers: RouteProps[] = [
	{ path: authMenu.login.path, element: null },
	{ path: authMenu.signUp.path, element: null },
	{ path: authMenu.page404.path, element: null },
	{ path: 'a-future-page', element: <DefaultFooter /> },
	{ path: '*', element: null },
];

export default footers;
